import { Injectable } from '@angular/core';
import { ReportHeaderUtility } from 'src/app/shared/service/report/report-header.utility';

@Injectable({
	providedIn: 'root'
})
export class TrendedWeeklyUtility extends ReportHeaderUtility {
	getReportPayload(values: any): any {
		let payload: any = {
			totalBusiness: values.product.totalBusiness,
			majorBusiness: values.product.majorBusiness,
			department: values.product.department,
			categoryGroup: values.product.categoryGroup,
			category: values.product.category,
			subCategory: values.product.subCategory,
			segment: values.product.segment,
			brandOwnerHigh: '',
			brandOwnerLow: '',
			brandHigh: '',
			brandFamily: '',
			brandLow: '',
			market: [],
			period: 52,
			cyPeriod: 'Latest 52 Wks',
			yaPeriod: 'Latest 52 Wks YA',
			wmMarket: [],
			rmMarket: [],
			twoYAPeriod: 'Latest 52 Wks 2YA'
		};

		values.market.forEach(market => {
			payload.market.push(market.key);
			payload.wmMarket.push(market.api.value.walmartMarket);
			payload.rmMarket.push(market.api.value.remainingMarket);
		});
		return payload;
	}
}
