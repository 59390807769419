import { Component, Input, OnInit } from '@angular/core';
import { ReportDimension } from 'src/app/models/report-dimension.model';

@Component({
	selector: 'crf-ca-dashboard-footnote',
	templateUrl: './dashboard-footnote.component.html',
	styleUrl: './dashboard-footnote.component.scss'
})
export class DashboardFootnoteComponent implements OnInit {
	@Input() reportDimension: ReportDimension;

	ngOnInit() {
		if (!this.reportDimension) {
			this.reportDimension = {
				dashboardName: '',
				selectedMarket: [],
				selectedProduct: [],
				selectedLevelToShow: '',
				baseFact: '',
				periodEndDate: '',
				periodsList: [],
				period: '',
				isCmaView: false,
				twoYAEnabled: false,
				multiMarketEnabled: false,
				volumeBasis: '',
				fact: '',
				showText1: false,
				hideText2: false,
				comparisonPeriod: '',
				subCategory: ''
			};
		}
	}
}
