import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastService, ToastState } from '@nielseniq/athena-core';
import { Subject, takeUntil } from 'rxjs';
import { PeriodGroup } from 'src/app/models/report-dimension.model';
import { ReportService } from 'src/app/service/report.service';
import { TOAST } from 'src/app/constants/toast.constant';

@Component({
	selector: 'app-dropdown-selection',
	templateUrl: './dropdown-selection.component.html',
	styleUrl: './dropdown-selection.component.scss'
})
export class DropdownSelectionComponent implements OnInit, OnDestroy {
	@Input() items: PeriodGroup[];
	@Input() selectorType: number;
	@Input() configId: number;
	@Input() questionId = -1;
	@Input() disableMenuOptions: boolean = true; // default to true
	@Output() periodCheckChanged: EventEmitter<PeriodGroup[]> = new EventEmitter<PeriodGroup[]>();
	@Output() onDefaultChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() cancel: EventEmitter<any> = new EventEmitter<any>();

	protected readonly unSubscribeAll: Subject<void> = new Subject<void>();
	selectedPeriod: any[] = [];
	defaultPeriodSelection: any[] = [];

	constructor(private service: ReportService, private toastService: ToastService) {}

	ngOnInit() {
		// Capture the initial selection as the default
		this.selectedPeriod = this.items?.filter(period => period.isSelected);
		this.defaultPeriodSelection = structuredClone(this.selectedPeriod);
	}

	checkedChange(category) {
		category.isSelected = !category.isSelected;
		this.selectedPeriod = this.items.filter(period => period.isSelected);
	}

	applyHandler() {
		this.periodCheckChanged.emit(this.items);
		this.disableMenuOptions = false;
	}

	trackByFn(index: number, category) {
		return category.key;
	}

	getMenuResponse(data) {
		// Ensure selectedPeriod and defaultPeriodSelection are initialized
		this.selectedPeriod = this.selectedPeriod || [];
		this.defaultPeriodSelection = this.defaultPeriodSelection || [];

		if (this.questionId !== -1) {
			if (data.menuId === 1) {
				let period =
					this.selectedPeriod.length > 0
						? this.selectedPeriod.map(period => period.key).join(';')
						: '';
				let payload = { periodIds: period };
				this.service
					.saveSelectors(this.questionId, 'periodIds', payload)
					.pipe(takeUntil(this.unSubscribeAll))
					.subscribe({
						next: res => {
							// After saving, set the defaults to the new selection
							this.defaultPeriodSelection = structuredClone(this.selectedPeriod);
							this.toast('success', 'Period Dropdown', 'Selections saved successfully');
						},
						error: err => {
							this.toast('error', 'Period Dropdown', 'Get Menu Response: Invalid Response');
						}
					});
				this.disableMenuOptions = !this.isSelectionModified();
			} else if (data.menuId === 2) {
				this.service
					.getSelectors(this.questionId)
					.pipe(takeUntil(this.unSubscribeAll))
					.subscribe({
						next: res => {
							if (res.responseData && res.responseData.periodIds) {
								let responsePeriods = res.responseData.periodIds.split(';');
								this.items.forEach(period => {
									period.isSelected = responsePeriods.includes(period.key);
								});
								this.selectedPeriod = this.items.filter(period => period.isSelected);
								this.defaultPeriodSelection = structuredClone(this.selectedPeriod);
								this.disableMenuOptions = true;
							}
						},
						error: err => {
							this.toast('error', 'Periods Dropdown', 'Get Menu Response: Invalid Response');
						}
					});
			}
		} else if (data.menuId === 1) {
			this.defaultPeriodSelection = structuredClone(this.selectedPeriod);
			this.disableMenuOptions = true;
		} else if (data.menuId === 2) {
			this.disableMenuOptions = true;
		}
		this.onDefaultChange.emit(data);
	}

	isSelectionModified(): boolean {
		// Ensure both arrays are initialized
		if (!this.selectedPeriod || !this.defaultPeriodSelection) return true; // Return true if either is undefined or null

		// Compare lengths
		if (this.selectedPeriod.length !== this.defaultPeriodSelection.length) return true;

		// Compare each item in both arrays
		return !this.selectedPeriod.every(
			(item, index) => item.key === this.defaultPeriodSelection[index].key
		);
	}

	toast(type: ToastState, title: string, message: string): void {
		this.toastService.InjectToast(type, message, '', TOAST.TIMEOUT, '', title, TOAST.SIZE);
	}

	ngOnDestroy(): void {
		this.unSubscribeAll.next();
		this.unSubscribeAll.complete();
	}
}
