import { Injectable } from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest
} from '@angular/common/http';
import { NlsnGlobalsContextExtract } from '../../../core/service/auth/nlsn-globals.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { REDIRECTING_ANSWERS_PORTAL_URL_WHEN_LOGOUT } from '../../../../environments/env.config.constants';
import { NlsnDataStoreService } from '../../service/store/nlsn-data-store.service';
import { KEY } from 'src/app/constants/data.constant';

@Injectable()
export class NlsnDataResponseInterceptor implements HttpInterceptor {
	constructor(
		private nlsnGlobalsContextExtract: NlsnGlobalsContextExtract,
		public nlsnDataStoreService: NlsnDataStoreService
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const accessToken = this.nlsnDataStoreService.getItem(KEY.COOKIE)?.access_token?.trim();

		// Clone the request and replace the original headers with
		// cloned headers, updated with the authorization.
		let authReq = req;

		if (accessToken)
			authReq = req.clone({
				headers: req.headers.set('X-NIQ-ACCESS-TOKEN', accessToken)
			});

		return next.handle(authReq).pipe(
			catchError(error => {
				if (error instanceof HttpErrorResponse) {
					if (error.status === 401 || error.status === 403) {
						this.handleAuthError(error);
						return of(null);
					}
				}
				return throwError(() => error);
			})
		);
	}

	private handleAuthError(err: any): void {
		window.location.href =
			REDIRECTING_ANSWERS_PORTAL_URL_WHEN_LOGOUT[
				this.nlsnGlobalsContextExtract.extractEnvironment()
			];
	}
}
