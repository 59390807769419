import { Injectable } from '@angular/core';
import { _, GetContextMenuItems, GetContextMenuItemsParams } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { NlsnGlobalsContextExtract } from 'src/app/core/service/auth/nlsn-globals.service';
import { NlsnDataStoreService } from 'src/app/core/service/store/nlsn-data-store.service';
import { AppInsightsService } from 'src/app/service/app-insights.service';

@Injectable({
	providedIn: 'root'
})
export class GridService {
	sortColumnSubject$ = new BehaviorSubject<{ headerName: string; sortOrder: string }>({
		headerName: '',
		sortOrder: ''
	});
	public isExpanded = new BehaviorSubject<boolean>(true);

	constructor(private appInsightsService: AppInsightsService) {}

	updateSortColumn(columnConfig) {
		this.sortColumnSubject$.next(columnConfig);
	}
	treeExpendedStatus(isExpandStatus) {
		this.isExpanded.next(isExpandStatus);
	}
	expandOrCollapse(params): void {
		if (params.node.data.group) {
			params.api.getRowNode(params.node.id).setExpanded(!params.node.expanded);
			this.isExpanded.next(params.node.expanded);
		}
	}

	onSortChanged(params) {
		let sortColumn = params?.columns[params?.columns?.length - 1];
		let sortColumnConfig = {
			headerName: sortColumn.colDef?.headerName,
			sortOrder: sortColumn.sort === undefined ? 'asc' : sortColumn.sort
		};
		this.updateSortColumn(sortColumnConfig);
	}

	resetColumnConfig() {
		this.sortColumnSubject$.next({ headerName: '', sortOrder: '' });
	}

	public getContextMenu(params: GetContextMenuItemsParams, gridApi: any, dataModelType: any) {
		return [
			...params.defaultItems.filter(item => item !== 'export'),
			{
				name: 'Excel Export',
				icon: this.createImgForContextMenu('excel'),
				action: () => {
					gridApi.exportDataAsExcel();
					this.trackPage(dataModelType + '_AGgrid.export');
				}
			},
			{
				name: 'CSV Export',
				icon: this.createImgForContextMenu('csv'),
				action: () => {
					gridApi.exportDataAsCsv();
					this.trackPage(dataModelType + '_AGgrid.csv');
				}
			}
		];
	}

	createImgForContextMenu(iconName: any) {
		return (
			'<span class="ag-icon ag-icon-' + iconName + '" unselectable="on" role="presentation"></span>'
		);
	}

	trackPage(pageName: string): void {
		if (_.isFunction(this.appInsightsService.trackPage)) {
			this.appInsightsService.trackPage(pageName);
		}
	}
}
