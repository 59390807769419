<div class="niq-download-icon d-flex">
	<!-- PPT Export -->
	@if (pptIcon) {
	<div class="export-icons">
		<span class="export-icon-column">
			@if (!pptExport) {
			<span class="ppt-icon">
				<niq-icon
					(click)="exportReq('ppt')"
					[state]="!isExportLoading ? 'primary' : 'disabled'"
					size="medium"
					iconName="ppt"
					[niqTooltip]="hoverText.pptHovertext"
					[niqTooltipColorTheme]="'dark'"
				>
				</niq-icon>
			</span>
			}
		</span>
		<span class="export-loader">
			@if (isExportLoading && !excelExport) {
			<niq-spinner
				colorTheme="light"
				*ngIf="isExportLoading && !excelExport"
				[niqTooltip]="'Download in Progress'"
				[niqTooltipColorTheme]="'dark'"
				size="medium"
				variant="brand"
			>
			</niq-spinner>
			}
		</span>
	</div>
	}
	<!-- EXCEL Export -->
	@if (excelIcon) {
	<div class="export-icons">
		<span class="export-icon-column">
			@if (!excelExport) {
			<span class="xl-icon">
				<niq-icon
					[state]="!isExportLoading ? 'primary' : 'disabled'"
					(click)="exportReq('excel')"
					[niqTooltip]="hoverText.csvHovertext"
					[niqTooltipColorTheme]="'dark'"
					size="medium"
					iconName="excel"
				>
				</niq-icon>
			</span>
			}
		</span>
		<span class="export-loader">
			<niq-spinner
				colorTheme="light"
				*ngIf="isExportLoading && !pptExport"
				[niqTooltip]="'Download in Progress'"
				[niqTooltipColorTheme]="'dark'"
				size="medium"
				variant="brand"
			>
			</niq-spinner>
		</span>
	</div>
	}
</div>
