import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerformanceQuadrantReportPageComponent } from './performance-quadrant-report-page.component';
import { MainPerformanceQuadrantReportComponent } from './main-performance-quadrant-report/main-performance-quadrant-report.component';
import {
	IconModule,
	SpinnerModule,
	TabModule,
	ToastModule,
	TooltipModule
} from '@nielseniq/athena-core';
import { CommonComponentModule } from 'src/app/shared/components/grid/common-component.module';
import { SharedComponentModule } from 'src/app/shared/components/shared-component.module';
import { ComponentModule } from 'src/app/shared/components/selector/component.module';

@NgModule({
	declarations: [MainPerformanceQuadrantReportComponent, PerformanceQuadrantReportPageComponent],
	imports: [
		CommonModule,
		TooltipModule,
		IconModule,
		ComponentModule,
		TabModule,
		CommonComponentModule,
		SpinnerModule,
		SharedComponentModule,
		ToastModule
	]
})
export class PerformanceQuadrantReportPageModule {}
