<span class="ag-cell-wrapper ag-cell-expandable ag-row-group" [ngClass]="[indentClass]">
	<div [ngClass]="{ 'ag-group-value': hasChildren }" class="ag-cell-value">
		<span
			class="ag-group-expanded"
			[ngClass]="{ 'ag-hidden && hasChildren': !isExpanded }"
			[ngStyle]="!hasChildren && { display: 'none' }"
			ref="eExpanded"
		>
			<span (click)="toggleRow($event)" unselectable="on" role="presentation">
				<i class="fa fa-minus-square" aria-hidden="true"></i> </span
		></span>
		<span
			class="ag-group-contracted"
			[ngClass]="{ 'ag-hidden && hasChildren': isExpanded }"
			[ngStyle]="!hasChildren && { display: 'none' }"
			ref="eContracted"
			aria-hidden="true"
			><span (click)="toggleRow($event)" unselectable="on" role="presentation">
				<i class="fa fa-plus-square" aria-hidden="true"></i> </span
		></span>
		<span
			class="ag-group-contracted"
			*ngIf="!hasChildren && level <= 2 && levelData !== 'Brand Low'"
		>
			<span
				(click)="selectSubCategory($event)"
				(click)="toggleRow($event)"
				unselectable="on"
				role="presentation"
			>
				<i class="fa fa-plus-square" aria-hidden="true"></i>
			</span>
		</span>
		<span
			ref="eValue"
			class="cvalueC"
			[ngStyle]="{ 'font-weight': (rowId == 0 || rowId == 1) && !child ? 700 : 400 }"
			>{{ cellValue }}</span
		>
	</div>
</span>
