<div class="priceBeats">
	<div class="main-menu consumer-drive-header">
		<div>
			<div class="mr-4">
				<div class="reportHeader">
					<h4 class="m-0 reportName">{{ pageTitle }}</h4>
				</div>
				<br />
				<div class="mb-0 reportMenu">
					<div>
						<span
							promptClickOutside
							(clickOutside)="closeDropdown('fact')"
							#fact
							class="report-dropdown dropdown dropdown-ovt"
							[ngClass]="{ 'field-disableOverlay': activeView === 'detailsView' }"
						>
							<a
								id="baseFactSelectBtn"
								type="button"
								(click)="toggleDropdown('fact')"
								[niqTooltip]="factTooltipContent"
								niqTooltipPosition="bottom"
								[niqTooltipColorTheme]="'dark'"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								[ngClass]="showDropdown.fact ? 'prompt-expanded' : ''"
							>
								<span class="text">{{ beatsCtrl.fact }}</span>
							</a>
							<ng-template #factTooltipContent>
								<div style="padding: 9px 14px; color: #000000">
									{{ pageHoverTexts.basefactTooltip }}
								</div>
							</ng-template>
							<app-dropdown-menu
								[hidden]="!showDropdown.fact"
								[items]="factList"
								(selectedItem)="factSelected($event)"
								[selectorType]="3"
								[configId]="qid"
								(onDefaultChange)="onMoreOptionClicked($event)"
								(cancel)="showDropdown.fact = false"
							></app-dropdown-menu>
						</span>
						<!-- Product -->
						for
						<span
							#product
							class="report-dropdown dropdown dropdown-ovt select-dropdown"
							promptClickOutside
							(clickOutside)="closeDropdown('product')"
						>
							<a
								id="productSelect"
								type="button"
								(click)="toggleDropdown('product')"
								[niqTooltip]="productTooltipContent"
								niqTooltipPosition="bottom"
								[niqTooltipColorTheme]="'dark'"
								[ngClass]="showDropdown.product ? 'prompt-expanded' : ''"
							>
								<span class="product-input-text"> {{ selectedProductToShow.value[0] }} </span>
								<span
									*ngIf="selectedProductToShow.value.length > 1"
									title="{{ selectedProductToShow.value.join(',') }}"
									class="badge item-count pl-2"
									>+{{ selectedProductToShow.value.length - 1 }}</span
								>
							</a>
							<crf-ca-multilayer-dropdown
								[hidden]="!showDropdown.product"
								[selectorType]="qid"
								(onClose)="toggleDropdown('product')"
								(onApply)="updateProductSelection($event)"
							></crf-ca-multilayer-dropdown>

							<ng-template #productTooltipContent>
								<div style="padding: 9px 14px; color: #000000">
									{{ pageHoverTexts.productTooltip }}
								</div>
							</ng-template>
						</span>
						<!-- By Level Menu -->
						by
						<span
							promptClickOutside
							(clickOutside)="closeDropdown('byLevel')"
							#byLevel
							class="report-dropdown dropdown dropdown-ovt"
						>
							<a
								id="levelSelectorBtn"
								type="button"
								(click)="toggleDropdown('byLevel')"
								[niqTooltip]="levelTooltipContent"
								niqTooltipPosition="bottom"
								[niqTooltipColorTheme]="'dark'"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								[ngClass]="showDropdown.byLevel ? 'prompt-expanded' : ''"
								><span>{{ beatsCtrl.selectedLevelToShow }}</span>
							</a>
							<ng-template #levelTooltipContent>
								<div style="padding: 9px 14px; color: #000000">
									{{ pageHoverTexts.levelTooltip }}
								</div>
							</ng-template>
							<app-dropdown-menu
								[hidden]="!showDropdown.byLevel"
								[items]="categoryList"
								(selectedItem)="levelSelected($event)"
								[selectorType]="1"
								[configId]="qid"
								(cancel)="showDropdown.byLevel = false"
							></app-dropdown-menu>
						</span>
						during
						<span
							promptClickOutside
							(clickOutside)="closeDropdown('period')"
							#period
							class="report-dropdown dropdown dropdown-ovt"
						>
							<a
								id="periodSelectorBtn"
								type="button"
								(click)="toggleDropdown('period')"
								[niqTooltip]="periodTooltipContent"
								niqTooltipPosition="bottom"
								[niqTooltipColorTheme]="'dark'"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								[ngClass]="showDropdown.period ? 'prompt-expanded' : ''"
							>
								<span>
									{{ beatsCtrl.period }}
								</span>
							</a>
							<ng-template #periodTooltipContent>
								<div style="padding: 9px 14px; color: #000000">
									{{ pageHoverTexts.periodTooltip }}
								</div>
							</ng-template>
							<app-dropdown-menu
								[hidden]="!showDropdown.period"
								[items]="periodList"
								(selectedItem)="periodSelected($event)"
								[selectorType]="5"
								[configId]="qid"
								(onDefaultChange)="onMoreOptionClicked($event)"
								(cancel)="showDropdown.period = false"
							></app-dropdown-menu>
						</span>
					</div>
				</div>

				<crf-ca-export-report
					(onExport)="reportDownload($event)"
					[pptIcon]="activeView === 'mapContainer'"
				>
				</crf-ca-export-report>
				<crf-ca-save-default
					class="save-selection-all"
					[selectorType]="7"
					[configId]="qid"
					(menuSelector)="onMoreOptionClicked($event)"
				>
				</crf-ca-save-default>
			</div>
		</div>
	</div>

	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
		<div id="loadData" *ngIf="showOverlay">
			<div id="overlay" class="ovt-general-overlay"></div>
			<div class="ovt-no-data-message" *ngIf="noDataAvailable">
				<span class="glyphicon glyphicon-exclamation-sign"></span>
				<span>Data Not Available</span>
				<span id="closeId" class="ovt-no-data-message-close" (click)="hideOverlay()">&times;</span>
			</div>
		</div>
		<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 order-summary-data">
			<!-- deep dive map table content  -->
			<div class="deep-dive-container">
				<div class="dashboard-secondary-tabs-nav">
					<nav class="navbar">
						<ul class="nav navbar-nav">
							<li
								[class.active]="activeView === 'mapContainer'"
								(click)="setActiveView('mapContainer')"
							>
								<a class="by-level-input-alias" data-toggle="tab" aria-expanded="true">
									<span class="by-level-input-text ng-binding">
										{{ selectedProductToShow.level }}: {{ selectedProductToShow.value[0] }}
									</span>
									<span
										*ngIf="selectedProductToShow.value.length > 1"
										title="{{ selectedProductToShow.value.join(',') }}"
										class="by-level-more-icon"
										>+{{ selectedProductToShow.value.length - 1 }}</span
									>
								</a>
							</li>
							<li
								[class.active]="activeView === 'detailsView'"
								(click)="setActiveView('detailsView')"
							>
								<a class="by-level-input-alias" data-toggle="tab" aria-expanded="false">
									<span class="by-level-input-text"> Details </span>
								</a>
							</li>
						</ul>
						<div class="pull-right market-area">
							<div
								class="details-view-ordered-by-summary"
								*ngIf="activeView === 'detailsView' && columnSortConfig.sortOrder"
							>
								<span>
									<i
										[class]="
											columnSortConfig.sortOrder === 'asc' ? 'fa fa-arrow-up' : 'fa fa-arrow-down'
										"
										aria-hidden="true"
									></i>
								</span>
								<span> {{ columnSortConfig.headerName }}</span>
							</div>
							<div *ngIf="activeView === 'mapContainer'">
								<span class="page-back-arrow" [hidden]="currentMarket === 'Total US'">
									<span (click)="backToTotalUS()" class="tooltiptext" title="Back to TOTAL US">
										<img src="assets/img/niq-icons/back-arrow_black.svg" alt="" />
									</span>
								</span>
								<strong class="ng-binding"> {{ currentMarket }} </strong>
							</div>
						</div>
					</nav>
				</div>
				<div class="deep-dive-tabs">
					<!-- Map Data -->
					<div class="tab-pane active" *ngIf="activeView === 'mapContainer'" id="mapContainer">
						<div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
							<div *ngIf="mapLoader">
								<span class="spinner-large">
									<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
								</span>
							</div>

							<!--Map-->
							<div class="deep-dive-map-height">
								<crf-ca-gis-map
									[hidden]="mapLoader"
									(gisClick)="onMapClick($event)"
									(gisMouseEnter)="onMapHover($event)"
									[reportName]="reportName"
								></crf-ca-gis-map>
							</div>
						</div>
						<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
							<div class="deep-dive-table-header">
								<h5>
									<span class="ng-binding"> {{ beatsCtrl.fact }} </span> in
									<span class="ng-binding"> {{ currentMarket }} </span>:
									<span *ngIf="beatsCtrl.fact !== 'Units Beats'">{{
										selectedCMA_Val | number: '1.1-1'
									}}</span>
									<span *ngIf="beatsCtrl.fact === 'Units Beats'">{{
										selectedCMA_Val | number: '1.0-0'
									}}</span>
									<span
										*ngIf="
											selectedBaseFact !== 'Units Beats' &&
											selectedBaseFact !== 'Beats%Chg' &&
											selectedBaseFact !== 'Price Gap % Chg'
										"
									>
										%</span
									>
								</h5>
							</div>
							<div class="deep-dive-table-column">
								<span class="spinner-large" *ngIf="tspcListLoader">
									<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
								</span>
								<table class="table table-bordered" aria-describedby="">
									<thead>
										<tr>
											<th scope="col">
												Top 10 <span> {{ beatsCtrl.selectedLevelToShow }} </span>
											</th>
											<th scope="col">
												<span> {{ beatsCtrl.fact }} </span>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr class="beats-no-table" *ngIf="tspcList.length === 0 && !tspcListLoader">
											<td class="table-loader">
												<span class="no-data"> No data available </span>
											</td>
										</tr>
										<tr *ngFor="let item of tspcList">
											<td>{{ item.prodLevel }}</td>
											<td [ngSwitch]="selectedBaseFact">
												<span *ngSwitchCase="'Units Beats'">
													<span [ngStyle]="checkColor(item.unitsBeats)">
														{{ item.unitsBeats | number: '1.0' }}
													</span>
												</span>

												<span *ngSwitchCase="'%Beats'">
													<span [ngStyle]="checkColor(item.ptBeats)">
														{{ item.ptBeats | number: '1.1-1' }} %
													</span>
												</span>

												<span *ngSwitchCase="'Beats%Chg'">
													<span [ngStyle]="checkColor(item.beatsPtChg)">
														{{ item.beatsPtChg | number: '1.1-1' }}
													</span>
												</span>

												<span *ngSwitchCase="'Price Gap'">
													<span [ngStyle]="checkColor(item.priceGap)">
														{{ item.priceGap | number: '1.1-1' }} %
													</span>
												</span>

												<span *ngSwitchCase="'Price Gap % Chg'">
													<span [ngStyle]="checkColor(item.priceGapPtChg)">
														{{ item.priceGapPtChg | number: '1.1-1' }}
													</span>
												</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div class="clearfix spacer-20"></div>

							<div class="deep-dive-table-column">
								<span class="spinner-large" *ngIf="bspcListLoader">
									<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
								</span>
								<table class="table table-bordered" aria-describedby="">
									<thead>
										<tr>
											<th scope="col">
												Bottom 10 <span> {{ beatsCtrl.selectedLevelToShow }} </span>
											</th>
											<th scope="col">
												<span> {{ beatsCtrl.fact }} </span>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngIf="bspcList.length === 0 && !bspcListLoader">
											<td class="table-loader">
												<span class="no-data"> No data available </span>
											</td>
										</tr>
										<tr *ngFor="let item of bspcList">
											<td>{{ item.prodLevel }}</td>
											<td [ngSwitch]="selectedBaseFact">
												<span *ngSwitchCase="'Units Beats'">
													<span [ngStyle]="checkColor(item.unitsBeats)">
														{{ item.unitsBeats | number: '1.0' }}
													</span>
												</span>

												<span *ngSwitchCase="'%Beats'">
													<span [ngStyle]="checkColor(item.ptBeats)">
														{{ item.ptBeats | number: '1.1-1' }} %
													</span>
												</span>

												<span *ngSwitchCase="'Beats%Chg'">
													<span [ngStyle]="checkColor(item.beatsPtChg)">
														{{ item.beatsPtChg | number: '1.1-1' }}
													</span>
												</span>

												<span *ngSwitchCase="'Price Gap'">
													<span [ngStyle]="checkColor(item.priceGap)">
														{{ item.priceGap | number: '1.1-1' }} %
													</span>
												</span>

												<span *ngSwitchCase="'Price Gap % Chg'">
													<span [ngStyle]="checkColor(item.priceGapPtChg)">
														{{ item.priceGapPtChg | number: '1.1-1' }}
													</span>
												</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<!-- Table Data -->
					<div class="tab-pane active" *ngIf="activeView === 'detailsView'" id="detailsView">
						<div class="details-view-table-container custom p-3">
							<span class="spinner-large" *ngIf="isSlicedDetailsTableDataLoading">
								<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
							</span>

							<!-- Grid table -->
							<crf-ag-grid
								#grid
								[columns]="colDefs"
								[sort]="true"
								[rowData]="wmtsList"
								[flex]="true"
								[reportName]="reportName"
							>
							</crf-ag-grid>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="col-xs-12 footnote-block">
		<crf-ca-dashboard-footnote [reportDimension]="beatsCtrl"> </crf-ca-dashboard-footnote>
	</div>
</div>
