<div class="details">
	<div class="summary px-2 pt-1 text-center" [style.width]="level < 3 ? '120px' : '100%'">
		<div
			class="d-flex justify-content-center"
			[niqTooltip]="node.treenode"
			niqTooltipPosition="right"
			[niqTooltipShowArrow]="true"
		>
			<p class="m-0" [style.max-width]="level < 3 ? '80px' : 'max-content'">{{ node.treenode }}</p>
			<p class="m-0">({{ node.childs.length }})</p>
		</div>
		<div style="width: 100%; position: relative">
			<niq-icon-button
				[iconName]="displayTree ? 'indeterminate' : 'add-large'"
				colorTheme="dark"
				type="secondary"
				size="x-small"
				showTooltip="false"
				(click)="displayTree = !displayTree"
			>
			</niq-icon-button>
			<niq-icon-button
				style="position: absolute; right: 0"
				*ngIf="displayTree && node.childs.length > 3"
				[iconName]="
					level < 3
						? displayMore
							? 'chevron-up'
							: 'chevron-down'
						: displayMore
						? 'chevron-left'
						: 'chevron-right'
				"
				colorTheme="dark"
				type="secondary"
				size="x-small"
				showTooltip="false"
				(click)="displayMore = !displayMore"
			>
			</niq-icon-button>
		</div>
	</div>
	<ul [class]="level < 3 ? 'subtree-block' : 'subtree-flex'" [hidden]="!displayTree">
		<ng-template ngFor let-childNode let-index="index" [ngForOf]="node.childs">
			<li *ngIf="displayMore || index < 3">
				<div
					*ngIf="childNode.childs && childNode.childs.length > 0; else singleNodeView"
					class="d-flex"
				>
					<ng-template ngFor let-subchild let-subindex="index" [ngForOf]="childNode.childs">
						<crf-ca-node
							#parentNode
							[childNode]="childNode"
							[level]="level"
							[category]="node.treenode"
							[parent]="this"
						>
						</crf-ca-node>
						<crf-ca-consumer-decision-tree-node
							[node]="subchild"
							[level]="level + 1"
							[parent]="parentNode"
						></crf-ca-consumer-decision-tree-node>
					</ng-template>
				</div>
				<ng-template #singleNodeView>
					<crf-ca-node
						[childNode]="childNode"
						[level]="level"
						[category]="node.treenode"
						[parent]="this"
					>
					</crf-ca-node>
				</ng-template>
			</li>
		</ng-template>
	</ul>
</div>
