import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import {
	BASE_URL,
	OVT_UI_URL_AZ_SEARCH,
	OVT_UI_URL_DASHBOARD_SELECTION
} from 'src/environments/env.config.constants';
import { NlsnDataStoreService } from '../core/service/store/nlsn-data-store.service';
import { KEY } from '../constants/data.constant';
import { NlsnGlobalsContextExtract } from '../core/service/auth/nlsn-globals.service';

@Injectable({
	providedIn: 'root'
})
export class ProductSearchService {
	constructor(
		public http: HttpClient,
		private nlsnDataStoreService: NlsnDataStoreService,
		private nlsnGlobalsContextExtract: NlsnGlobalsContextExtract
	) {}

	public getChildLevels(queid, productlevel, prodObj?): Observable<any> {
		let pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			OVT_UI_URL_AZ_SEARCH.GET_CHILD_LEVELS +
			queid +
			'/' +
			productlevel;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		if (queid === 2) {
			pingURL = pingURL + prodObj;
		}
		return this.http.get<any>(pingURL, headers).pipe(
			map(result => {
				return result;
			})
		);
	}

	public getSuggestionSearch(queId: number, keyToSearch: string): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			OVT_UI_URL_AZ_SEARCH.SUGGESTIONS +
			queId;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		const payload = {
			searchString: keyToSearch
		};
		return this.http.post<any>(pingURL, payload, headers).pipe(map(result => result.responseData));
	}

	public getSearchList(reqObj): Observable<any> {
		const fors = reqObj.searchType;
		const queId = reqObj.dashboardId;
		const hierrachyType = reqObj.hierrachyType;
		const optedValues = reqObj.selectedVal;
		const isMulti = reqObj.isMulti;

		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const reqHeaders = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		let reqUrl = '';
		if (!isMulti) {
			if (!fors) {
				// individual search
				reqUrl =
					BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
					OVT_UI_URL_AZ_SEARCH.INDIVIDUAL_SEARCH +
					queId +
					'/' +
					hierrachyType;
			} else if (fors) {
				//group search
				reqUrl =
					BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
					OVT_UI_URL_AZ_SEARCH.GROUP_SEARCH +
					queId +
					'/' +
					hierrachyType;
			}
		} else if (queId === 15) {
			reqUrl =
				BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
				OVT_UI_URL_AZ_SEARCH.OSA_SEARCH +
				queId +
				'/' +
				hierrachyType;
		} else {
			reqUrl =
				BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
				OVT_UI_URL_AZ_SEARCH.MULTI_SEARCH +
				queId +
				'/' +
				hierrachyType;
		}
		return this.http
			.post<any>(reqUrl, optedValues, reqHeaders)
			.pipe(map(result => result.responseData));
	}

	public saveUserSelection(queId: number, searchString) {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			OVT_UI_URL_DASHBOARD_SELECTION.SAVE_USER_SELECTION +
			queId +
			searchString;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.post<any>(pingURL, null, headers).pipe(
			map(result => {
				return result;
			})
		);
	}

	public findFreshProducts(params): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			OVT_UI_URL_DASHBOARD_SELECTION.FIND_FRESH_PRODUCTS;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.post<any>(pingURL, params, headers).pipe(
			map(result => {
				return result.responseData;
			})
		);
	}
}
