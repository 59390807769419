import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService, ToastState } from '@nielseniq/athena-core';
import { Subscription } from 'rxjs';
import { TOAST } from 'src/app/constants/toast.constant';
import { ReportService } from 'src/app/service/report.service';

@Component({
	selector: 'app-dropdown-menu',
	templateUrl: './dropdown-menu.component.html',
	styleUrl: './dropdown-menu.component.scss'
})
export class DropdownMenuComponent implements OnInit {
	@Input() items: { key: string; value: string; isSelected?: boolean }[];
	@Input() selectorType: number;
	@Input() configId: number;
	@Input() questionId: number = -1;
	@Input() showMenu: boolean = true;
	@Input() showMenuIcon: boolean = true;
	@Input() disabledDefaultOptions: boolean;
	@Input() disableMenuOptions: boolean;
	@Input() disableApply: boolean = false;

	@Output() selectedItem: EventEmitter<string> = new EventEmitter<string>();
	@Output() onDefaultChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() cancel: EventEmitter<any> = new EventEmitter<any>();

	selectedValue: any;
	originalCopy: { key: string; value: string; isSelected?: boolean }[];

	constructor(private service: ReportService, private toastService: ToastService) {}

	ngOnInit(): void {
		this.disableApply = true;
		this.disableMenuOptions = true;
	}

	checkIfSelectionModified() {
		// Check if `originalCopy` exists and has the same length as `items`
		if (!this.originalCopy || this.items.length !== this.originalCopy.length) {
			console.error('Original copy is not initialized or mismatched length.');
			this.disableMenuOptions = true; // Disable by default in case of mismatch
			return;
		}

		// Check if the current selection (`items`) is the same as the original state (`originalCopy`)
		const isSame = this.items.every(
			(item, index) => item.isSelected === this.originalCopy[index].isSelected
		);

		this.disableMenuOptions = isSame;
	}

	onSelectedChange(item) {
		if (!this.originalCopy) {
			this.originalCopy = structuredClone(this.items);
		}
		this.disableApply = false;
		this.items.forEach(itemI => {
			itemI.isSelected = itemI.key === item.key;
		});
		this.selectedValue = item;
	}

	onApply() {
		this.selectedItem.emit(this.selectedValue);
		this.checkIfSelectionModified();
	}

	getMenuResponse(data) {
		let subscription: Subscription = new Subscription(); // Initialize subscription with a default value
		if (data.menuId === 1) {
			this.disableApply = true;
			this.originalCopy = structuredClone(this.items);
		}
		//For the reports which is having implementation at report level
		if (data.menuId === 2) {
			this.disableApply = true;
			this.items = structuredClone(this.originalCopy);
		}

		if (this.questionId !== -1 && this.selectorType === 5) {
			if (data.menuId === 1 && this.selectedValue) {
				let payload = { periodIds: this.selectedValue.key };
				subscription = this.service
					.saveSelectors(this.questionId, 'periodIds', payload)
					?.subscribe({
						next: res => {
							this.originalCopy = structuredClone(this.items);
							this.toast('success', 'Success', 'Selections saved successfully');
						},
						error: err => {
							this.toast('error', 'Dropdown Menu Component', 'Get Menu Response: Invalid response');
							subscription.unsubscribe();
						},
						complete: () => subscription.unsubscribe()
					});
			} else if (data.menuId === 2) {
				this.items = structuredClone(this.originalCopy);

				this.items.forEach(item => {
					if (item.isSelected) this.onSelectedChange(item);
				});
				this.onApply();
				// subscription = this.service.getSelectors(this.questionId).subscribe({
				// 	next: res => {
				// 		if (res.responseData) {
				// 			if (res.responseData.periodIds || res.responseData.periodIds !== '') {
				// 				this.items.forEach(item => {
				// 					if (item.key === res.responseData.periodIds) this.onSelectedChange(item);
				// 				});

				// 				//Disabling apply button on reset to default
				// 				this.disableApply = true;
				// 				this.selectedItem.emit(this.selectedValue);
				// 			}
				// 		} else
				// 			this.toast(
				// 				'warning',
				// 				'Dropdown Menu Component',
				// 				'Get Menu Response: Invalid response'
				// 			);
				// 	},
				// 	error: err => {
				// 		this.toast('error', 'Dropdown Menu Component', 'Get Menu Response: Invalid response');
				// 		subscription.unsubscribe();
				// 	},
				// 	complete: () => subscription.unsubscribe()
				// });
			}
		}

		this.onDefaultChange.emit(data);
		this.checkIfSelectionModified();
	}

	toast(type: ToastState, title: string, message: string): void {
		this.toastService.InjectToast(type, message, '', TOAST.TIMEOUT, '', title, TOAST.SIZE);
	}
}
