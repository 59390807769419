import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule, SliderModule, SpinnerModule, TooltipModule } from '@nielseniq/athena-core';
import { TrendedWeeklyComparisionPageComponent } from './trended-weekly-comparision-page.component';
import { SharedComponentModule } from 'src/app/shared/components/shared-component.module';
import { ComponentModule } from 'src/app/shared/components/selector/component.module';

@NgModule({
	declarations: [TrendedWeeklyComparisionPageComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
	imports: [
		CommonModule,
		TooltipModule,
		IconModule,
		SpinnerModule,
		SharedComponentModule,
		SliderModule,
		ComponentModule
	]
})
export class TrendedWeeklyComparisionPageModule {}
