import { Observable } from 'rxjs';
import { IAuthStatus, IAuthUser, AUTH_TYPE } from '../model/auth.model';

export interface IAuthService {
	//login(): void;
	authenticate(): Observable<boolean>;
	getAuthUser(): IAuthUser;
	getAuthStatus(): IAuthStatus;
	logout(): void;

	validateToken(): boolean;
}

export abstract class AuthService implements IAuthService {
	protected authuser: IAuthUser;
	protected authstatus: IAuthStatus;
	authType: AUTH_TYPE;

	constructor() {}

	//abstract login(); -> Handled by the middle layer
	abstract authenticate(): Observable<boolean>;
	abstract getAuthUser(): IAuthUser;
	abstract getAuthStatus(): IAuthStatus;
	abstract logout(): void;

	getToken(): string {
		if (this.validateToken()) return this.authstatus?.token;

		this.logout();
		return null;
	}

	validateToken(): boolean {
		//return Number(this.authstatus?.expiryTime) > new Date().getTime();
		return true;
	}
}
