import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { EXCEL } from 'src/app/constants/report.constant';
import { pageHoverTexts } from 'src/app/models/report-hovertexts.constant';
import { UtilityService } from 'src/app/reports/services/utility.service';

@Component({
	selector: 'crf-ca-export-report',
	templateUrl: './export-report.component.html',
	styleUrl: './export-report.component.scss'
})
export class ExportReportComponent implements OnDestroy {
	@Input() excelIcon = true;
	@Input() pptIcon = true;
	@Output() onExport = new EventEmitter<string>();
	private destroySubject: Subject<void> = new Subject();

	isExportLoading: boolean;
	pptExport: boolean;
	excelExport: boolean;
	hoverText = pageHoverTexts;
	constructor(private readonly utilityService: UtilityService) {}

	exportReq(requestFor: 'excel' | 'ppt') {
		// Emit requested value
		this.onExport.emit(requestFor);
		this.isExportLoading = true;
		if (requestFor === EXCEL) {
			this.excelExport = true;
			this.pptExport = false;
		} else {
			this.pptExport = true;
			this.excelExport = false;
		}
		this.utilityService.isExport$?.pipe(takeUntil(this.destroySubject)).subscribe(status => {
			if (status) {
				this.isExportLoading = false;
				this.excelExport = false;
				this.pptExport = false;
			}
		});
	}

	ngOnDestroy() {
		// Unsubscribe subscription
		this.destroySubject.next();
		this.destroySubject.complete();
	}
}
