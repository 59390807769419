import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'textChange',
	standalone: true
})
export class TextChangePipe implements PipeTransform {
	transform(value: string, qid?: number): string {
		const obj = {
			TOTAL: 'Total Business',
			MAJOR: 'Major Business',
			DEPT: 'Department',
			DEPARTMENT: 'Department',
			CATGRP: 'Category Group',
			CATEGORY_GROUP: 'Category Group',
			CAT: 'Category',
			CATEGORY: 'Category',
			SUBCAT: 'Subcategory',
			SEG: 'Segment',
			BRDOWNHGH: 'Brand Owner High',
			BRDOWNLOW: 'Brand Owner',
			BRDHGH: 'Brand High',
			BRDFAMILY: 'Brand Family',
			BRDLOW: 'Brand Low',
			TOTAL_BUSINESS: 'Total Business',
			MAJOR_BUSINESS: 'Major Business',
			BRAND: qid === 3 || qid === 8 ? 'Brand Type' : 'Brand Low',
			'4weeks': '4 Weeks',
			'13weeks': '13 Weeks',
			'52weeks': '52 Weeks'
		};
		return obj[value] || '';
	}
}
