import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'crf-ca-custom-icon',
	templateUrl: './custom-icon.component.html',
	styleUrl: './custom-icon.component.scss'
})
export class CustomIconComponent implements ICellRendererAngularComp {
	selectedSubCategory: string = '';
	columnParams: any = [];
	cellValue: any = [];
	hasChildren: any = [];
	isExpanded: any = [];
	indentClass: string = '';
	child: boolean = false;
	level: any = [];
	rowId: any;
	levelData: any;

	agInit(params: any): void {
		this.columnParams = params;
		this.setValueToDisplay(params);
	}
	/**
	 *
	 * @param params
	 * Set cellValues
	 * Set expanded property
	 * Set IndentClass
	 * Set Tooltip
	 * Set SubcategoryOptions
	 * Set selectedSubCategory
	 */
	setValueToDisplay(params: any) {
		this.cellValue = params?.data.productLevel;
		this.hasChildren = params?.node?.['__hasChildren'];
		this.isExpanded = params?.node?.expanded;
		this.indentClass = `ag-row-group-indent-${params?.data?.path?.length - 1}`;
		this.child = params?.data.hierarchy === 'child';
		this.level = params?.node?.level;
		this.rowId = params?.data.rowId;
		this.levelData = params?.data.bylevel;
	}

	refresh(params: ICellRendererParams<any, any, any>): boolean {
		this.setValueToDisplay(params);
		return false;
	}

	/**
	 *
	 * @param event
	 * Set row expand and collaps
	 */
	toggleRow(event) {
		event.stopPropagation();
		this.columnParams.node.setExpanded(!this.columnParams?.node?.expanded);
		this.isExpanded = this.columnParams?.node?.expanded;
	}

	/**
	 *
	 * @param _t23
	 * Close popup
	 * Send data to component
	 */
	selectSubCategory(event) {
		this.selectedSubCategory = this.columnParams.data.productLevel;
		this.columnParams.onInspectClick(this.columnParams, this.selectedSubCategory);
	}
}
