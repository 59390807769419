import { DOLLAR_SM, EQ_SM, UNITs_SM } from 'src/app/constants/report.constant';

const SALES = 'Sales';
const NO_COLOR = 'noColor';
const BLUE_COLOR = '#8CD1FA';
const CERULEAN_COLOR = '#00568B';
const DEEP_BLUE_COLOR = '#00AEEF';
const CHOCOLATE_COLOR = '#D26514';
const TEXAS_ROSE_COLOR = '#FFAD59';
const MANDYS_PINK = '#F3C098';
const SANDY_BROWN = '#EEA165';
const HAS_COLOR = 'hasColor';
const VALUE_PER = 'value%';
const VALUE = 'value';

const constantValues = {
	formatType: '',
	isSelected: false,
	cellClass: 'normal'
};
export const FACT_SPECIFICATION = [
	{
		gkey: 'WM $ Sales',
		valuefromapi: 'walmartDollars',
		factGroup: SALES,
		valuebase: DOLLAR_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'WM Units',
		valuefromapi: 'walmartUnits',
		factGroup: SALES,
		valuebase: UNITs_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM $ Sales YA',
		valuefromapi: 'walmartDollarsYA',
		factGroup: SALES,
		valuebase: DOLLAR_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'WM $ Sales 2YA',
		valuefromapi: 'walmartDollarsTwoYA',
		factGroup: SALES,
		valuebase: DOLLAR_SM,
		colorcode: BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'WM Units YA',
		valuefromapi: 'walmartUnitsYA',
		factGroup: SALES,
		valuebase: UNITs_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM Units 2YA',
		valuefromapi: 'walmartUnitsTwoYA',
		factGroup: SALES,
		valuebase: UNITs_SM,
		colorcode: BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM $ Sales',
		valuefromapi: 'remainingDollars',
		factGroup: SALES,
		valuebase: DOLLAR_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'RM Units',
		valuefromapi: 'remainingUnits',
		factGroup: SALES,
		valuebase: UNITs_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM $ Sales YA',
		valuefromapi: 'remainingDollarsYA',
		factGroup: SALES,
		valuebase: DOLLAR_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'RM $ Sales 2YA',
		valuefromapi: 'remainingDollarsTwoYA',
		factGroup: SALES,
		valuebase: DOLLAR_SM,
		colorcode: MANDYS_PINK,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'RM Units YA',
		valuefromapi: 'remainingUnitsYA',
		factGroup: SALES,
		valuebase: UNITs_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM Units 2YA',
		valuefromapi: 'remainingUnitsTwoYA',
		factGroup: SALES,
		valuebase: UNITs_SM,
		colorcode: MANDYS_PINK,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM $ Sales % Chg vs YA',
		valuefromapi: 'walmartDollarsPercentageChange',
		factGroup: 'Sales % Chg',
		valuebase: DOLLAR_SM,
		colorcode: CERULEAN_COLOR,
		color: HAS_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'WM $ Sales % Chg vs 2YA',
		valuefromapi: 'walmartDollarsPercentageChangeTwoYA',
		factGroup: 'Sales % Chg',
		valuebase: DOLLAR_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: HAS_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'WM Units % Chg vs YA',
		valuefromapi: 'walmartUnitsPercentageChange',
		factGroup: 'Sales % Chg',
		valuebase: UNITs_SM,
		colorcode: CERULEAN_COLOR,
		color: HAS_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'WM Units % Chg vs 2YA',
		valuefromapi: 'walmartUnitsPercentageChangeTwoYA',
		factGroup: 'Sales % Chg',
		valuebase: UNITs_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: HAS_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'RM $ Sales % Chg vs YA',
		valuefromapi: 'remainingDollarsPercentageChange',
		factGroup: 'Sales % Chg',
		valuebase: DOLLAR_SM,
		colorcode: CHOCOLATE_COLOR,
		color: HAS_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'RM $ Sales % Chg vs 2YA',
		valuefromapi: 'remainingDollarsPercentageChangeTwoYA',
		factGroup: 'Sales % Chg',
		valuebase: DOLLAR_SM,
		colorcode: SANDY_BROWN,
		color: HAS_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'RM Units % Chg vs YA',
		valuefromapi: 'remainingUnitsPercentageChange',
		factGroup: 'Sales % Chg',
		valuebase: UNITs_SM,
		colorcode: CHOCOLATE_COLOR,
		color: HAS_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'RM Units % Chg vs 2YA',
		valuefromapi: 'remainingUnitsPercentageChangeTwoYA',
		factGroup: 'Sales % Chg',
		valuebase: UNITs_SM,
		colorcode: SANDY_BROWN,
		color: HAS_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'WM $ Share',
		valuefromapi: 'walmartDollarSharePercentage',
		factGroup: 'Share',
		valuebase: DOLLAR_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'WM Units Share',
		valuefromapi: 'walmartUnitsSharePercentage',
		factGroup: 'Share',
		valuebase: UNITs_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'WM $ Share YA',
		valuefromapi: 'walmartDollarSharePercentageYA',
		factGroup: 'Share',
		valuebase: DOLLAR_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'WM $ Share 2YA',
		valuefromapi: 'walmartDollarSharePercentageTwoYA',
		factGroup: 'Share',
		valuebase: DOLLAR_SM,
		colorcode: BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'WM Units Share YA',
		valuefromapi: 'walmartUnitsSharePercentageYA',
		factGroup: 'Share',
		valuebase: UNITs_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'WM Units Share 2YA',
		valuefromapi: 'walmartUnitsSharePercentageTwoYA',
		factGroup: 'Share',
		valuebase: UNITs_SM,
		colorcode: BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'WM $ Share Pt Chg vs YA',
		valuefromapi: 'walmartDollarSharePointChange',
		factGroup: 'Share Pt Chg',
		valuebase: DOLLAR_SM,
		colorcode: CERULEAN_COLOR,
		color: HAS_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM $ Share Pt Chg vs 2YA',
		valuefromapi: 'walmartDollarSharePointChangeTwoYA',
		factGroup: 'Share Pt Chg',
		valuebase: DOLLAR_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: HAS_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM Units Share Pt Chg vs YA',
		valuefromapi: 'walmartUnitsSharePointChange',
		factGroup: 'Share Pt Chg',
		valuebase: UNITs_SM,
		colorcode: CERULEAN_COLOR,
		color: HAS_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM Units Share Pt Chg vs 2YA',
		valuefromapi: 'walmartUnitsSharePointChangeTwoYA',
		factGroup: 'Share Pt Chg',
		valuebase: UNITs_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: HAS_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM $ Value of Share Pt Chg vs YA',
		valuefromapi: 'walmartDollarValueOfShareChange',
		factGroup: 'Value of Share Pt Chg',
		valuebase: DOLLAR_SM,
		colorcode: CERULEAN_COLOR,
		color: HAS_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'WM $ Value of Share Pt Chg vs 2YA',
		valuefromapi: 'walmartDollarValueOfShareChangeTwoYA',
		factGroup: 'Value of Share Pt Chg',
		valuebase: DOLLAR_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: HAS_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'WM Units Value of Share Pt Chg vs YA',
		valuefromapi: 'walmartUnitsValueOfShareChange',
		factGroup: 'Value of Share Pt Chg',
		valuebase: UNITs_SM,
		colorcode: CERULEAN_COLOR,
		color: HAS_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM Units Value of Share Pt Chg vs 2YA',
		valuefromapi: 'walmartUnitsValueOfShareChangeTwoYA',
		factGroup: 'Value of Share Pt Chg',
		valuebase: UNITs_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: HAS_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM $ Any Promo',
		valuefromapi: 'walmartDollarAnyPromo',
		factGroup: 'Any Promo',
		valuebase: DOLLAR_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'WM Units Any Promo',
		valuefromapi: 'walmartUnitsAnyPromo',
		factGroup: 'Any Promo',
		valuebase: UNITs_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM $ Any Promo YA',
		valuefromapi: 'walmartDollarAnyPromoYA',
		factGroup: 'Any Promo',
		valuebase: DOLLAR_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'WM Units Any Promo YA',
		valuefromapi: 'walmartUnitsAnyPromoYA',
		factGroup: 'Any Promo',
		valuebase: UNITs_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM $ Any Promo',
		valuefromapi: 'remainingDollarAnyPromo',
		factGroup: 'Any Promo',
		valuebase: DOLLAR_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'RM Units Any Promo',
		valuefromapi: 'remainingUnitsAnyPromo',
		factGroup: 'Any Promo',
		valuebase: UNITs_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM $ Any Promo YA',
		valuefromapi: 'remainingDollarAnyPromoYA',
		factGroup: 'Any Promo',
		valuebase: DOLLAR_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'RM Units Any Promo YA',
		valuefromapi: 'remainingUnitsAnyPromoYA',
		factGroup: 'Any Promo',
		valuebase: UNITs_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM $ No Promo',
		valuefromapi: 'walmartDollarNoPromo',
		factGroup: 'No Promo',
		valuebase: DOLLAR_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'WM Units No Promo',
		valuefromapi: 'walmartUnitsNoPromo',
		factGroup: 'No Promo',
		valuebase: UNITs_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM $ No Promo YA',
		valuefromapi: 'walmartDollarNoPromoYA',
		factGroup: 'No Promo',
		valuebase: DOLLAR_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'WM Units No Promo YA',
		valuefromapi: 'walmartUnitsNoPromoYA',
		factGroup: 'No Promo',
		valuebase: UNITs_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM $ No Promo',
		valuefromapi: 'remainingDollarNoPromo',
		factGroup: 'No Promo',
		valuebase: DOLLAR_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'RM Units No Promo',
		valuefromapi: 'remainingUnitsNoPromo',
		factGroup: 'No Promo',
		valuebase: UNITs_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM $ No Promo YA',
		valuefromapi: 'remainingDollarNoPromoYA',
		factGroup: 'No Promo',
		valuebase: DOLLAR_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'RM Units No Promo YA',
		valuefromapi: 'remainingUnitsNoPromoYA',
		factGroup: 'No Promo',
		valuebase: UNITs_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM $ Feature and Display',
		valuefromapi: 'walmartDollarFeatureAndDisplay',
		factGroup: 'Feature and Display',
		valuebase: DOLLAR_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'WM Units Feature and Display',
		valuefromapi: 'walmartUnitsFeatureAndDisplay',
		factGroup: 'Feature and Display',
		valuebase: UNITs_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM $ Feature and Display YA',
		valuefromapi: 'walmartDollarFeatureAndDisplayYA',
		factGroup: 'Feature and Display',
		valuebase: DOLLAR_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'WM Units Feature and Display YA',
		valuefromapi: 'walmartUnitsFeatureAndDisplayYA',
		factGroup: 'Feature and Display',
		valuebase: UNITs_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM $ Feature and Display',
		valuefromapi: 'remainingDollarFeatureAndDisplay',
		factGroup: 'Feature and Display',
		valuebase: DOLLAR_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'RM Units Feature and Display',
		valuefromapi: 'remainingUnitsFeatureAndDisplay',
		factGroup: 'Feature and Display',
		valuebase: UNITs_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM $ Feature and Display YA',
		valuefromapi: 'remainingDollarFeatureAndDisplayYA',
		factGroup: 'Feature and Display',
		valuebase: DOLLAR_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'RM Units Feature and Display YA',
		valuefromapi: 'remainingUnitsFeatureAndDisplayYA',
		factGroup: 'Feature and Display',
		valuebase: UNITs_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM $ Display w/o Feature',
		valuefromapi: 'walmartDollarDisplayWithOutFeature',
		factGroup: 'Display w/o Feature',
		valuebase: DOLLAR_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'WM Units Display w/o Feature',
		valuefromapi: 'walmartUnitsDisplayWithOutFeature',
		factGroup: 'Display w/o Feature',
		valuebase: UNITs_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM $ Display w/o Feature YA',
		valuefromapi: 'walmartDollarDisplayWithOutFeatureYA',
		factGroup: 'Display w/o Feature',
		valuebase: DOLLAR_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'WM Units Display w/o Feature YA',
		valuefromapi: 'walmartUnitsDisplayWithOutFeatureYA',
		factGroup: 'Display w/o Feature',
		valuebase: UNITs_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM $ Display w/o Feature',
		valuefromapi: 'remainingDollarDisplayWithOutFeature',
		factGroup: 'Display w/o Feature',
		valuebase: DOLLAR_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'RM Units Display w/o Feature',
		valuefromapi: 'remainingUnitsDisplayWithOutFeature',
		factGroup: 'Display w/o Feature',
		valuebase: UNITs_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM $ Display w/o Feature YA',
		valuefromapi: 'remainingDollarDisplayWithOutFeatureYA',
		factGroup: 'Display w/o Feature',
		valuebase: DOLLAR_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'RM Units Display w/o Feature YA',
		valuefromapi: 'remainingUnitsDisplayWithOutFeatureYA',
		factGroup: 'Display w/o Feature',
		valuebase: UNITs_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM $ Feature w/o Display',
		valuefromapi: 'walmartDollarFeatureWithoutDisplay',
		factGroup: 'Feature w/o Display',
		valuebase: DOLLAR_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'WM Units Feature w/o Display',
		valuefromapi: 'walmartUnitsFeatureWithoutDisplay',
		factGroup: 'Feature w/o Display',
		valuebase: UNITs_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM $ Feature w/o Display YA',
		valuefromapi: 'walmartDollarFeatureWithoutDisplayYA',
		factGroup: 'Feature w/o Display',
		valuebase: DOLLAR_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'WM Units Feature w/o Display YA',
		valuefromapi: 'walmartUnitsFeatureWithoutDisplayYA',
		factGroup: 'Feature w/o Display',
		valuebase: UNITs_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM $ Feature w/o Display',
		valuefromapi: 'remainingDollarFeatureWithoutDisplay',
		factGroup: 'Feature w/o Display',
		valuebase: DOLLAR_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'RM Units Feature w/o Display',
		valuefromapi: 'remainingUnitsFeatureWithoutDisplay',
		factGroup: 'Feature w/o Display',
		valuebase: UNITs_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM $ Feature w/o Display YA',
		valuefromapi: 'remainingDollarFeatureWithoutDisplayYA',
		factGroup: 'Feature w/o Display',
		valuebase: DOLLAR_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'RM Units Feature w/o Display YA',
		valuefromapi: 'remainingUnitsFeatureWithoutDisplayYA',
		factGroup: 'Feature w/o Display',
		valuebase: UNITs_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM $ Price Decrease',
		valuefromapi: 'walmartDollarPriceDecrease',
		factGroup: 'Price Decrease',
		valuebase: DOLLAR_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'WM Units Price Decrease',
		valuefromapi: 'walmartUnitsPriceDecrease',
		factGroup: 'Price Decrease',
		valuebase: UNITs_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM $ Price Decrease YA',
		valuefromapi: 'walmartDollarPriceDecreaseYA',
		factGroup: 'Price Decrease',
		valuebase: DOLLAR_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'WM Units Price Decrease YA',
		valuefromapi: 'walmartUnitsPriceDecreaseYA',
		factGroup: 'Price Decrease',
		valuebase: UNITs_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM $ Price Decrease',
		valuefromapi: 'remainingDollarPriceDecrease',
		factGroup: 'Price Decrease',
		valuebase: DOLLAR_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'RM Units Price Decrease',
		valuefromapi: 'remainingUnitsPriceDecrease',
		factGroup: 'Price Decrease',
		valuebase: UNITs_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM $ Price Decrease YA',
		valuefromapi: 'remainingDollarPriceDecreaseYA',
		factGroup: 'Price Decrease',
		valuebase: DOLLAR_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: '$value',
		...constantValues
	},
	{
		gkey: 'RM Units Price Decrease YA',
		valuefromapi: 'remainingUnitsPriceDecreaseYA',
		factGroup: 'Price Decrease',
		valuebase: UNITs_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	// EQ Units
	{
		gkey: 'WM EQ',
		valuefromapi: 'walmartEQUnits',
		factGroup: SALES,
		valuebase: EQ_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM EQ YA',
		valuefromapi: 'walmartEQUnitsYA',
		factGroup: SALES,
		valuebase: EQ_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM EQ 2YA',
		valuefromapi: 'walmartEQUnitsTwoYA',
		factGroup: SALES,
		valuebase: EQ_SM,
		colorcode: BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM EQ',
		valuefromapi: 'remainingEQUnits',
		factGroup: SALES,
		valuebase: EQ_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM EQ YA',
		valuefromapi: 'remainingEQUnitsYA',
		factGroup: SALES,
		valuebase: EQ_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM EQ 2YA',
		valuefromapi: 'remainingEQUnitsTwoYA',
		factGroup: SALES,
		valuebase: EQ_SM,
		colorcode: MANDYS_PINK,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM EQ % Chg vs YA',
		valuefromapi: 'walmartEQUnitsPercentageChange',
		factGroup: 'Sales % Chg',
		valuebase: EQ_SM,
		colorcode: CERULEAN_COLOR,
		color: HAS_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'WM EQ % Chg vs 2YA',
		valuefromapi: 'walmartEQUnitsPercentageChangeTwoYA',
		factGroup: 'Sales % Chg',
		valuebase: EQ_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: HAS_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'RM EQ % Chg vs YA',
		valuefromapi: 'remainingEQUnitsPercentageChange',
		factGroup: 'Sales % Chg',
		valuebase: EQ_SM,
		colorcode: CHOCOLATE_COLOR,
		color: HAS_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'RM EQ % Chg vs 2YA',
		valuefromapi: 'remainingEQUnitsPercentageChangeTwoYA',
		factGroup: 'Sales % Chg',
		valuebase: EQ_SM,
		colorcode: SANDY_BROWN,
		color: HAS_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'WM EQ Share',
		valuefromapi: 'walmartEQUnitsSharePercentage',
		factGroup: 'Share',
		valuebase: EQ_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'WM EQ Share YA',
		valuefromapi: 'walmartEQUnitsSharePercentageYA',
		factGroup: 'Share',
		valuebase: EQ_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'WM EQ Share 2YA',
		valuefromapi: 'walmartEQUnitsSharePercentageTwoYA',
		factGroup: 'Share',
		valuebase: EQ_SM,
		colorcode: BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE_PER,
		...constantValues
	},
	{
		gkey: 'WM EQ Share Pt Chg vs YA',
		valuefromapi: 'walmartEQUnitsSharePointChange',
		factGroup: 'Share Pt Chg',
		valuebase: EQ_SM,
		colorcode: CERULEAN_COLOR,
		color: HAS_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM EQ Share Pt Chg vs 2YA',
		valuefromapi: 'walmartEQUnitsSharePointChangeTwoYA',
		factGroup: 'Share Pt Chg',
		valuebase: EQ_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: HAS_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM EQ Value of Share Pt Chg vs YA',
		valuefromapi: 'walmartEQUnitsValueOfShareChange',
		factGroup: 'Value of Share Pt Chg',
		valuebase: EQ_SM,
		colorcode: CERULEAN_COLOR,
		color: HAS_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM EQ Value of Share Pt Chg vs 2YA',
		valuefromapi: 'walmartEQUnitsValueOfShareChangeTwoYA',
		factGroup: 'Value of Share Pt Chg',
		valuebase: EQ_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: HAS_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM EQ Any Promo',
		valuefromapi: 'walmartEQUnitsAnyPromo',
		factGroup: 'Any Promo',
		valuebase: EQ_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM EQ Any Promo YA',
		valuefromapi: 'walmartEQUnitsAnyPromoYA',
		factGroup: 'Any Promo',
		valuebase: EQ_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM EQ Any Promo',
		valuefromapi: 'remainingEQUnitsAnyPromo',
		factGroup: 'Any Promo',
		valuebase: EQ_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM EQ Any Promo YA',
		valuefromapi: 'remainingEQUnitsAnyPromoYA',
		factGroup: 'Any Promo',
		valuebase: EQ_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM EQ No Promo',
		valuefromapi: 'walmartEQUnitsNoPromo',
		factGroup: 'No Promo',
		valuebase: EQ_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM EQ No Promo YA',
		valuefromapi: 'walmartEQUnitsNoPromoYA',
		factGroup: 'No Promo',
		valuebase: EQ_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM EQ No Promo',
		valuefromapi: 'remainingEQUnitsNoPromo',
		factGroup: 'No Promo',
		valuebase: EQ_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM EQ No Promo YA',
		valuefromapi: 'remainingEQUnitsNoPromoYA',
		factGroup: 'No Promo',
		valuebase: EQ_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM EQ Feature and Display',
		valuefromapi: 'walmartEQUnitsFeatureAndDisplay',
		factGroup: 'Feature and Display',
		valuebase: EQ_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM EQ Feature and Display YA',
		valuefromapi: 'walmartEQUnitsFeatureAndDisplayYA',
		factGroup: 'Feature and Display',
		valuebase: EQ_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM EQ Feature and Display',
		valuefromapi: 'remainingEQUnitsFeatureAndDisplay',
		factGroup: 'Feature and Display',
		valuebase: EQ_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM EQ Feature and Display YA',
		valuefromapi: 'remainingEQUnitsFeatureAndDisplayYA',
		factGroup: 'Feature and Display',
		valuebase: EQ_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM EQ Display w/o Feature',
		valuefromapi: 'walmartEQUnitsDisplayWithOutFeature',
		factGroup: 'Display w/o Feature',
		valuebase: EQ_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM EQ Display w/o Feature YA',
		valuefromapi: 'walmartEQUnitsDisplayWithOutFeatureYA',
		factGroup: 'Display w/o Feature',
		valuebase: EQ_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM EQ Display w/o Feature',
		valuefromapi: 'remainingEQUnitsDisplayWithOutFeature',
		factGroup: 'Display w/o Feature',
		valuebase: EQ_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM EQ Display w/o Feature YA',
		valuefromapi: 'remainingEQUnitsDisplayWithOutFeatureYA',
		factGroup: 'Display w/o Feature',
		valuebase: EQ_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM EQ Feature w/o Display',
		valuefromapi: 'walmartEQUnitsFeatureWithoutDisplay',
		factGroup: 'Feature w/o Display',
		valuebase: EQ_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM EQ Feature w/o Display YA',
		valuefromapi: 'walmartEQUnitsFeatureWithoutDisplayYA',
		factGroup: 'Feature w/o Display',
		valuebase: EQ_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM EQ Feature w/o Display',
		valuefromapi: 'remainingEQUnitsFeatureWithoutDisplay',
		factGroup: 'Feature w/o Display',
		valuebase: EQ_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM EQ Feature w/o Display YA',
		valuefromapi: 'remainingEQUnitsFeatureWithoutDisplayYA',
		factGroup: 'Feature w/o Display',
		valuebase: EQ_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM EQ Price Decrease',
		valuefromapi: 'walmartEQUnitsPriceDecrease',
		factGroup: 'Price Decrease',
		valuebase: EQ_SM,
		colorcode: CERULEAN_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'WM EQ Price Decrease YA',
		valuefromapi: 'walmartEQUnitsPriceDecreaseYA',
		factGroup: 'Price Decrease',
		valuebase: EQ_SM,
		colorcode: DEEP_BLUE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM EQ Price Decrease',
		valuefromapi: 'remainingEQUnitsPriceDecrease',
		factGroup: 'Price Decrease',
		valuebase: EQ_SM,
		colorcode: CHOCOLATE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	},
	{
		gkey: 'RM EQ Price Decrease YA',
		valuefromapi: 'remainingEQUnitsPriceDecreaseYA',
		factGroup: 'Price Decrease',
		valuebase: EQ_SM,
		colorcode: TEXAS_ROSE_COLOR,
		color: NO_COLOR,
		unitDesc: VALUE,
		...constantValues
	}
];
