import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainingVideoComponent } from './training-video/training-video.component';
import { TrainingDocumentComponent } from './training-document/training-document.component';
import {
	ButtonModule,
	CardModule,
	IconButtonModule,
	IconModule,
	TabModule
} from '@nielseniq/athena-core';
import { TrainingPageComponent } from './training-page.component';

@NgModule({
	declarations: [TrainingDocumentComponent, TrainingVideoComponent, TrainingPageComponent],
	imports: [CommonModule, IconModule, ButtonModule, TabModule, CardModule, IconButtonModule]
})
export class TrainingPageModule {}
