import { FACT_SPECIFICATION } from 'src/app/shared/constants/fact-sepcification/performance-quadrant.constant';
import { SELECTOR } from 'src/app/shared/constants/selector.constant';
import { SelectorConfig } from 'src/app/shared/model/report/report-header.type';

export const selectorConfigurations: SelectorConfig[] = [
	{
		id: SELECTOR.PRODUCT.ID,
		available: true,
		multiSelect: true,
		apiFetched: true
	},
	{
		id: SELECTOR.BYLEVEL.ID,
		available: true,
		multiSelect: false,
		apiFetched: true
	},
	{
		id: SELECTOR.MARKET.ID,
		available: true,
		multiSelect: true,
		apiFetched: true
	},
	{
		id: SELECTOR.PERIOD.ID,
		available: true,
		multiSelect: false,
		apiFetched: true
	},
	{
		id: SELECTOR.FACT.ID,
		available: true,
		multiSelect: {
			header: false,
			facts: true
		},
		column: {
			others: true,
			eq: true
		},
		apiFetched: false,
		value: FACT_SPECIFICATION
	}
];
