import { Injectable } from '@angular/core';
import { Observable, Subject, map } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { KEY } from 'src/app/constants/data.constant';
import { BASE_URL, ITEM_ASSORTMENT_DASHBOARD } from 'src/environments/env.config.constants';
import { NlsnDataStoreService } from 'src/app/core/service/store/nlsn-data-store.service';
import { NlsnGlobalsContextExtract } from 'src/app/core/service/auth/nlsn-globals.service';

@Injectable({
	providedIn: 'root'
})
export class AssortmentApiService {
	isInnerTable: boolean;
	tableData: any;
	mockData: any;
	innerTableCols: any = [];
	payload = {
		totalBusiness: '',
		majorBusiness: '',
		department: '',
		categoryGroup: '',
		category: '',
		subCategory: '',
		segment: '',
		brandOwnerHigh: '',
		brandOwnerLow: '',
		brandHigh: '',
		brandFamily: '',
		brandLow: '',
		market: [],
		period: '',
		cyPeriod: '',
		yaPeriod: '',
		wmMarket: [],
		rmMarket: [],
		quadrant: '',
		level: '',
		baseFact: ''
	};

	factUpdated$: Subject<any> = new Subject();

	constructor(
		private http: HttpClient,
		private nlsnDataStoreService: NlsnDataStoreService,
		public nlsnGlobalsContextExtract: NlsnGlobalsContextExtract
	) {}

	factUpdated(data) {
		this.factUpdated$.next(data);
		this.innerTableCols = data;
	}

	getAssortmentInfo(payload: any): Observable<any> {
		let pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] + ITEM_ASSORTMENT_DASHBOARD.REPORT;
		let userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		let headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};

		return this.http.post<any>(pingURL, payload, headers).pipe(
			map(result => {
				this.tableData = result;
				return this.tableData;
			})
		);
	}
	getTabData(payload: any): Observable<any> {
		let pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] + ITEM_ASSORTMENT_DASHBOARD.TAB_DATA;
		let userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		let headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};

		return this.http.post<any>(pingURL, payload, headers).pipe(
			map(result => {
				this.tableData = result;
				return this.tableData;
			})
		);
	}
}
