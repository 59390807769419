export const FACT_SPECIFICATION = [
	{
		valuebase: 'Other',
		gkey: 'Products',
		name: 'Products',
		units_display_name: 'Products',
		headerName: 'Products',
		field: 'productName',
		cellTooltip: 'toolTip',
		width: 200,
		pinnedLeft: true,
		enablePinning: true,
		cellClass: 'gridProductName',
		isSelected: false,
		isPinned: false,
		pinned: 'left'
	},
	{
		valuebase: 'Other',
		gkey: 'Product Description',
		name: 'Product Description',
		units_display_name: 'Product Description',
		headerName: 'Product Description',
		field: 'productDescription',
		cellTooltip: 'toolTipProdDesc',
		width: 200,
		pinnedLeft: true,
		enablePinning: true,
		cellClass: 'special special-header-left ',
		isSelected: false,
		isPinned: false,
		pinned: 'left'
	},
	{
		valuebase: 'Dollars',
		gkey: 'WM Share',
		name: 'WM $ Share',
		units_display_name: 'WM Units Share',
		headerName: 'WM $ Share',
		field: 'marketShare',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'special',
		isSelected: true,
		isPinned: true
	},
	{
		valuebase: 'Dollars',
		gkey: 'WM Share Pt Chg (BPS)',
		name: 'WM $ Share Pt Chg (BPS)',
		units_display_name: 'WM Units Share Pt Chg (BPS)',
		field: 'sharePtChange',
		type: 'number',
		headerName: 'WM $ Share Pt Chg (BPS)',
		width: 170,
		cellFilter: "zip:'roundednumber'",
		cellClass: 'gridFromNumber',
		isSelected: true,
		isPinned: true
	},
	{
		valuebase: 'Dollars',
		gkey: 'WM Value of Share Pt Chg',
		name: 'WM $ Value of Share Pt Chg',
		units_display_name: 'WM Units Value of Share Pt Chg',
		field: 'valueOfSharePointChange',
		type: 'number',
		headerName: 'WM $ Value of Share Pt Chg',
		width: 200,
		cellFilter: "zip:'dollar'",
		cellClass: 'gridFromNumber',
		isSelected: true,
		isPinned: true
	},
	{
		valuebase: 'Dollars',
		gkey: 'WM Sales',
		name: 'WM $ Sales',
		units_display_name: 'WM Units Sales',
		headerName: 'WM $ Sales',
		field: 'walmartSales',
		type: 'number',
		width: 170,
		cellFilter: "zip:'dollar'",
		cellClass: 'special',
		isSelected: true,
		isPinned: true
	},
	{
		valuebase: 'Dollars',
		gkey: 'WM Sales % Chg',
		name: 'WM $ Sales % Chg',
		units_display_name: 'WM Units Sales % Chg',
		headerName: 'WM $ Sales % Chg',
		field: 'walmartSalesChange',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'gridFromNumber',
		isSelected: true,
		isPinned: true
	},
	{
		valuebase: 'Dollars',
		gkey: 'WM Sales Actual Chg',
		name: 'WM $ Sales Actual Chg',
		units_display_name: 'WM Units Actual Chg',
		headerName: 'WM $ Sales Actual Chg',
		field: 'walmartSalesActualChange',
		type: 'number',
		width: 170,
		cellClass: 'gridFromNumber',
		cellFilter: "zip:'dollar'",
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Dollars',
		gkey: 'WM Sales/TDP',
		name: 'WM $/TDP',
		units_display_name: 'WM Units/TDP',
		field: 'walmartTDP',
		headerName: 'WM $/TDP',
		type: 'number',
		width: 170,
		cellFilter: "zip:'dollar'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Dollars',
		gkey: 'WM Sales/TDP % Chg',
		name: 'WM $/TDP % Chg',
		units_display_name: 'WM Units/TDP % Chg',
		field: 'walmartTDPChange',
		headerName: 'WM $/TDP % Chg',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Dollars',
		gkey: 'WM % Promo Sales',
		name: 'WM % Promo $',
		units_display_name: 'WM % Promo Units',
		field: 'walmartPromo',
		headerName: 'WM % Promo $',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Dollars',
		gkey: 'WM % Promo Sales Pt Chg (BPS)',
		name: 'WM % Promo $ Pt Chg (BPS)',
		units_display_name: 'WM % Promo Units Pt Chg (BPS)',
		field: 'walmartPromoPointChange',
		headerName: 'WM % Promo $ Pt Chg (BPS)',
		type: 'number',
		width: 170,
		cellFilter: "zip:'roundednumber'",
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Units',
		gkey: 'WM Share',
		name: 'WM Units Share',
		units_display_name: 'WM Units Share',
		headerName: 'WM Units Share',
		field: 'marketShare',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Units',
		gkey: 'WM Share Pt Chg (BPS)',
		name: 'WM Units Share Pt Chg (BPS)',
		units_display_name: 'WM Units Share Pt Chg (BPS)',
		field: 'sharePtChange',
		type: 'number',
		headerName: 'WM Units Share Pt Chg (BPS)',
		width: 170,
		cellFilter: "zip:'roundednumber'",
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Units',
		gkey: 'WM Value of Share Pt Chg',
		name: 'WM Units Value of Share Pt Chg',
		units_display_name: 'WM Units Value of Share Pt Chg',
		field: 'valueOfSharePointChange',
		type: 'number',
		headerName: 'WM Units Value of Share Pt Chg',
		width: 200,
		cellFilter: "zip:'dollar'",
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Units',
		gkey: 'WM Sales',
		name: 'WM Units Sales',
		units_display_name: 'WM Units Sales',
		headerName: 'WM Units Sales',
		field: 'walmartSales',
		type: 'number',
		width: 170,
		cellFilter: "zip:'dollar'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false,
		sort: 'desc'
	},
	{
		valuebase: 'Units',
		gkey: 'WM Sales % Chg',
		name: 'WM Units Sales % Chg',
		units_display_name: 'WM Units Sales % Chg',
		headerName: 'WM Units Sales % Chg',
		field: 'walmartSalesChange',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Units',
		gkey: 'WM Sales Actual Chg',
		name: 'WM Units Actual Chg',
		units_display_name: 'WM Units Actual Chg',
		headerName: 'WM Units Actual Chg',
		field: 'walmartSalesActualChange',
		type: 'number',
		width: 170,
		cellClass: 'gridFromNumber',
		cellFilter: "zip:'dollar'",
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Units',
		gkey: 'WM Sales/TDP',
		name: 'WM Units/TDP',
		units_display_name: 'WM Units/TDP',
		field: 'walmartTDP',
		headerName: 'WM Units/TDP',
		type: 'number',
		width: 170,
		cellFilter: "zip:'dollar'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Units',
		gkey: 'WM Sales/TDP % Chg',
		name: 'WM Units/TDP % Chg',
		units_display_name: 'WM Units/TDP % Chg',
		field: 'walmartTDPChange',
		headerName: 'WM Units/TDP % Chg',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Units',
		gkey: 'WM % Promo Sales',
		name: 'WM % Promo Units',
		units_display_name: 'WM % Promo Units',
		field: 'walmartPromo',
		headerName: 'WM % Promo Units',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Units',
		gkey: 'WM % Promo Sales Pt Chg (BPS)',
		name: 'WM % Promo Units Pt Chg (BPS)',
		units_display_name: 'WM % Promo Units Pt Chg (BPS)',
		field: 'walmartPromoPointChange',
		headerName: 'WM % Promo Units Pt Chg (BPS)',
		type: 'number',
		width: 170,
		cellFilter: "zip:'roundednumber'",
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Other',
		gkey: 'WM %ACV (UPC level data only)',
		name: 'WM %ACV (UPC level data only)',
		units_display_name: 'WM %ACV (UPC level data only)',
		field: 'walmartPerACV',
		headerName: 'WM %ACV (UPC level data only)',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Other',
		gkey: 'WM %ACV Pt Chg (UPC level data only)',
		name: 'WM %ACV Pt Chg (UPC level data only)',
		units_display_name: 'WM %ACV Pt Chg (UPC level data only)',
		field: 'walmartPerAcvPtChg',
		headerName: 'WM %ACV Pt Chg (UPC level data only)',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Other',
		gkey: 'WM Avg Unit Price',
		name: 'WM Avg Unit Price',
		units_display_name: 'WM Avg Unit Price',
		field: 'walmartAverageUnitPrice',
		headerName: 'WM Avg Unit Price',
		type: 'number',
		width: 170,
		cellFilter: "zip:'dollar2decimal'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Other',
		gkey: 'WM Avg Unit Price % Chg',
		name: 'WM Avg Unit Price % Chg',
		units_display_name: 'WM Avg Unit Price % Chg',
		field: 'walmartUnitPriceChange',
		headerName: 'WM Avg Unit Price % Chg',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Other',
		gkey: 'WM TDP Share (%)',
		name: 'WM TDP Share (%)',
		units_display_name: 'WM TDP Share (%)',
		field: 'walmartTDPShare',
		headerName: 'WM TDP Share (%)',
		type: 'number',
		width: 200,
		cellFilter: "zip:'perc'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Other',
		gkey: 'WM TDP Share Pt Chg (BPS)',
		name: 'WM TDP Share Pt Chg (BPS)',
		units_display_name: 'WM TDP Share Pt Chg (BPS)',
		field: 'walmartTDPSharePointChange',
		headerName: 'WM TDP Share Pt Chg (BPS)',
		type: 'number',
		width: 170,
		cellFilter: "zip:'roundednumber'",
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Dollars',
		gkey: 'RM Sales',
		name: 'RM $ Sales',
		units_display_name: 'RM Units Sales',
		headerName: 'RM $ Sales',
		field: 'remainingMarketSales',
		type: 'number',
		width: 170,
		cellFilter: "zip:'dollar'",
		cellClass: 'special',
		isSelected: true,
		isPinned: true
	},
	{
		valuebase: 'Dollars',
		gkey: 'RM Sales % Chg',
		name: 'RM $ Sales % Chg',
		units_display_name: 'RM Units Sales % Chg',
		headerName: 'RM $ Sales % Chg',
		field: 'remainingMarketSalesChange',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'gridFromNumber',
		isSelected: true,
		isPinned: true
	},
	{
		valuebase: 'Dollars',
		gkey: 'RM Sales Actual Chg',
		name: 'RM $ Sales Actual Chg',
		units_display_name: 'RM Units Actual Chg',
		headerName: 'RM $ Sales Actual Chg',
		field: 'remainingMarketSalesActualChange',
		type: 'number',
		width: 170,
		cellClass: 'gridFromNumber',
		cellFilter: "zip:'dollar'",
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Dollars',
		gkey: 'RM Sales/TDP',
		name: 'RM $/TDP',
		units_display_name: 'RM Units/TDP',
		field: 'remainingMarketTDP',
		headerName: 'RM $/TDP',
		type: 'number',
		width: 170,
		cellFilter: "zip:'dollar'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Dollars',
		gkey: 'RM Sales/TDP % Chg',
		name: 'RM $/TDP % Chg',
		units_display_name: 'RM Units/TDP % Chg',
		field: 'remainingMarketTDPChange',
		headerName: 'RM $/TDP % Chg',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Dollars',
		gkey: 'RM % Promo Sales',
		name: 'RM % Promo $',
		units_display_name: 'RM % Promo Units',
		field: 'remainingMarketPromo',
		headerName: 'RM % Promo $',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Dollars',
		gkey: 'RM % Promo Sales Pt Chg (BPS)',
		name: 'RM % Promo $ Pt Chg (BPS)',
		units_display_name: 'RM % Promo Units Pt Chg (BPS)',
		field: 'remainingMarketPromoPointChange',
		headerName: 'RM % Promo $ Pt Chg (BPS)',
		type: 'number',
		width: 170,
		cellFilter: "zip:'roundednumber'",
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Units',
		gkey: 'RM Sales',
		name: 'RM Units Sales',
		units_display_name: 'RM Units Sales',
		headerName: 'RM Units Sales',
		field: 'remainingMarketSales',
		type: 'number',
		width: 170,
		cellFilter: "zip:'dollar'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Units',
		gkey: 'RM Sales % Chg',
		name: 'RM Units Sales % Chg',
		units_display_name: 'RM Units Sales % Chg',
		headerName: 'RM Units Sales % Chg',
		field: 'remainingMarketSalesChange',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Units',
		gkey: 'RM Sales Actual Chg',
		name: 'RM Units Actual Chg',
		units_display_name: 'RM Units Actual Chg',
		headerName: 'RM Units Actual Chg',
		field: 'remainingMarketSalesActualChange',
		type: 'number',
		width: 170,
		cellClass: 'gridFromNumber',
		cellFilter: "zip:'dollar'",
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Units',
		gkey: 'RM Sales/TDP',
		name: 'RM Units/TDP',
		units_display_name: 'RM Units/TDP',
		field: 'remainingMarketTDP',
		headerName: 'RM Units/TDP',
		type: 'number',
		width: 170,
		cellFilter: "zip:'dollar'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Units',
		gkey: 'RM Sales/TDP % Chg',
		name: 'RM Units/TDP % Chg',
		units_display_name: 'RM Units/TDP % Chg',
		field: 'remainingMarketTDPChange',
		headerName: 'RM Units/TDP % Chg',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Units',
		gkey: 'RM % Promo Sales',
		name: 'RM % Promo Units',
		units_display_name: 'RM % Promo Units',
		field: 'remainingMarketPromo',
		headerName: 'RM % Promo Units',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Units',
		gkey: 'RM % Promo Sales Pt Chg (BPS)',
		name: 'RM % Promo Units Pt Chg (BPS)',
		units_display_name: 'RM % Promo Units Pt Chg (BPS)',
		field: 'remainingMarketPromoPointChange',
		headerName: 'RM % Promo Units Pt Chg (BPS)',
		type: 'number',
		width: 170,
		cellFilter: "zip:'roundednumber'",
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Other',
		gkey: 'RM %ACV (UPC level data only)',
		name: 'RM %ACV (UPC level data only)',
		units_display_name: 'RM %ACV (UPC level data only)',
		field: 'remainingMarketPerACV',
		headerName: 'RM %ACV (UPC level data only)',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Other',
		gkey: 'RM %ACV Pt Chg (UPC level data only)',
		name: 'RM %ACV Pt Chg (UPC level data only)',
		units_display_name: 'RM %ACV Pt Chg (UPC level data only)',
		field: 'remainingMarketPerAcvPtChg',
		headerName: 'RM %ACV Pt Chg (UPC level data only)',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Other',
		gkey: 'RM Avg Unit Price',
		name: 'RM Avg Unit Price',
		units_display_name: 'RM Avg Unit Price',
		field: 'remainingMarketAverageUnitPrice',
		headerName: 'RM Avg Unit Price',
		type: 'number',
		width: 170,
		cellFilter: "zip:'dollar2decimal'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Other',
		gkey: 'RM Avg Unit Price % Chg',
		name: 'RM Avg Unit Price % Chg',
		units_display_name: 'RM Avg Unit Price % Chg',
		field: 'remainingMarketUnitPriceChange',
		headerName: 'RM Avg Unit Price % Chg',
		type: 'number',
		width: 170,
		cellFilter: "zip:'perc'",
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Other',
		gkey: 'RM TDP Share (%)',
		name: 'RM TDP Share (%)',
		units_display_name: 'RM TDP Share (%)',
		field: 'remainingMarketTDPShare',
		headerName: 'RM TDP Share (%)',
		type: 'number',
		width: 200,
		cellFilter: "zip:'perc'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: 'Other',
		gkey: 'RM TDP Share Pt Chg (BPS)',
		name: 'RM TDP Share Pt Chg (BPS)',
		units_display_name: 'RM TDP Share Pt Chg (BPS)',
		field: 'remainingMarketTDPSharePointChange',
		headerName: 'RM TDP Share Pt Chg (BPS)',
		type: 'number',
		width: 170,
		cellFilter: "zip:'roundednumber'",
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	}
];
