<div style="text-overflow: ellipsis; overflow: hidden" [ngClass]="cellClass">
	<!-- <span *ngIf="!cellFilter">{{ params.value }}</span>
	<span *ngIf="cellFilter && this.isUPC" (click)="onUpcClick(params)">
		{{ params.value | zip: cellFilter }}
	</span>
	<span *ngIf="cellFilter && !this.isUPC">
		{{ params.value | zip: cellFilter }}
	</span> -->

	@if(!cellFilter){
	<span>{{ params.value }}</span>
	}@else if (cellFilter && this.isUPC) {
	<span (click)="onUpcClick(params)">
		{{ params.value | zip: cellFilter }}
	</span>
	}@else if (cellFilter && !this.isUPC) { @if(params.value ==='RO'){
	<img
		src="assets/img/niq-icons/raw-occasions_black.svg"
		alt="RO"
		style="width: 10px; height: 10px"
	/>
	}@else {
	<span>
		{{ params.value | zip: cellFilter }}
	</span>
	} }
</div>
