<section id="card-container">
	<div class="overlay-bg-ppt" [hidden]="!showSpinnerForProductApply"></div>
	<span *ngIf="showSpinnerForProductApply" class="spinner-large">
		<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
	</span>
	<div class="row mb-4">
		<div class="col-12">
			<div class="d-flex justify-content-between align-items-center p-4 card-container-header">
				<div class="mr-4">
					<h4 class="m-0">Welcome, {{ firstName }}</h4>
					<br />
					<p class="mb-0 defaultProduct">
						Default product selection:
						<span
							*ngIf="!selectedFilter; else selProduct"
							class="nonselection"
							(click)="toggleCardHeaderMenu()"
							>None selected
							<niq-icon iconName="triangle-alert" size="small" class="Warning-tringle"></niq-icon>
						</span>
						<ng-template #selProduct
							><span class="selection" (click)="toggleCardHeaderMenu()">{{
								selectedFilter
							}}</span></ng-template
						>
					</p>
				</div>
				<span (click)="navigateToFavorites()" class="favorite">
					<niq-icon iconName="star" size="small"></niq-icon>
				</span>
				<div class="card-header-menu" [hidden]="!isCardHeaderMenuVisible">
					<crf-ca-product-selector-single
						[configId]="qid"
						[fromMainDashboard]="true"
						(onClose)="toggleCardHeaderMenu()"
						(productUpdateFromMD)="showSpinner($event)"
						(selectedProuctMain)="selectedProduct($event)"
					></crf-ca-product-selector-single>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-12">
			<div class="card-container-main px-4" *ngFor="let category of mainCategory">
				<h4 class="my-2">{{ category.heading }}</h4>
				<div class="row">
					<div
						class="col-lg-4 col-md-6 col-sm-12 mb-4 ml-0"
						*ngFor="let question of category.subQuestions"
					>
						<div class="card">
							<div class="card-header d-flex justify-content-start">
								<p class="card-header-text" [routerLink]="question.navLink">{{ question.text }}</p>
								<div class="card-header-icon-container d-inline align-items-end">
									<a target="_blank" [routerLink]="question.navLink" class="mr-2">
										<niq-icon
											iconName="open"
											size="small"
											class="card-header-icon open-icon"
										></niq-icon>
									</a>
									&nbsp;
									<niq-icon
										(click)="toggleFavorite(question)"
										[iconName]="isFavorite(question.id) ? 'star-fill' : 'star'"
										size="small"
										class="card-header-icon favorite-icon"
									></niq-icon>
								</div>
							</div>
							<div class="card-body" [routerLink]="question.navLink">
								<img [src]="question.image" alt="" />
							</div>
							<div class="card-popup" [routerLink]="question.navLink">
								<p class="card-popup-text my-0">{{ question.altText }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
