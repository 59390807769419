<div class="app-menu-container">
	<ul class="dashboard-name-icons">
		<li
			[class]="{ 'hover-background': hover }"
			*ngFor="let app of displayMenuItems"
			(click)="navigateToAppItem(app)"
		>
			<a class="app-image mr-2 image-gap-12">
				<img [src]="app.image" alt="" width="24" height="24" />
			</a>
			<span class="app-text p-r-6">{{ app.text }}</span>
		</li>
	</ul>
</div>
