import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastService, ToastState } from '@nielseniq/athena-core';
import { ReplaySubject, takeUntil } from 'rxjs';
import { TrainingService } from 'src/app/concierge/services/training.service';
import { TOAST } from 'src/app/constants/toast.constant';

@Component({
	selector: 'crf-ca-training-page',
	templateUrl: './training-page.component.html',
	styleUrl: './training-page.component.scss'
})
export class TrainingPageComponent implements OnInit, OnDestroy {
	selectedTrainingTabValue: string = 'Job-Aids';
	trainingMaterials: any = { Documents: [], Videos: [] };

	unsubscribeOnDestroy$: ReplaySubject<any> = new ReplaySubject<any>();

	constructor(private trainingService: TrainingService, private toastService: ToastService) {}

	ngOnInit(): void {
		this.trainingService
			.getTrainingMaterial()
			.pipe(takeUntil(this.unsubscribeOnDestroy$))
			.subscribe({
				next: res => {
					if (res) this.trainingMaterials = res;
					else
						this.toast(
							'warning',
							'Something went wrong',
							'Training Material: ngOnInit: Invalid Response'
						);
					console.log(this.trainingMaterials);
				},
				error: err => {
					this.toast(
						'error',
						'Something went wrong',
						'Training Material: ngOnInit: Invalid Response'
					);
				}
			});
	}

	toast(type: ToastState, title: string, message: string): void {
		this.toastService.InjectToast(type, message, '', TOAST.TIMEOUT, '', title, TOAST.SIZE);
	}

	ngOnDestroy(): void {
		this.unsubscribeOnDestroy$.next(true);
		this.unsubscribeOnDestroy$.complete();
	}
}
