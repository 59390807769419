export const SHARE_REPORT_TAB = {
	PRODUCT: 'Product',
	CMA: 'CMA',
	PERIOD: 'Period'
};
export const TAB = {
	PRODUCT: 'product',
	CMA: 'cma'
};
export enum REPORT_TAB {
	PRODUCT = 'product',
	CMA = 'market'
}
