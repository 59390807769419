import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponentModule } from 'src/app/shared/components/shared-component.module';
import { ConsumerDecisionTreePageComponent } from './consumer-decision-tree-page.component';
import {
	IconButtonModule,
	IconModule,
	SliderModule,
	SpinnerModule,
	TilesModule,
	ToggleModule,
	TooltipModule
} from '@nielseniq/athena-core';
import { ConsumerDecisionTreeNodeComponent } from './consumer-decision-tree-node/consumer-decision-tree-node.component';
import { FormsModule } from '@angular/forms';
import { CommonComponentModule } from 'src/app/shared/components/grid/common-component.module';
import { ConsumerDecisionTreeUpcTableComponent } from './consumer-decision-tree-upc-table/consumer-decision-tree-upc-table.component';
import { NodeComponent } from './node/node.component';
import { ComponentModule } from 'src/app/shared/components/selector/component.module';

@NgModule({
	declarations: [
		ConsumerDecisionTreePageComponent,
		ConsumerDecisionTreeNodeComponent,
		ConsumerDecisionTreeUpcTableComponent,
		NodeComponent
	],
	imports: [
		CommonModule,
		IconModule,
		IconButtonModule,
		TooltipModule,
		ToggleModule,
		SliderModule,
		FormsModule,
		SpinnerModule,
		SharedComponentModule,
		CommonComponentModule,
		TilesModule,
		ComponentModule
	]
})
export class ConsumerDecisionTreePageModule {}
