<div class="container m-0">
	<div class="row">
		<div class="col-lg-12 p-4">
			<h4>Click WATCH to view the video for each topic</h4>
			<div class="accordion-container mt-4">
				<div class="accordion-tab mt-2" *ngFor="let document of trainingVideos; let index = index">
					<div
						class="accordion-tab-header p-3 d-flex align-items-center"
						(click)="
							index == currentActiveTab ? (currentActiveTab = -1) : (currentActiveTab = index)
						"
					>
						<niq-icon
							class="mr-3"
							state="primary"
							size="medium"
							[iconName]="index == currentActiveTab ? 'chevron-down' : 'chevron-right'"
							colorTheme="light"
						>
						</niq-icon>
						<p class="m-0">{{ document.topic }}</p>
					</div>
					<div class="accordion-body" *ngIf="index == currentActiveTab">
						<div
							class="row resource-container p-2 m-0 pl-5 align-items-center"
							(click)="openResource(resource.heading, resource.description, resource.blobUrl)"
							*ngFor="let resource of document.resource"
						>
							<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">{{ resource.heading }}</div>
							<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">{{ resource.description }}</div>
							<div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
								<niq-button size="medium">Watch</niq-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!--Video Popup-->
<div class="dialog-container p-5" *ngIf="isVideoDialogVisible" style="z-index: 999">
	<niq-report-card variant="half" size="one-third" [header]="videoDialogHeading">
		<ng-container card-actions>
			<niq-icon-button iconName="close" size="small" (click)="closeVideoDialog()"></niq-icon-button>
		</ng-container>
		<div card-body class="dialog-body-container">
			<video [src]="videoDialogVideoUrl" controls></video>
			<p class="mt-4">{{ videoDialogDescription }}</p>
		</div>
		<ng-container card-footer>
			<niq-button (click)="closeVideoDialog()" size="medium" variant="primary">Close</niq-button>
		</ng-container>
	</niq-report-card>
</div>
