<div class="overlay-bg-ppt" *ngIf="isLoading">
	<span class="spinner-large">
		<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
	</span>
</div>
<ag-grid-angular
	style="width: 100%; height: 500px"
	[columnDefs]="colDefs"
	[autoGroupColumnDef]="autoGroupColumnDef"
	[rowData]="rowData"
	[treeData]="true"
	[defaultColDef]="defaultColDef"
	[icons]="icons"
	[groupDefaultExpanded]="groupDefaultExpanded"
	[getDataPath]="getDataPath"
	[class]="themeClass"
	(gridReady)="onGridReady($event)"
	(sortChanged)="printSortColoumn($event)"
	[sort]="true"
	[sortingOrder]="sortingOrder"
	[getContextMenuItems]="getContextMenuItems"
>
</ag-grid-angular>
