import { Component, Input } from '@angular/core';

@Component({
	selector: 'crf-ca-training-document',
	templateUrl: './training-document.component.html',
	styleUrl: './training-document.component.scss'
})
export class TrainingDocumentComponent {
	currentActiveTab = -1;
	@Input() trainingDocuments: any = [];

	openResource(resourceUrl) {
		window.open(resourceUrl, '_blank');
	}
}
