import { inject, Injectable } from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { catchError, Observable, of, throwError } from 'rxjs';
import { AuthService } from '../../service/auth.service';
import { AUTH_TYPE } from '../../model/auth.model';

@Injectable()
export class NlsnDataRequestInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const authService: AuthService = inject(AuthService);
		let updateRequest: HttpRequest<any> = null;

		console.log(authService.getToken());
		if (authService.getToken())
			updateRequest = req.clone({
				headers: req.headers
					.set('X-Requested-With', 'XMLHttpRequest')
					.set('X-NIQ-ACCESS-TOKEN', authService.getToken())
			});
		//if (authService.authType === AUTH_TYPE.ANSWERS)
		updateRequest = req.clone({
			headers: req.headers
				.set('X-Requested-With', 'XMLHttpRequest')
				.set('X-NIQ-ACCESS-TOKEN', authService.getToken())
		});
		// else
		// 	updateRequest = req.clone({
		// 		headers: req.headers.set('X-Requested-With', 'XMLHttpRequest')
		// 	});

		return next.handle(updateRequest).pipe(
			catchError(error => {
				if (error instanceof HttpErrorResponse) {
					if (error.status === 401 || error.status === 403) {
						authService.logout();
						return of(null);
					}
				}
				return throwError(() => error);
			})
		);
	}
}
