import { Selector } from '../model/report/report-header.type';

export const SELECTOR: { [key: string]: Selector } = {
	PRODUCT: {
		ID: 'product',
		DISPLAY_NAME: 'Product'
	},
	BYLEVEL: {
		ID: 'bylevel',
		DISPLAY_NAME: 'By'
	},
	PERIOD: {
		ID: 'period',
		DISPLAY_NAME: 'Period'
	},
	MARKET: {
		ID: 'market',
		DISPLAY_NAME: 'Markets'
	},
	FACT: {
		ID: 'fact',
		DISPLAY_NAME: 'Fact'
	},
	BASE_FACT: {
		ID: 'basefact',
		DISPLAY_NAME: 'Base Fact'
	},
	PERIODENDDATE: {
		ID: 'periodenddate',
		DISPLAY_NAME: 'Period End Date'
	}
};
