import { ToastSize, ToastState } from '@nielseniq/athena-core';

export const TOAST = {
	TIMEOUT: 3000,
	SIZE: 'large' as ToastSize,

	FEEDBACK: {
		SUBMIT: {
			SUCCESS: {
				STATE: 'success' as ToastState,
				TITLE: 'Feedback submitted successfully',
				DESCRIPTION: 'Feedback successfull saved',
				ACTION_LABEL: '',
				SUB_DESCRIPTION: ''
			},
			ERROR: {
				STATE: 'error' as ToastState,
				TITLE: 'Feedback submission failed',
				DESCRIPTION: 'Something went wrong while submitting the feedback',
				ACTION_LABEL: '',
				SUB_DESCRIPTION: ''
			}
		}
	},
	NOTIFICATION: {
		READ: {
			ERROR: {
				STATE: 'error' as ToastState,
				TITLE: 'Notification read failed',
				DESCRIPTION: 'Something went wrong while fetching the notifications',
				ACTION_LABEL: '',
				SUB_DESCRIPTION: ''
			}
		}
	}
};
