import { Component, Input } from '@angular/core';

@Component({
	selector: 'crf-ca-training-video',
	templateUrl: './training-video.component.html',
	styleUrl: './training-video.component.scss'
})
export class TrainingVideoComponent {
	currentActiveTab = -1;
	@Input() trainingVideos: any = [];
	isVideoDialogVisible: boolean = false;
	videoDialogHeading: string = '';
	videoDialogVideoUrl: string = '';
	videoDialogDescription: string = '';

	openResource(resourceHeading, resourceDescription, resourceUrl) {
		this.videoDialogHeading = resourceHeading;
		this.videoDialogDescription = resourceDescription;
		this.videoDialogVideoUrl = resourceUrl;
		this.isVideoDialogVisible = true;
	}

	closeVideoDialog() {
		this.videoDialogHeading = '';
		this.videoDialogDescription = '';
		this.videoDialogVideoUrl = '';
		this.isVideoDialogVisible = false;
	}
}
