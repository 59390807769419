import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'truncateString',
	standalone: true
})
export class TruncateStringPipe implements PipeTransform {
	escapeRegexp(queryToEscape, textlength) {
		if (textlength > 0 && queryToEscape.length > textlength) {
			let text = queryToEscape;
			let stripHere = textlength;
			return text.substring(0, stripHere) + '...';
		} else {
			return queryToEscape;
		}
	}
	transform(value: string, textlength?: number): string {
		if (!textlength) {
			return value;
		}

		return this.escapeRegexp(value, textlength);
	}
}
