import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import {
	ColDef,
	GetContextMenuItems,
	GetContextMenuItemsParams,
	GridApi,
	GridReadyEvent
} from 'ag-grid-community';
import { GridColumn } from 'src/app/shared/model/grid-column.model';
import { GridCellComponent } from '../grid-cell/grid-cell.component';
import { icons, themeClass } from 'src/app/shared/model/column.config';
import { GridService } from '../grid-service/grid.service';

@Component({
	selector: 'crf-ag-grid',
	templateUrl: './grid.component.html',
	styleUrls: ['./grid.component.scss']
})
export class GridComponent<T> implements OnInit {
	constructor(private gridColumnService: GridService) {}
	@ViewChild('agGrid') public agGrid;

	@Input() id: string;
	@Input() columns: GridColumn[];
	@Input() rowData: T[];
	@Input() sort = false;
	@Output() cellClick = new EventEmitter<any>(); // Add Output property
	@Input() groupIncludeTotalFooter = false;
	@Input() groupIncludeFooter = false;
	@Input() autoGroup = false;
	@Input() pinnedTopRowData?;
	@Input() customStyle = 'height: 500px';
	@Input() reportName;

	public autoGroupColumnDef: ColDef = {
		minWidth: 300
	};
	icons = icons;
	@Input() flex = false;
	gridApi: GridApi;
	defaultColDef: ColDef | any;
	loadingTemplate;
	components = {
		cellComponent: GridCellComponent
	};

	public themeClass = themeClass;

	onGridReady(grid: GridReadyEvent): void {
		if (grid) {
			this.gridApi = grid?.api;
		}
	}

	onCellClicked(event: any): void {
		this.cellClick.emit(event); // Emit cell click event
	}
	onSort(params) {
		this.gridColumnService.onSortChanged(params);
	}

	ngOnInit(): void {
		this.defaultColDef = {
			width: 150,
			flex: this.flex ? 1 : 0,
			resizable: true,
			initialWidth: 150,
			wrapHeaderText: true,
			autoHeaderHeight: true,
			cellRenderer: GridCellComponent,
			cellStyle: (params: any) => {
				/**
				 * Color not applied  if cellClass include "special","normal","gridProductName",
				 * Apply color if cellClass match with "gridFromNumber";
				 */
				if (
					params.column.userProvidedColDef?.cellClass !== 'special' &&
					params.column.userProvidedColDef?.cellClass !== 'normal' &&
					params.column.userProvidedColDef?.cellClass !== 'gridProductName'
				) {
					let number = params.value;
					if (number < 0) {
						return {
							color: '#DD0014'
						};
					} else if (number >= 0) {
						return {
							color: '#26B910'
						};
					} else {
						return { color: '#000000' };
					}
				}
			}
		};
	}

	public getContextMenuItems: GetContextMenuItems = (params: GetContextMenuItemsParams) => {
		return this.gridColumnService.getContextMenu(params, this.gridApi, this.reportName);
	};
}
