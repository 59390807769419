<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
	<div class="foot-notes">
		<div class="fn-row">
			<span class="fn-title">Dashboard:</span>
			<span class="fn-val">{{ reportDimension.dashboardName }}</span>
		</div>
		<div class="fn-row" *ngIf="reportDimension?.selectedMarket && !reportDimension?.isCmaView">
			<span class="fn-title">Market:</span>
			<span class="fn-val" *ngIf="reportDimension.multiMarketEnabled">{{
				reportDimension.selectedMarket
			}}</span>
			<span class="fn-val" *ngIf="!reportDimension.multiMarketEnabled">{{
				reportDimension.selectedMarket
			}}</span>
		</div>
		<div class="fn-row" *ngIf="reportDimension?.selectedMarket && reportDimension?.isCmaView">
			<span class="fn-title">Markets:</span>
			<span class="fn-val">{{ 'All' }}</span>
		</div>
		<div
			class="fn-row"
			ng-if="reportDimension?.selectedProduct && reportDimension?.selectedProduct.length"
		>
			<span class="fn-title" [ngClass]="{ product: reportDimension?.freshProduct }">Product:</span>
			<span *ngIf="reportDimension?.freshProduct" class="fn-image"
				><img src="assets/img/data-robustness.svg" alt=""
			/></span>
			<span>
				<div class="fn-val" *ngFor="let level of reportDimension?.selectedProduct">
					<span class="fn-product-key">{{ level.displayName }}</span>
					<span> : {{ level.displayValue }}</span>
					<span><i class="fa fa-level-down level-sep" aria-hidden="true"></i></span>
				</div>
			</span>
		</div>
		<div class="fn-row" *ngIf="reportDimension?.selectedLevelToShow && !reportDimension?.isCmaView">
			<span class="fn-title">By:</span>
			<span class="fn-val" *ngIf="!reportDimension?.subCategory">{{
				reportDimension.selectedLevelToShow
			}}</span>
			<span class="fn-val" *ngIf="reportDimension?.subCategory">{{
				reportDimension.subCategory
			}}</span>
		</div>
		<div class="fn-row">
			<span class="fn-title" *ngIf="reportDimension?.volumeBasis">Volume Basis:</span>
			<span class="fn-val">{{ reportDimension.volumeBasis }}</span>
		</div>

		<div class="fn-row" *ngIf="reportDimension?.fact">
			<span class="fn-title">Fact:</span>
			<span class="fn-val">{{ reportDimension.fact }}</span>
		</div>

		<div class="fn-row" *ngIf="reportDimension?.baseFact">
			<span class="fn-title">Base Fact:</span>
			<span class="fn-val">{{ reportDimension.baseFact }}</span>
		</div>

		<div class="fn-row" *ngIf="reportDimension?.periodEndDate">
			<span class="fn-title">Period End Date:</span>
			<span *ngIf="reportDimension?.periodsList" class="fn-val"> Latest &nbsp; </span>
			<span *ngIf="reportDimension?.periodsList" class="fn-val multi-weeks-section">
				<span class="fn-val" *ngFor="let period of reportDimension.periodsList">
					<span *ngIf="period.isSelected">
						{{ period.gkey }}
						<span class="week-sep">, </span>
					</span></span
				>
			</span>
			<span *ngIf="reportDimension?.periodsList" class="fn-val"> &nbsp; Weeks</span>
			<span class="fn-val" *ngIf="reportDimension.period">{{ reportDimension.period }}</span>

			<span class="fn-val" *ngIf="reportDimension.twoYAEnabled">
				&nbsp;Ending {{ reportDimension.periodEndDate }}</span
			>
			<span class="fn-val" *ngIf="!reportDimension.twoYAEnabled"
				>&nbsp;Ending {{ reportDimension.periodEndDate }} vs YA</span
			>
		</div>

		<div class="fn-row" *ngIf="reportDimension?.comparisonPeriod">
			<span class="fn-title">Period Comparison:</span>
			<span class="fn-val">{{ reportDimension.comparisonPeriod }}</span>
		</div>
	</div>
</div>
<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
	<div style="height: 50px"></div>
	<div *ngIf="reportDimension?.freshProduct" class="foot-notes">
		<div class="data-indicator"><img src="assets/img/data-robustness.svg" alt="" /></div>
		<div class="data-note">
			<strong> Data Notification: </strong> Due to the percentage of random weight business in Fresh
			and how we capture these transactions in panel, there are some constraints in granularity or
			sample. Please reach out to your NIQ contact with any questions.
		</div>
	</div>
</div>
