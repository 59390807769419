import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopbarComponent } from './components/topbar/topbar.component';
import { AppSectionComponent } from './components/app-section/app-section.component';
import { FooterComponent } from './components/footer/footer.component';
import { TopbarPanelClickOutside } from './directive/topbarpanelclickoutside.directive';
import {
	BadgeModule,
	ButtonModule,
	CardModule,
	ChipModule,
	DialogModule,
	IconButtonModule,
	IconModule,
	LinkModule,
	PageHeaderModule,
	SidePanelModule,
	TabModule,
	TooltipModule
} from '@nielseniq/athena-core';
import { TrainingPageModule } from '../page/training-page/training-page.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentModule } from '../shared/components/selector/component.module';
import { PerformanceQuadrantReportPageModule } from '../page/performance-quadrant-report-page/performance-quadrant-report-page.module';
import { ConsumerDecisionTreePageModule } from '../page/consumer-decision-tree-page/consumer-decision-tree-page.module';
import { SharedComponentModule } from '../shared/components/shared-component.module';
import { TrendedWeeklyComparisionPageModule } from '../page/trended-weekly-comparision-page/trended-weekly-comparision-page.module';

@NgModule({
	declarations: [TopbarComponent, AppSectionComponent, FooterComponent, TopbarPanelClickOutside],
	imports: [
		CommonModule,
		PageHeaderModule,
		IconModule,
		SidePanelModule,
		CardModule,
		IconButtonModule,
		ButtonModule,
		TrainingPageModule,
		DialogModule,
		LinkModule,
		RouterModule,
		ReactiveFormsModule,
		BadgeModule,
		TabModule,
		ComponentModule,
		PerformanceQuadrantReportPageModule,
		ConsumerDecisionTreePageModule,
		SharedComponentModule,
		TrendedWeeklyComparisionPageModule,
		ChipModule,
		TooltipModule
	],
	exports: [TopbarComponent, AppSectionComponent, FooterComponent]
})
export class CoreModule {}
