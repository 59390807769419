import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { FavoriteService } from '../service/favorite.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
	selector: 'crf-ca-favorites-page',
	templateUrl: './favorites-page.component.html',
	styleUrls: ['./favorites-page.component.scss']
})
export class FavoritesPageComponent implements OnInit, OnDestroy {
	favCards: any[];
	private readonly unSubscribeAll: Subject<void> = new Subject<void>();
	public favoriteIDs: number[] = [];

	constructor(
		private favoriteService: FavoriteService,
		private router: Router,
		private cd: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.loadFavorites();
	}

	ngOnDestroy(): void {
		this.unSubscribeAll.next();
		this.unSubscribeAll.complete();
	}

	loadFavorites(): void {
		this.favoriteService
			.getFavorites()
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(value => {
				this.favCards = value.result;
				this.extractQuestionIds(this.favCards);
				this.cd.detectChanges();
			});
	}

	navigateTo(link: string): void {
		this.router.navigate([link]);
	}

	removeFavorite(card: any): void {
		const question = card.tblQuestion;
		const isFavorite = this.favoriteIDs.includes(question.id);
		if (isFavorite) {
			this.favoriteService.removeFavorite(question.id).subscribe(() => {
				card.isFavorite = false;
				this.loadFavorites();
			});
		}
	}

	isFavorite(id: number): boolean {
		return this.favoriteIDs.includes(id);
	}

	extractQuestionIds(data: any[]): void {
		this.favoriteIDs = data.map(item => item.tblQuestion.id);
	}
}
