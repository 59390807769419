import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ShareReportService } from '../services/share-report.service';
import { ShareReportDashboardService } from '../services/share-report-dashboard.service';
import { PeriodGroup, ReportDimension } from 'src/app/models/report-dimension.model';
import { pageHoverTexts, SELECTOR_TYPES } from 'src/app/models/report-hovertexts.constant';
import { SHARE_REPORT_TAB } from './model/tabs.config';
import { REQ_OBJ, optedSelection } from 'src/app/constants/dashboard-ui.constant';
import { UtilityService } from '../services/utility.service';
import { BASE_FACT_LIST, PERIOD_LIST } from 'src/app/models/share-prompt.constant';
import * as _ from 'lodash';
import { ProductComparisonComponent } from './tabs/product-comparison/product-comparison.component';
import { CMAComparisonComponent } from './tabs/cma-comparison/cma-comparison.component';
import { TOAST } from 'src/app/constants/toast.constant';
import { ToastService } from '@nielseniq/athena-core';
import { SHARE_REPORT_CONSTANTS } from 'src/app/reports/share-report/constants/share-report.constant';
import { cloneDeep } from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { TopbarUtilityService } from 'src/app/core/service/topbar/topbar-utility.service';
import { ReportHeaderUtility } from 'src/app/shared/service/report/report-header.utility';
import { PRODUCT_SELECTOR_MAPPING } from 'src/app/constants/report.constant';

@Component({
	selector: 'crf-ca-share-report',
	templateUrl: './share-report.component.html',
	styleUrl: './share-report.component.scss'
})
export class ShareReportComponent {
	@Output() factValueChange = new EventEmitter<void>();
	@ViewChild(ProductComparisonComponent) productComparison: ProductComparisonComponent;
	@ViewChild(CMAComparisonComponent) cmaComparison: CMAComparisonComponent;

	showDropdown = {
		product: false,
		byLevel: false,
		market: false,
		fact: false,
		period: false
	};
	qid: number = 13;
	shareReport: string = 'shareReport';
	questionId: number = 13;
	configValues: any = { isMan: 'cat', qid: 12, p_l: 'l' };
	isMultiSelector: boolean = true;
	periodCount: number;
	selectedProductToShow: string[];
	activePeriodText: string;
	activeTab = SHARE_REPORT_TAB.PRODUCT;
	shareCtrl: ReportDimension = {
		dashboardName: 'Share Report (Scan)',
		isCmaView: false,
		multiMarketEnabled: true,
		twoYAEnabled: true,
		volumeBasis: '',
		subCategory: ''
	};
	pageHoverTexts: any = pageHoverTexts;
	selectorTypes = SELECTOR_TYPES;
	categoryList: any;
	periodList: PeriodGroup[] = PERIOD_LIST;
	activePeriodsArray: any = [];
	factList: any;
	selectedFacts: any = [];
	setAsDefaultFacts: any = [];
	finalFactData: any;

	//Market list declarion
	marketList: any;
	marketMenuItems: any;
	reqObj: any = REQ_OBJ;
	errorMessage: any;

	//Declare reqObj for payload
	enableLoader: boolean;
	selectedReqWmMarketFrmDirective = [];
	selectedReqRmMarketFrmDirective = [];
	selectedReqMarketFrmDirective = [];
	selectedMarketFrmDirective = [];

	//Product declaration
	selectedProduct_Obj: any = [];
	selectedProduct: any = [];
	optedSelection: any = [];
	selectedProductOptedvalues: any = [];
	selectedProductApply: { [key: string]: any } = [];
	selectedProductActualData: any;
	loadHtml: boolean;
	loadProductHtml: boolean;
	productComparisonData: any = [];
	sortData: any = { nameCol: 'Product', sortOrder: 'asc', nameOfField: 'Products' };
	saveDefaultselectedProductActualData = {};
	objData: any;
	disableSet: boolean;
	typeToDownload: any;
	childLevelData: any = [];
	subLevelData: any = [];
	//Selected product
	selectedProductMulti = {
		TOTAL: [],
		MAJOR: [],
		DEPT: [],
		CATGRP: [],
		CAT: [],
		SUBCAT: [],
		SEG: [],
		BRDOWNHGH: [],
		BRDOWNLOW: [],
		BRDHGH: [],
		BRDFAMILY: [],
		BRDLOW: [],
		BRAND: []
	};
	selectedPeriod: PeriodGroup[];
	selectedFactsChecked: any = [];
	factDataNew: (
		| {
				gkey: string;
				name: string;
				displayName: string;
				key: string;
				field: string;
				valuebase: string;
				isSelected: boolean;
				isPinned: boolean;
				cellFilter: string;
				cellClass: string;
				isPeriodHide: boolean;
				width: number;
				period: string;
				type: string;
				availablePeriods: number[];
		  }[]
		| (
				| {
						gkey: string;
						name: string;
						displayName: string;
						key: string;
						field: string;
						valuebase: string;
						isSelected: boolean;
						formatType: string;
						isPinned: boolean;
						cellFilter: string;
						cellClass: string;
						isPeriodHide: boolean;
						width: number;
						period: string;
						type: string;
						availablePeriods: number[];
				  }
				| {
						gkey: string;
						name: string;
						displayName: string;
						key: string;
						field: string;
						valuebase: string;
						isSelected: boolean;
						cellFilter: string;
						cellClass: string;
						isPeriodHide: boolean;
						width: number;
						period: string;
						type: string;
						availablePeriods: number[];
						formatType?: undefined;
						isPinned?: undefined;
				  }
		  )[]
	)[];
	setAsDefaultFact: any = [];
	loadProductSelector: boolean;
	excelGridData: any;
	factSelectedExcel: any;
	productDataValues: any[];
	byLevelData: any;
	byLevelExpandedChildren: any[];
	getByLevelsFootNoteValue: any;
	excelByLevelData: any;

	protected readonly unSubscribeAll: Subject<void> = new Subject<void>();
	checkMarket: string;
	checkPeriod: string;
	pageTitle: any;

	constructor(
		private shareReportService: ShareReportService,
		private utilityService: UtilityService,
		public shareReportDashboardService: ShareReportDashboardService,
		private toastService: ToastService,
		private topBarUtility: TopbarUtilityService,
		private utility: ReportHeaderUtility
	) {}

	ngOnInit() {
		this.pageTitle = this.topBarUtility.getPageTitleReport();
		//Declare reqObj for payload
		this.reqObj.rmMarket = [];
		this.reqObj.wmMarket = [];
		this.reqObj.rmMarket = [];
		this.reqObj.market = ['Total US'];
		this.getDefaultProductData(); //product and bylevel
		this.getPeriodEndDate();
		this.fixedValues();
	}

	onApplyDefault(response, selector) {
		if (selector === 5 || selector === 7) {
			if (response.periodIds) {
				this.periodList.map(i => {
					i.isSelected = this.utilityService
						.defaultSelectorSplit(response.periodIds)
						.includes(i.key);
				});
			}

			this.periodOnInitial(this.periodList);
			this.getPeriodsCount();
		}

		if (selector === 2 || selector === 7) {
			this.marketMenuItems = [];

			if (response.market === 'Total US' || response.market === null) {
				Object.entries(this.marketList).map(market => {
					let marketEntry = { key: market[0], value: market[1], isSelected: false };
					this.marketMenuItems.push(marketEntry);
				});
				this.shareCtrl.selectedMarket = ['Total US'];
			} else {
				Object.entries(this.marketList).map(market => {
					let marketEntry = {
						key: market[0],
						value: market[1],
						isSelected: this.utilityService
							.defaultSelectorSplit(response.market)
							.includes(market[0])
					};
					this.marketMenuItems.push(marketEntry);
				});
				this.shareCtrl.selectedMarket = this.utilityService.defaultSelectorSplit(response.market);
			}
		}

		if (selector === 6 || selector === 7 || selector === 2) {
			let selectedFactName: any = [];
			if (response.facts) {
				selectedFactName = this.expandBaseFactList(response.facts);
			}
			this.setAsDefaultFact = [...selectedFactName];
			let factData = BASE_FACT_LIST;
			factData.map(fact => {
				if (selectedFactName.indexOf(fact[0].name) > -1 || fact[0]?.isPinned)
					fact[0].isSelected = true;
				else fact[0].isSelected = false;

				if (selectedFactName.indexOf(fact[1].name) > -1 || fact[1]?.isPinned)
					fact[1].isSelected = true;
				else fact[1].isSelected = false;

				if (selectedFactName.indexOf(fact[2].name) > -1 || fact[2]?.isPinned)
					fact[2].isSelected = true;
				else fact[2].isSelected = false;
			});
			this.factList = [];
			factData.map(fact => {
				let keyname = this.utility.getFactSelectorMapping(fact);
				this.factList.push(keyname);
			});
			//Take all coloumn as per period selected
			const PERIOD_PLACEHOLDER = '{period}';
			const GLOBAL_REG_EXP_MODIFIER = 'g';
			this.finalFactData = [];
			this.selectedPeriod.forEach(selPeriod => {
				this.factList.map(selFact => {
					let nameDoller = _.replace(
						selFact.nameDollars,
						new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
						selPeriod.gkey
					);
					let nameUnit = _.replace(
						selFact.nameUnits,
						new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
						selPeriod.gkey
					);
					let nameEQ = _.replace(
						selFact.nameEQ,
						new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
						selPeriod.gkey
					);

					let finalData = {
						value: selFact.value,
						nameDollars: nameDoller,
						nameUnits: nameUnit,
						nameEQ: nameEQ,
						isCheckedDollar: selFact.isCheckedDollar,
						isCheckedUnit: selFact.isCheckedUnit,
						isCheckedEQ: selFact.isCheckedEQ,
						isDollarPinned: selFact?.isDollarPinned,
						period: selPeriod.gkey
					};

					this.finalFactData.push(finalData);
				});
			});
			this.checkMarket = this.shareCtrl.selectedMarket.join(';');
			this.checkPeriod = this.shareCtrl.periodsList
				.filter(e => e.isSelected)
				.map(e => e.key)
				.join(';');
			const defaultSelectedFact = this.finalFactData
				.filter(x => x.isDollarPinned === true)
				.map(e => e.value);
			const removeDupsSelFact = _.union(selectedFactName);
			if (removeDupsSelFact.length > 0) {
				this.selectedFactsChecked = removeDupsSelFact;
			} else {
				this.selectedFactsChecked = _.union(defaultSelectedFact);
			}
			this.factDataNew = [...factData];
		}
	}

	expandBaseFactList(fact) {
		const PERIOD_PLACEHOLDER = '{period}';
		const GLOBAL_REG_EXP_MODIFIER = 'g';

		let expandedBaseFacts = [];
		let selectedFactName: any = this.utilityService.defaultSelectorSplit(fact);

		this.selectedPeriod.forEach(element => {
			selectedFactName.forEach(name => {
				if (name.indexOf(element.gkey) > -1) {
					name = _.replace(
						name,
						new RegExp(`${element.gkey}`, GLOBAL_REG_EXP_MODIFIER),
						PERIOD_PLACEHOLDER
					);
					expandedBaseFacts.push(name);
				} else {
					name = '';
				}
			});
		});

		return expandedBaseFacts;
	}

	onMoreOptionClicked(data) {
		if (data.menuId === 1) {
			let selectorType = this.selectorTypes[data.selectorId].type;
			//Pass facts detail in payload, once we reload the page and do set all as default
			this.onFactSelect(this.factList);
			let selectorValues = this.saveSelectionMetaData();
			let payload = this.getSaveDefaultPayload(data.menuId, data.selectorId, selectorValues);
			let queryParam: any = this.qid + '?type=' + selectorType;
			this.utilityService.saveItemAsDefault(payload, queryParam).subscribe({
				next: res => {
					for (let i in payload) {
						this.saveDefaultselectedProductActualData[i] = payload[i];
					}
					this.toastService.InjectToast(
						'success',
						'Selections saved successfully',
						'',
						TOAST.TIMEOUT,
						'',
						'Success',
						TOAST.SIZE
					);
				},
				error: err => console.log(err)
			});
		} else if (data.menuId === 2) {
			let savedData: any = [];
			switch (data.selectorId) {
				case 5:
					if (this.selectedProductActualData.responseData.periodIds) {
						let savedPeriod = this.selectedProductActualData.responseData.periodIds.split(';');
						savedData.push({ periodIds: savedPeriod });
						//Checking and unchecking the values in dropdown
						this.periodList.forEach(period => {
							period.isSelected = this.selectedProductActualData.responseData.periodIds.includes(
								period.key
							);
						});
					} else {
						savedData.push({ periodIds: '' });
						this.periodList.forEach(period => {
							period.isSelected = true;
						});
					}
					break;
				case 7:
					this.enableLoader = true;
					this.ngOnInit();
			}
			this.onApplyDefault(this.selectedProductActualData.responseData, data.selectorId);
			if (data.selectorId == 6) {
				this.selectedFacts = [];
			}
			this.onTabChangeColoumn();
		}
	}

	getSaveDefaultPayload(type, selectorId, params) {
		if (type === 1) {
			let paramsObj = {};
			if (selectorId === 0 || selectorId === 7) {
				paramsObj = {
					brand: '',
					brandFamily: '',
					brandHigh: '',
					brandLow: '',
					brandOwnerHigh:
						this.reqObj.brandOwnerHigh.length > 0 ? this.reqObj.brandOwnerHigh.join(';') : '',
					brandOwnerLow: this.reqObj.brandOwnerLow ? this.reqObj.brandOwnerLow.join(';') : '',
					byLevel: this.shareCtrl.selectedLevelToShow,
					category: this.reqObj.category.length > 0 ? this.reqObj.category.join(';') : '',
					categoryGroup:
						this.reqObj.categoryGroup.length > 0 ? this.reqObj.categoryGroup.join(';') : '',
					department: this.reqObj.department.length > 0 ? this.reqObj.department.join(';') : '',
					majorBusiness:
						this.reqObj.majorBusiness.length > 0 ? this.reqObj.majorBusiness.join(';') : '',
					segment: this.reqObj.segment.length > 0 ? this.reqObj.segment.join(';') : '',
					subCategory: this.reqObj.subCategory.length > 0 ? this.reqObj.subCategory.join(';') : '',
					totalBusiness:
						this.reqObj.totalBusiness.length > 0 ? this.reqObj.totalBusiness.join(';') : ''
				};
			}
			for (let i in this.selectorTypes) {
				if ((selectorId == Number(i) || selectorId === 7) && i !== '0') {
					if (params[this.selectorTypes[i].type] !== undefined) {
						paramsObj[this.selectorTypes[i].type] = params[this.selectorTypes[i].type];
					}
				}
			}
			return paramsObj;
		}
	}

	saveSelectionMetaData() {
		return {
			product: this.shareCtrl.selectedProduct,
			market: this.shareCtrl.selectedMarket.join(';'),
			periodIds: this.shareCtrl.periodsList
				.filter(e => e.isSelected)
				.map(e => e.key)
				.join(';'),
			facts: this.setAsDefaultFacts.join(';')
		};
	}

	toggleDropdown(dropdown: string) {
		this.showDropdown[dropdown] = !this.showDropdown[dropdown];
	}

	closeDropdown(dropdown: string) {
		this.showDropdown[dropdown] = false;
	}

	levelSelected(data: any) {
		this.enableLoader = true;
		let displayLevel = this.categoryList.filter(level => {
			return level.key === data.key;
		});
		this.shareCtrl.selectedLevelToShow = displayLevel[0].value;
		this.showDropdown['byLevel'] = false;
		// Set payload data
		this.reqObj.byprodlevel = data.key;
		this.refreshShareDashboard();
	}

	periodOnInitial(data: any) {
		this.shareCtrl.periodsList = data;
		this.activePeriodText = '';
		this.selectedPeriod = this.shareCtrl.periodsList.filter(i => i.isSelected);
		this.periodCount = this.selectedPeriod.length;
		this.selectedPeriod.forEach(period => {
			this.activePeriodText = this.activePeriodText + period.gkey + ', ';
		});
		this.activePeriodText = this.activePeriodText.slice(0, -2) + ' Weeks';

		this.showDropdown['period'] = false;
	}

	periodSelected(data: any) {
		this.factDataNew = [];
		this.shareCtrl.periodsList = data;
		this.activePeriodText = '';
		this.selectedPeriod = this.shareCtrl.periodsList.filter(i => i.isSelected);
		this.periodCount = this.selectedPeriod.length;
		this.selectedPeriod.forEach(period => {
			this.activePeriodText = this.activePeriodText + period.gkey + ', ';
		});
		this.activePeriodText = this.activePeriodText.slice(0, -2) + ' Weeks';

		this.checkPeriod = this.shareCtrl.periodsList
			.filter(e => e.isSelected)
			.map(e => e.key)
			.join(';');
		//Set factselected coloumn data
		this.onTabChangeColoumn();

		this.showDropdown['period'] = false;
	}

	onFactSelect(selectedFacts) {
		this.selectedFacts = [];
		selectedFacts
			.filter(item => item.isCheckedDollar)
			.forEach(item => {
				this.selectedFacts.push(item.nameDollars);
			});

		selectedFacts
			.filter(item => item.isCheckedUnit)
			.forEach(item => {
				this.selectedFacts.push(item.nameUnits);
			});

		selectedFacts
			.filter(item => item.isCheckedEQ)
			.forEach(item => {
				this.selectedFacts.push(item.nameEQ);
			});

		let newFactData = this.shareReportService.expandPeriodFact(
			this.selectedPeriod,
			this.selectedFacts
		);
		this.setAsDefaultFacts = [...newFactData];
		this.selectedFactsChecked = [...this.selectedFacts];
		this.toggleDropdown('fact');
		//Set factselected coloumn data
		this.onTabChangeColoumn();
	}

	onTabChangeColoumn() {
		let periodFactSelected = this.factColoumnChange();
		if (this.activeTab === 'Product') {
			this.productComparison.loadColumnDefinition(periodFactSelected);
		} else if (this.activeTab === 'CMA') {
			this.cmaComparison.loadColumnDefinition(periodFactSelected);
		}
	}

	addselectedMarket(selectedMarket: any) {
		this.reqObj.market = [];
		this.reqObj.wmMarket = [];
		this.reqObj.rmMarket = [];
		this.shareCtrl.selectedMarket = [];
		selectedMarket.forEach(item => {
			this.shareCtrl.selectedMarket.push(item.key);
			if (item.marketName === 'Total US') {
				let totalUsData = Object.entries(this.marketList).map(market => {
					if (market[0] === 'Total US') {
						this.reqObj.wmMarket.push(market[1]['walmartMarket']);
						this.reqObj.rmMarket.push(market[1]['remainingMarket']);
						this.selectedReqWmMarketFrmDirective = [];
						this.selectedReqRmMarketFrmDirective = [];
						this.selectedMarketFrmDirective = [];
						this.selectedReqMarketFrmDirective = [];
						this.reqObj.market.push(item.key);
					}
				});
			} else {
				//Update payloadData
				this.reqObj.wmMarket.push(item.value.walmartMarket);
				this.reqObj.rmMarket.push(item.value.remainingMarket);
				this.selectedReqWmMarketFrmDirective.push(item.value.walmartMarket);
				this.selectedReqRmMarketFrmDirective.push(item.value.remainingMarket);
				this.selectedMarketFrmDirective.push(item.key);
				this.selectedReqMarketFrmDirective.push(item.key);
				this.reqObj.market.push(item.key);
			}
		});
		//Disable save as default data
		this.checkMarket = this.shareCtrl.selectedMarket.join(';');
		this.showDropdown['market'] = false;
		this.refreshShareDashboard();
	}

	excelData(data) {
		this.excelGridData = data;
	}

	selectedFactExcel(data) {
		this.factSelectedExcel = data;
	}

	excelProdData(data) {
		this.excelByLevelData = data;
	}

	reportDownload(downloadType: string) {
		let fileType;
		let exportMetricsObj = [];

		let byLevelRequestMap = {
			TOTAL_BUSINESS: 'totalBusiness',
			MAJOR_BUSINESS: 'majorBusiness',
			DEPARTMENT: 'department',
			CATEGORY_GROUP: 'categoryGroup',
			CATEGORY: 'category',
			SUBCATEGORY: 'subCategory',
			SEGMENT: 'segment',
			BRAND_OWNER_HIGH: 'brandOwnerHigh',
			BRAND_OWNER_LOW: 'brandOwnerLow',
			BRAND_HIGH: 'brandHigh',
			BRAND_FAMILY: 'brandFamily',
			BRAND_LOW: 'brandLow'
		};
		this.excelGridData.forEach(element => {
			exportMetricsObj.push(element.field);
		});

		let parentColors = [];
		let childColors = [];
		let activePeriodsInComparison = [];

		if (this.selectedPeriod) {
			this.selectedPeriod.forEach(data => {
				if (data.isSelected) {
					activePeriodsInComparison.push(data.gkey);
				}
			});
		}

		if (activePeriodsInComparison.indexOf(4) !== -1) {
			parentColors.push(SHARE_REPORT_CONSTANTS.parentBarColorSet[0]);
			childColors.push(SHARE_REPORT_CONSTANTS.childBarColorSet[0]);
		}
		if (activePeriodsInComparison.indexOf(13) !== -1) {
			parentColors.push(SHARE_REPORT_CONSTANTS.parentBarColorSet[1]);
			childColors.push(SHARE_REPORT_CONSTANTS.childBarColorSet[1]);
		}
		if (activePeriodsInComparison.indexOf(52) !== -1) {
			parentColors.push(SHARE_REPORT_CONSTANTS.parentBarColorSet[2]);
			childColors.push(SHARE_REPORT_CONSTANTS.childBarColorSet[2]);
		}

		let otherReqObj = {
			...this.reqObj,
			applicationType: 'JBP',
			dataModelType: 'SHAREREPORT',
			user_sort_selection_metrics: this.sortData.nameOfField
				? this.sortData.nameOfField
				: 'dollarWalmartSales_52Week',
			user_sort_selection_order: this.sortData.sortOrder,
			slected_metrics: exportMetricsObj,
			period: this.activePeriodsArray,
			periodEndDate: this.shareCtrl.periodEndDate,
			measureSelected: this.shareCtrl.volumeBasis,
			factSelected: this.factSelectedExcel ? this.factSelectedExcel : '',
			selectedValue:
				this.shareCtrl.selectedProduct && this.shareCtrl.selectedProduct.length > 0
					? this.shareCtrl.selectedProduct.slice(-1)[0].displayValue
					: '',
			periodText:
				activePeriodsInComparison && activePeriodsInComparison.length > 0
					? activePeriodsInComparison.toString()
					: '',
			parentBarColorSet: parentColors && parentColors.length > 0 ? parentColors.toString() : '',
			childBarColorSet: childColors && childColors.length > 0 ? childColors.toString() : ''
		};

		let exportReqObject = otherReqObj;

		if (this.activeTab === 'Period') {
			exportReqObject.user_sort_selection_metrics = 'dollarWalmartSales_52Week';
		}
		if (downloadType === 'excel') {
			if (this.activeTab === 'Product') {
				//setByLevelData();
			}
			if (this.activeTab !== 'Period') {
				exportReqObject.byprodlevel = this.shareCtrl.subCategory
					? this.shareCtrl.subCategory
					: this.shareCtrl.selectedLevelToShow;
			}

			exportReqObject = Object.assign(exportReqObject, { byLevelData: this.excelByLevelData });
			fileType = downloadType;
		} else {
			fileType = downloadType;
		}
		this.utilityService.exportFile(exportReqObject, fileType);
		this.typeToDownload = downloadType;
	}
	showTab(tab) {
		switch (tab) {
			case 'product':
				this.activeTab = SHARE_REPORT_TAB.PRODUCT;
				this.sortData = { nameCol: this.activeTab, sortOrder: 'asc', nameOfField: 'Product' };
				this.shareCtrl.isCmaView = false;
				this.shareCtrl.volumeBasis = '';
				this.factColoumnChange();
				break;
			case 'cma':
				this.activeTab = SHARE_REPORT_TAB.CMA;
				this.sortData = { nameCol: this.activeTab, sortOrder: 'asc', nameOfField: 'CMA' };
				this.shareCtrl.isCmaView = true;
				this.shareCtrl.volumeBasis = '';
				this.factColoumnChange();
				break;
			case 'period':
				this.activeTab = SHARE_REPORT_TAB.PERIOD;
				this.sortData = { nameCol: 'WM $ Share', sortOrder: 'desc', nameOfField: 'Period' };
				this.shareCtrl.isCmaView = false;
				this.shareCtrl.subCategory = '';
				this.shareCtrl.volumeBasis = '$';
				break;

			default:
				break;
		}
		this.refreshShareDashboard();
	}

	/* On tab chnage set fact and period data */
	factColoumnChange() {
		let fact =
			this.selectedFacts.length > 0
				? this.selectedFacts
				: this.selectedProductActualData.responseData.facts;

		//Set factselected coloumn data
		let factPeriod = {
			periods: this.selectedPeriod,
			facts: fact
		};

		let result =
			this.selectedFacts.length > 0
				? this.shareReportService.getFactData(factPeriod)
				: this.shareReportService.getPeriodData(factPeriod);

		let periodFactSelected = {
			finalData: result.finalFactData,
			periods: this.selectedPeriod
		};
		this.finalFactData = periodFactSelected.finalData;
		this.selectedPeriod = periodFactSelected.periods;
		return periodFactSelected;
	}

	updateSortData(sortdata) {
		this.sortData = {
			nameCol: sortdata.name,
			sortOrder: sortdata.direction,
			nameOfField: sortdata.nameOfField
		};
	}

	/**** Load dashboard data on selection */
	loadShareDashboard(refreshProductData) {
		this.loadProductHtml = false;
		this.enableLoader = true;
		let data;

		const dataToLoad =
			this.activeTab === 'Product' || this.activeTab === 'Period' || refreshProductData
				? 'Product'
				: this.activeTab;
		this.shareReportDashboardService
			.getShareReportDashboardInfo(this.reqObj, dataToLoad)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(response => {
				if (!this.utilityService.isValid(response)) {
					this.enableLoader = true;
					this.loadProductHtml = false;
				} else {
					let productData =
						this.activeTab === 'Product' || this.activeTab === 'Period' || refreshProductData
							? response.responseData.ShareReportProductMetrics
							: response.responseData.ShareReportMarketMetrics;

					let i = 1;
					_.forEach(productData, (data, key) => {
						if (data.hierarchy === 'parent' || data.marketName === 'Total US') {
							data.tlevel = 0;
						} else {
							i = i + 1;
							data.tlevel = i;
							data.byLevelKey = this.reqObj.byprodlevel;
						}
						if (data.marketName) {
							data.productName = data.marketName;
						}
					});
					productData = _.orderBy(productData, 'tlevel', 'asc');
					if (this.activeTab === 'Product' || this.activeTab === 'Period') {
						this.productDataValues = [...productData];
					}
					this.digestProductData(productData);
					this.productComparisonData = [...productData];
					this.loadProductHtml = true;
					this.enableLoader = false;
				}
			});
	}

	digestProductData(productData) {
		_.forEach(productData, product => {
			if (product.hierarchy) {
				if (product.hierarchy === 'parent') {
					product.treeLevel = 0;
				} else {
					product.treeLevel = 1;
				}
			}
		});

		this.setByLevelExpandedChildrenData();

		this.byLevelData = [...productData];
		this.postProcessByLevelData();
	}

	setByLevelExpandedChildrenData() {
		let byLevelIndex = this.categoryList.findIndex(byLevelElement => {
			return this.reqObj.byprodlevel === byLevelElement.key;
		});

		this.byLevelExpandedChildren = [];

		let byLevel: any = this.categoryList[byLevelIndex];
		let byLevelExpandedChild: any = {
			key: byLevel.key,
			value: byLevel.value,
			treeLevel: 0
		};

		this.byLevelExpandedChildren.push(byLevelExpandedChild);
	}

	postProcessByLevelData() {
		_.forEach(this.byLevelData, product => {
			if (product.treeLevel === undefined) {
				product.treeLevel = 3;
			}
		});
		this.getByLevelsFootNoteValue = this.getByLevelsFootNote();
	}

	getByLevelsFootNote() {
		let byLevelsFootNote = '';

		let byLevelName0 = _.find(this.byLevelExpandedChildren, function (byLevelExpandedChild) {
			return byLevelExpandedChild.treeLevel === 0;
		});

		if (byLevelName0) {
			byLevelsFootNote += byLevelName0.value;
		}

		let byLevelName1 = _.find(this.byLevelExpandedChildren, function (byLevelExpandedChild) {
			return byLevelExpandedChild.treeLevel === 1;
		});

		if (byLevelName1) {
			byLevelsFootNote += ', ' + byLevelName1.value;
		}

		const byLevelName2 = _.find(this.byLevelExpandedChildren, function (byLevelExpandedChild) {
			return byLevelExpandedChild.treeLevel === 2;
		});

		if (byLevelName2) {
			byLevelsFootNote += ', ' + byLevelName2.value;
		}

		return byLevelsFootNote;
	}

	refreshShareDashboard() {
		this.loadShareDashboard(false);
	}

	/***after product selected update the report with new selection**/
	updatereport(selectedProduct: any) {
		let newData: any = [];
		selectedProduct.forEach((ele, index) => {
			newData.push({ key: ele.key });
		});

		this.shareCtrl.selectedProduct = [];
		this.enableLoader = true;
		let opt = this.optedSelection;
		let selectedData = [];
		/**Update selected product value to payload */
		let levelData = [];
		opt.filter((item, index) => {
			item.value = [];
			selectedProduct.forEach(product => {
				let category = product.key;
				if (item.alias_name === product.key) {
					item.value.push(product.value);
					levelData.push({ key: index, value: item.key, selected: item.alias_name });
					selectedData.push({
						displayName: item.key,
						displayValue: this.selectedProductMulti[product.key]
					});
					return item;
				}
			});
		});

		//Show product selected data in footer
		let footerShowProdData = [];
		opt.filter(ele => {
			if (ele.value.length > 0) {
				footerShowProdData.push({
					displayName: PRODUCT_SELECTOR_MAPPING[ele.alias_name].NAME,
					displayValue: ele.value.join(','),
					aliasName: ele.alias_name
				});
			}
		});
		this.shareCtrl.selectedProduct = [...footerShowProdData];
		//End
		this.reqObj.totalBusiness = opt[0].value;
		this.reqObj.majorBusiness = opt[1].value;
		this.reqObj.department = opt[2].value;
		this.reqObj.categoryGroup = opt[3].value;
		this.reqObj.category = opt[4].value;
		this.reqObj.subCategory = opt[5].value;
		this.reqObj.segment = opt[6].value;
		this.reqObj.brandOwnerHigh = opt[7].value;
		this.reqObj.brandOwnerLow = opt[8].value;
		this.reqObj.brandHigh = opt[9].value;
		this.reqObj.brandFamily = opt[10].value;
		this.reqObj.brandLow = opt[11].value;

		this.selectedProductOptedvalues = JSON.parse(JSON.stringify(selectedProduct));
		/* get level data**/
		let childLevel = levelData.slice(-1);
		this.selectedProductMulti = {
			TOTAL: [],
			MAJOR: [],
			DEPT: [],
			CATGRP: [],
			CAT: [],
			SUBCAT: [],
			SEG: [],
			BRDOWNHGH: [],
			BRDOWNLOW: [],
			BRDHGH: [],
			BRDFAMILY: [],
			BRDLOW: [],
			BRAND: []
		};
		selectedProduct.forEach(element => {
			this.selectedProductMulti[element.key].push(element.value);
		});
		this.selectedProductToShow = this.selectedProductMulti[childLevel[0].selected];
		this.getBylevels(12, childLevel[0].value);
	}

	getBylevels(qid, selectedProductLevel) {
		this.reqObj.byprodlevel = '';
		this.shareReportDashboardService
			.getChildLevels(qid, selectedProductLevel)
			.subscribe(response => {
				let responseData = response.result.map(byLevel => {
					return {
						...byLevel,
						isSelected: false
					};
				});
				this.categoryList = responseData;
				let byLevelIndex = 0;
				byLevelIndex =
					responseData.findIndex(function (level) {
						return level.key === selectedProductLevel;
					}) + 1;
				responseData[byLevelIndex].isSelected = true;
				this.shareCtrl.selectedLevelToShow = responseData[byLevelIndex]?.value;
				this.reqObj.byprodlevel = responseData[byLevelIndex]?.key;
				this.refreshShareDashboard();
			});
	}

	/* Initial level call */

	getDefaultProductData() {
		this.loadProductSelector = false;
		this.selectedProductToShow = [];
		this.enableLoader = true;
		let opt = optedSelection;
		this.shareReportDashboardService
			.getSelectedProduct(this.isMultiSelector ? 13 : 12, this.isMultiSelector)
			.subscribe(response => {
				let data = this.shareReportService.selectedProductOptedvaluesSet(response);
				let saveData = this.shareReportService.selectedProductOptedvaluesData(response);
				this.selectedProductActualData = response;
				//Data to show to footer
				let footerData = [];
				data.forEach(element => {
					footerData.push({
						displayName: PRODUCT_SELECTOR_MAPPING[element.aliasName].NAME,
						displayValue: element.displayValue
					});
				});
				this.shareCtrl.selectedProduct = footerData;
				this.getMarketData(data);

				this.objData = saveData;
				this.loadProductSelector = true;
			});
	}

	getMarketData(data) {
		this.loadHtml = false;
		let opt = optedSelection;
		this.shareReportDashboardService.getMarketListData().subscribe((response: any) => {
			this.marketList = response.responseData;
			_.forEach(this.marketList, (v, k) => {
				if (k === 'Total US') {
					delete this.marketList[v];
				}
			});
			if (this.selectedProductActualData.responseData.market) {
				this.shareCtrl.selectedMarket = [];
				this.selectedMarketFrmDirective = [];
				this.selectedProductActualData.responseData.market.split(';').forEach(market => {
					let marketObj = this.marketList[market];
					this.reqObj.wmMarket.push(marketObj.walmartMarket);
					this.reqObj.rmMarket.push(marketObj.remainingMarket);
					this.shareCtrl.selectedMarket.push(market);
					this.reqObj.market = this.shareCtrl.selectedMarket;
					this.selectedReqWmMarketFrmDirective.push(marketObj.walmartMarket);
					this.selectedReqRmMarketFrmDirective.push(marketObj.remainingMarket);
					this.selectedMarketFrmDirective.push(market);
					this.selectedReqMarketFrmDirective = this.shareCtrl.selectedMarket;
				});
			}

			/**Update selected product value to payload */
			let levelData = [];
			this.selectedProduct = [];
			opt.filter((item, index) => {
				let keyName = this.shareReportDashboardService.dashedToCamelCase(item.key);
				this.reqObj[keyName] = [];
				item.value = [];
				data.forEach(product => {
					if (item.key === product.displayName) {
						item.value.push(product.value);
						product.displayValue.split(';').forEach(productData => {
							this.reqObj[keyName].push(productData);
						});
						levelData.push({
							key: index,
							value: item.alias_name,
							levelData: product.displayValue,
							name: product.displayName
						});
					}
				});
			});
			this.selectedProduct = [...levelData];
			let childLevel = levelData.slice(-1);
			let Datatoshow = childLevel[0].levelData.replace(';', ',').split(',');
			this.selectedProductToShow = Datatoshow;
			this.getBylevels(this.isMultiSelector ? 13 : 12, childLevel[0].name);
			this.onApplyDefault(this.selectedProductActualData.responseData, 7);
			this.loadHtml = true;
		});
	}

	getPeriodEndDate() {
		this.shareReportDashboardService
			.getAODPeriodEndDate(12, 13, this.reqObj)
			.subscribe(response => {
				this.shareCtrl.periodEndDate = response.responseData.periodEndDate;
			});
	}

	getPeriodsCount() {
		this.activePeriodsArray = [];
		let countOfPeriods = 0;
		let str = '';

		this.shareCtrl.periodsList.forEach((val, key) => {
			if (val.isSelected) {
				this.activePeriodsArray.push(val.gkey);
				str = str + val.gkey + ', ';
				countOfPeriods++;
			}
		});
		let pos = str.lastIndexOf(',');
		this.activePeriodText = str.substring(0, pos) + ' Weeks' + str.substring(pos + 1);
	}

	fixedValues() {
		this.optedSelection = [
			{ key: 'TOTAL_BUSINESS', value: [], alias_name: 'TOTAL' },
			{ key: 'MAJOR_BUSINESS', value: [], alias_name: 'MAJOR' },
			{ key: 'DEPARTMENT', value: [], alias_name: 'DEPT' },
			{ key: 'CATEGORY_GROUP', value: [], alias_name: 'CATGRP' },
			{ key: 'CATEGORY', value: [], alias_name: 'CAT' },
			{ key: 'SUBCATEGORY', value: [], alias_name: 'SUBCAT' },
			{ key: 'SEGMENT', value: [], alias_name: 'SEG' },
			{ key: 'BRAND_OWNER_HIGH', value: [], alias_name: 'BRDOWNHGH' },
			{ key: 'BRAND_OWNER_LOW', value: [], alias_name: 'BRDOWNLOW' },
			{ key: 'BRAND_HIGH', value: [], alias_name: 'BRDHGH' },
			{ key: 'BRAND_FAMILY', value: [], alias_name: 'BRDFAMILY' },
			{ key: 'BRAND_LOW', value: [], alias_name: 'BRDLOW' },
			{ key: 'BRAND', value: [], alias_name: 'BRAND' }
		];
	}

	getSortColDetails(data: any) {
		this.sortData = data;
	}

	selectedVolume(measure: any) {
		if (this.activeTab === 'Period') {
			this.shareCtrl.volumeBasis = measure.value;
		} else {
			this.shareCtrl.volumeBasis = '';
		}
	}

	updateGridData(childLevel: any) {
		let expandedReqObject = this.setByLevelExpandedChildren(childLevel);
		this.childLevelData.push(expandedReqObject);
	}

	setByLevelExpandedChildren(subCategoryData) {
		let reqByproductLevel = this.reqObj.byprodlevel;
		let byLevelIndex = this.categoryList.findIndex(function (byLevelElement) {
			return reqByproductLevel === byLevelElement.key;
		});

		let childByLevel = this.categoryList[byLevelIndex + subCategoryData.subCategoryLevel];
		this.subLevelData.push(this.shareCtrl.selectedLevelToShow, childByLevel.value);
		this.shareCtrl.subCategory = [...new Set(this.subLevelData)].join(',');

		let byLevelExpandedChildren = [];

		const byLevelExpandedChild = {
			key: childByLevel.key,
			value: childByLevel.value
		};
		byLevelExpandedChildren.push(byLevelExpandedChild);

		let byLevelRequestMap = {
			TOTAL_BUSINESS: 'totalBusiness',
			MAJOR_BUSINESS: 'majorBusiness',
			DEPARTMENT: 'department',
			CATEGORY_GROUP: 'categoryGroup',
			CATEGORY: 'category',
			SUBCATEGORY: 'subCategory',
			SEGMENT: 'segment',
			BRAND_OWNER_HIGH: 'brandOwnerHigh',
			BRAND_OWNER_LOW: 'brandOwnerLow',
			BRAND_HIGH: 'brandHigh',
			BRAND_FAMILY: 'brandFamily',
			BRAND_LOW: 'brandLow'
		};

		let expandRequestObject: any = cloneDeep(this.reqObj);
		let selectedLevel1 = this.shareCtrl.selectedLevelToShow;
		let levelindex = this.categoryList.findIndex(function (byLevel1Element) {
			return selectedLevel1 === byLevel1Element.value;
		});
		if (subCategoryData.subCategoryLevel === 1) {
			let childLevelvalue = this.categoryList[levelindex + 1];
			expandRequestObject.byprodlevel = childLevelvalue.key;
			expandRequestObject[byLevelRequestMap[this.categoryList[levelindex].key]] =
				subCategoryData.subCategory;
			expandRequestObject[byLevelRequestMap[this.categoryList[levelindex + 1].key]] = '';
		} else if (subCategoryData.subCategoryLevel === 2) {
			let parentValue = subCategoryData.params.node.parent.data.productLevel;
			let childLevelvalue = this.categoryList[levelindex + 2];
			expandRequestObject.byprodlevel = childLevelvalue.key;
			expandRequestObject[byLevelRequestMap[this.categoryList[levelindex + 1].key]] =
				subCategoryData.subCategory;
			expandRequestObject[byLevelRequestMap[this.categoryList[levelindex].key]] = parentValue;
		}

		return expandRequestObject;
	}
}
