import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import 'ag-grid-enterprise';
@Component({
	selector: 'crf-lp-grid-cell',
	templateUrl: 'grid-cell.component.html',
	styleUrls: ['grid-cell.component.scss']
})
export class GridCellComponent implements AgRendererComponent {
	cellFilter: string;
	params: any = [];
	cellClass: any;
	isUPC: boolean;
	refresh(params: ICellRendererParams): boolean {
		return false;
	}

	/**
	 *
	 * @param params
	 * Set row params
	 * Set cellFilter
	 * Set CellClass
	 */
	agInit(params: any): void {
		this.params = params;
		this.cellFilter = params.column.userProvidedColDef?.cellFilter?.split("'")[1];
		this.cellClass = this.gridCountofUPC(params);
	}
	/**
	 *
	 * @param params
	 * @returns class to show click link for UPCs data
	 */
	gridCountofUPC(params) {
		if (params.colDef.cellClass === 'gridCountofUPC') {
			if (
				params?.data?.level === 'TOTAL_BUSINESS' ||
				params?.data?.level === 'MAJOR_BUSINESS' ||
				params?.data?.productName === 'TOTAL'
			) {
				return 'special special-header-left countofupcsText';
			} else {
				this.isUPC = true;
				return 'special special-header-left countofupcsLink';
			}
		} else if (params.colDef.cellClass === 'main') {
			return 'text-left';
		} else if (params.colDef.cellClass !== 'gridProductName') {
			// Fix left alignment issue
			return 'text-right';
		}
	}
	/**
	 *
	 * @param row
	 * Send UPC requested params to grid component
	 */
	onUpcClick(row) {
		let upcInfo = {
			bylevel: 'UPC',
			level: row.data.level,
			forMarketUPCS: row.colDef.headerName.split(' ')[0],
			productName: row.data.productName
		};
		this.params.onInspectClick(upcInfo);
	}
}
