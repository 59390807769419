<div class="tiles-container">
	<div class="heading" *ngFor="let tile of tilesData">
		<h4>{{ tile.heading }}</h4>
		<div class="row">
			<div class="card-container">
				<a
					class="card"
					[routerLink]="subquestion.navlink"
					*ngFor="let subquestion of tile.subquestions"
				>
					<div class="card-body">
						<p class="subquestion-text">
							<b>{{ subquestion.text }}</b>
						</p>
						<img class="card-img-top" [src]="subquestion.image" alt="" />
					</div>
				</a>
			</div>
		</div>
	</div>
</div>
