export const pageHoverTexts = {
	getDataNotAvailableMsg: 'Data Not Available',
	csvHovertext: 'Export to XL',
	pptHovertext: 'Export to PPT',
	periodTooltip: 'Choose the Period you would like to show in this report',
	levelTooltip: 'Choose the By level breakdown you would like to show in this report',
	productTooltip: 'Choose the Product you would like to show in this report',
	marketTooltip: 'Choose the Walmart Market you would like to show in this report',
	basefactTooltip: 'Choose the Base fact you would like to show in this report',
	factGroupTooltip: 'Choose the Fact Group you would like to show in this report',
	parentCompanyTooltip: 'Choose the Brand Owner High you would like to show in this report',
	brandOwnerTooltip: 'Choose the Brand Owner you would like to show in this report',
	shareToSelectorTooltip: 'Choose to show Share of Wallet by Retailer Outlets or Channels',
	compPeriodTooltip: 'Choose the comparision time period you would like to show in this report'
};

export const SELECTOR_TYPES = [
	{ type: 'products', displayName: 'Products' },
	{ type: 'byLevel', displayName: 'By level:' },
	{ type: 'market', displayName: 'Market:' },
	{ type: 'baseFact', displayName: 'Base Fact' },
	{ type: 'factType', displayName: 'Fact Type' },
	{ type: 'periodIds', displayName: 'Period:' },
	{ type: 'facts', displayName: 'Facts' },
	{ type: 'all', displayName: 'All' },
	{ type: 'comparisonPeriod', displayName: 'Comparison Period' }
];
