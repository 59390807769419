<div class="node">
	<div class="node-header p-1 d-flex justify-content-between">
		<p
			class="m-0"
			[niqTooltip]="childNode.treenode"
			niqTooltipPosition="top"
			[niqTooltipShowArrow]="true"
		>
			{{ childNode.treenode }}
		</p>
		<niq-icon
			iconName="full-screen-open"
			size="small"
			(click)="displayExpandedUpcTableView()"
		></niq-icon>
	</div>
	<div class="node-body d-flex flex-wrap text-center">
		<p class="m-0">
			WM<br />{{
				childNode.wm_share ? (childNode.wm_share * 100).toFixed().replace('.0', '') + '%' : '-'
			}}
		</p>
		<p class="m-0">
			RM<br />{{
				childNode.rem_share ? (childNode.rem_share * 100).toFixed().replace('.0', '') + '%' : '-'
			}}
		</p>
	</div>
</div>
