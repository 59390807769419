export const ROUTES_URL_TITLE = {
	'/': 'Home Page',
	'/favorites': 'Favorites',
	'/ranking': 'Sales Drivers (Scan)',
	'/shareMulti': 'Share Report (Scan)',
	'/trended': 'Trended Weekly Comparison (Scan)',
	'/kpi': 'Category Product Comparison (Scan)',
	'/productPerformance': 'Product Performance (Scan)',
	'/assortment': 'Item Assortment Summary (Scan)',
	'/geographicDeepDive': 'Geographic Deep Dive (Scan)',
	'/priceBeats': 'Walmart Price Beats',
	'/panel': 'Shopper Overview (Panel)',
	'/leakage': 'Leakage Tree (Panel)',
	'/cdt': 'Consumer Decision Tree',

	// concierge
	'/concierge/conciergeHome': 'Concierge',
	'/quadrant': 'Performance Quadrant (Scan)',
	'/concierge/enhancementSummary': 'User Notifications',
	'/concierge/training-library': 'Training Library',
	'/concierge/createUsers': 'Entitlements',
	'/concierge/requestStatus': 'Users & Entitlements Summary',
	'/concierge/usageStats': 'Usage Stats',
	'/concierge/editEntitlements': 'Enable Content to the Existing Users',
	'/concierge/pushNotifications': 'Push Notifications to the App',
	// training
	'/training': 'Training Library'
};
