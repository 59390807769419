import { Injectable } from '@angular/core';
import { BASE_URL, OVT_UI_URL_HOME_PAGE_URLS } from '../../../environments/env.config.constants';
import { KEY } from '../../constants/data.constant';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { NlsnDataStoreService } from '../../core/service/store/nlsn-data-store.service';
import { Observable } from 'rxjs';
import { NlsnGlobalsContextExtract } from 'src/app/core/service/auth/nlsn-globals.service';

@Injectable({
	providedIn: 'root'
})
export class FavoriteService {
	private storageKey = 'favorites';
	favorites: any = [];

	constructor(
		private http: HttpClient,
		private nlsnDataStoreService: NlsnDataStoreService,
		public nlsnGlobalsContextExtract: NlsnGlobalsContextExtract
	) {
		this.favorites = JSON.parse(localStorage.getItem(this.storageKey)) || [];
	}

	getFavorites(): any {
		let pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			OVT_UI_URL_HOME_PAGE_URLS.GET_FAVORITES;
		let userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		let headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.get<any>(pingURL, headers).pipe(
			map(res => {
				return res;
			})
		);
	}
	extractQuestionIds(data: any): any {
		data.forEach(item => {
			this.favorites.push(item.tblQuestion.id);
		});
	}

	isFavorite(id: number): boolean {
		if (this.favorites.includes(id)) {
			return true;
		} else {
			return false;
		}
	}

	addFavorite(id: number): Observable<any> {
		this.favorites.push(id);
		let pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			OVT_UI_URL_HOME_PAGE_URLS.FAVORITES_ADD +
			id;
		let userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		let headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.post<any>(pingURL, null, headers);
	}

	removeFavorite(id: number): Observable<any> {
		this.favorites = this.favorites.filter(favoriteId => favoriteId !== id);
		let pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			OVT_UI_URL_HOME_PAGE_URLS.FAVORITES_REMOVE +
			id;
		let userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		let headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};

		return this.http.delete<any>(pingURL, headers);
	}
}
