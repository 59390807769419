import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Feedback } from '../../model/topbar.model';
import { NlsnDataStoreService } from '../store/nlsn-data-store.service';
import {
	BASE_URL,
	OVT_UI_URL_HOME_PAGE_URLS,
	REDIRECTING_ANSWERS_PORTAL_URL_WHEN_LOGOUT
} from '../../../../environments/env.config.constants';
import { map } from 'rxjs/operators';
import { KEY } from '../../../constants/data.constant';
import { NlsnGlobalsContextExtract } from '../auth/nlsn-globals.service';
import { AuthService } from '../auth.service';

@Injectable({
	providedIn: 'root'
})
export class TopbarService {
	constructor(
		private http: HttpClient,
		private nlsnDataStoreService: NlsnDataStoreService,
		private nlsnGlobalsContextExtract: NlsnGlobalsContextExtract
	) {}

	getAppMenuItems(): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] + OVT_UI_URL_HOME_PAGE_URLS.APPS;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const options = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};

		return this.http.get<any>(pingURL, options).pipe(
			//return this.http.get<any>(pingURL).pipe(
			map(result => {
				this.nlsnDataStoreService.putItem(KEY.APPS, result?.result);
				this.nlsnDataStoreService.isAppLoaded$?.next(true);
				return result;
			})
		);
	}

	//FEEDBACK
	getFeedbackReportDropdownOptions() {
		let appsData = this.nlsnDataStoreService.getItem(KEY.QUESTIONS);
		return appsData;
	}

	// submitFeedback(feedback: Feedback): Observable<any> {
	// 	const pingURL =
	// 		BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
	// 		OVT_UI_URL_HOME_PAGE_URLS.COMMENT_SERVICE;
	// 	const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
	// 	const options = {
	// 		headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
	// 	};
	// 	return this.http.post<any>(pingURL, options).pipe(
	// 		map(result => {
	// 			return result.data;
	// 		})
	// 	);
	// }

	//NOTIFICATION
	readAllNotifications(): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			OVT_UI_URL_HOME_PAGE_URLS.NOTIFICATIONS;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const options = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.get<any>(pingURL, options).pipe(
			map(result => {
				return result;
			})
		);
	}

	updateNotificationAsRead(notificationId): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			OVT_UI_URL_HOME_PAGE_URLS.VIEWED_NOTIFICATION_MESSAGES;

		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);

		const options = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};

		return this.http.post(pingURL + notificationId, notificationId, options);
	}

	logout() {
		this.nlsnDataStoreService.deleteAll();
		localStorage.removeItem('isLoggedIn');
		window.location.href =
			REDIRECTING_ANSWERS_PORTAL_URL_WHEN_LOGOUT[
				this.nlsnGlobalsContextExtract.extractEnvironment()
			];
		//this.authService.logout();
	}
}
