<div class="overlay-bg-ppt" *ngIf="enableLoader">
	<span class="spinner-large">
		<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
	</span>
</div>
<div class="container" style="width: auto; padding: 0px 30px 0px 30px">
	<div class="row">
		<div class="col-md-12">
			<div id="loadData" *ngIf="!chartData && chartData.length === 0">
				<div id="overlay" class="ovt-general-overlay"></div>
				<div class="ovt-no-data-message">
					<span class="glyphicon glyphicon-exclamation-sign"></span>
					No Data Available
					<span
						id="closeId"
						class="ovt-no-data-message-close"
						ng-click="shareCtrl.showOverlay = false"
						>&times;</span
					>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-6">
			<div class="dropdown dropdown-ovt" style="width: 30%">
				<a
					id="baseFactSelectBtn"
					type="button"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
					(click)="showUnitMenu()"
				>
					<div class="row">
						<span class="text col-lg-10">{{ measureSelected.value }}</span>
						<span class="arrow col-lg-2"
							><niq-icon state="primary" size="small" iconName="chevron-down"></niq-icon
						></span>
					</div>
				</a>
				<ul class="dropdown-menu dropdown-list" [hidden]="!showUnitDropdown">
					<li *ngFor="let measure of measures">
						<a (click)="updateMeasureSelection(measure)">{{ measure.value }}</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="col-md-6">
			<div class="dropdown dropdown-ovt" style="float: right; width: 50%">
				<a
					id="baseFactSelectBtn"
					type="button"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
					(click)="showFactMenu()"
				>
					<div class="row">
						<span class="text col-lg-10">{{ factSelected.value }}</span>
						<span class="arrow col-lg-2"
							><niq-icon state="primary" size="small" iconName="chevron-down"></niq-icon
						></span>
					</div>
				</a>
				<ul class="dropdown-menu dropdown-list" [hidden]="!showFactDropdown">
					<li *ngFor="let fact of factLocalPromptValues[measureSelected.key]">
						<a (click)="updateFactSelection(fact, 'UI')">{{ fact.value }}</a>
					</li>
				</ul>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12">
			<div #containerElement class="shared-report-chart-dashboard" ng-if="shareCtrl.enableBarChart">
				<div class="row">
					<span id="wovtBarDotChartLeft" style="float: left"></span>
					<span id="wovtBarDotChartParentCenter"></span>
					<div id="wovtBarDotChartCenterScrollDiv" style="display: inline-block; overflow-x: auto">
						<div id="wovtBarDotChartOtherCenter"></div>
						<div id="wovtBarDotChartOtherCenterLabels" [hidden]="!showChildLabelText">
							<div
								id="wmovtxAxisLabel"
								class="wmovtxAxisLabelText"
								*ngFor="let product of barDataList"
								[style.width.px]="labelWidth"
							>
								{{ product.productName }}
							</div>
						</div>
					</div>

					<span id="wovtBarDotChartRight" style="float: right"></span>
				</div>
				<div class="row">
					<span><span id="wovtBarDotChartLegend"></span></span>
				</div>
			</div>
		</div>
	</div>
</div>
