import { DISTRIBUTION, DOLLARS, EQ, OTHER, UNITS } from 'src/app/constants/report.constant';

const WM_DOLL_SHRPT_CHG = 'WM $ Share Pt Chg (BPS)';
const WM_DOLL_VAL_SHRPT_CHG = 'WM $ Value of Share Pt Chg';
const TM_SALES_PER_CHG = 'TM Sales % Chg';
const WM_DOLL_SHR = 'WM $ Share';
const WM_DOLL_SALES = 'WM $ Sales';
const WM_DOLL_SALES_PER_CHG = 'WM $ Sales % Chg';
const WM_DOLL_SALES_ACTUAL_CHG = 'WM $ Sales Actual Chg';
const WM_TDP_SHR_PER = 'WM TDP Share (%)';
const WM_TDP_SHR_PT_CHG = 'WM TDP Share Pt Chg (BPS)';
const WM_PER_PROMO_DOLL = 'WM % Promo $';
const WM_PER_PROMO_DOLL_CHG = 'WM % Promo $ Chg (BPS)';
const WM_AVG_UNIT_PRICE = 'WM Avg Unit Price';
const WM_AVG_UNIT_PRICE_DOLL_CHG = 'WM Avg Unit Price % Chg';
const WM_DOLL_TDP_PER_CHG = 'WM $/TDP % Chg';
const WM_UNITS_TDP_PER_CHG = 'WM Units/TDP % Chg';
const RM_DOLL_SALES = 'RM $ Sales';
const RM_DOLL_SALES_PER_CHG = 'RM $ Sales % Chg';
const RM_DOLL_SALES_ACTUAL_CHG = 'RM $ Sales Actual Chg';
const RM_DOLL_TDP_CHG = 'RM $/TDP % Chg';
const RM_PER_PROMO_DOLL = 'RM % Promo $';
const RM_PER_PROMO_DOLL_CHG = 'RM % Promo $ Chg (BPS)';
const RM_TDP_SHR_PER = 'RM TDP Share (%)';
const RM_TDP_SHR_PT_CHG = 'RM TDP Share Pt Chg (BPS)';
const RM_AVG_UNIT_PRICE = 'RM Avg Unit Price';
const RM_AVG_UNIT_PRICE_DOLL_CHG = 'RM Avg Unit Price % Chg';
const TM_SALES_ACTUAL_CHG = 'TM Sales Actual Chg';
const ZIP_ROUNDED_NUMB = "zip:'roundednumber'";
const ZIP_DOLLAR = "zip:'dollar'";
const ZIP_PERCENT = "zip:'perc'";

export const FACT_SPECIFICATION = [
	{
		valuebase: OTHER,
		gkey: 'Products',
		name: 'Products',
		headerName: 'Products',
		field: 'level_MANUFATURER',
		suppressColumnsToolPanel: true,
		cellTooltip: 'toolTip',
		width: 200,
		pinnedLeft: true,
		enablePinning: true,
		cellClass: 'gridProductName',
		isSelected: true,
		isPinned: false,
		pinned: 'left'
	},
	{
		valuebase: DOLLARS,
		gkey: 'WM Share Pt Chg (BPS)',
		name: WM_DOLL_SHRPT_CHG,
		field: 'sharePtChange',
		type: 'number',
		headerName: WM_DOLL_SHRPT_CHG,
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'gridFromNumber',
		isSelected: true,
		isPinned: true
	},
	{
		valuebase: DOLLARS,
		gkey: 'WM Value of Share Pt Chg',
		name: WM_DOLL_VAL_SHRPT_CHG,
		field: 'valueOfSharePtCharge',
		type: 'number',
		headerName: WM_DOLL_VAL_SHRPT_CHG,
		width: 200,
		cellFilter: ZIP_DOLLAR,
		cellClass: 'gridFromNumber',
		isSelected: true,
		isPinned: true
		//sort: 'desc'
	},
	{
		valuebase: UNITS,
		gkey: 'WM Share Pt Chg (BPS)',
		name: 'WM Units Share Pt Chg (BPS)',
		field: 'sharePtChange',
		type: 'number',
		headerName: 'WM Units Share Pt Chg (BPS)',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'gridFromNumber',
		isSelected: true,
		isPinned: true
	},
	{
		valuebase: EQ,
		gkey: 'WM Share Pt Chg (BPS)',
		name: 'WM EQ Share Pt Chg (BPS)',
		field: 'sharePtChange',
		type: 'number',
		headerName: 'WM EQ Share Pt Chg (BPS)',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'gridFromNumber',
		isSelected: true,
		isPinned: true
	},
	{
		valuebase: UNITS,
		gkey: 'WM Value of Share Pt Chg',
		name: 'WM Units Value of Share Pt Chg',
		field: 'valueOfSharePtCharge',
		type: 'number',
		headerName: 'WM Units Value of Share Pt Chg',
		width: 200,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'gridFromNumber',
		isSelected: true,
		isPinned: true
		//sort: 'desc'
	},
	{
		valuebase: EQ,
		gkey: 'WM Value of Share Pt Chg',
		name: 'WM EQ Value of Share Pt Chg',
		field: 'valueOfSharePtCharge',
		type: 'number',
		headerName: 'WM EQ Value of Share Pt Chg',
		width: 200,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'gridFromNumber',
		isSelected: true,
		isPinned: true
		//sort: 'desc'
	},
	{
		valuebase: DOLLARS,
		gkey: TM_SALES_PER_CHG,
		name: 'TM $ Sales % Chg',
		field: 'totalMarketSalesPerChange',
		headerName: TM_SALES_PER_CHG,
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'gridFromNumber',
		isSelected: true,
		isPinned: true
	},
	{
		valuebase: UNITS,
		gkey: TM_SALES_PER_CHG,
		name: 'TM Units % Chg',
		field: 'totalMarketSalesPerChange',
		headerName: 'TM Units % Chg',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'gridFromNumber',
		isSelected: true,
		isPinned: true
	},
	{
		valuebase: EQ,
		gkey: TM_SALES_PER_CHG,
		name: 'TM EQ % Chg',
		field: 'totalMarketSalesPerChange',
		headerName: 'TM EQ % Chg',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'gridFromNumber',
		isSelected: true,
		isPinned: true
	},
	{
		valuebase: DOLLARS,
		gkey: 'WM Share',
		name: WM_DOLL_SHR,
		headerName: WM_DOLL_SHR,
		field: 'marketShare',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DOLLARS,
		gkey: 'WM Sales',
		name: WM_DOLL_SALES,
		headerName: WM_DOLL_SALES,
		field: 'walmartSales',
		type: 'number',
		width: 170,
		cellFilter: ZIP_DOLLAR,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DOLLARS,
		gkey: 'WM Sales % Chg',
		name: WM_DOLL_SALES_PER_CHG,
		headerName: WM_DOLL_SALES_PER_CHG,
		field: 'walmartSalesChange',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DOLLARS,
		gkey: 'WM Sales Actual Chg',
		name: WM_DOLL_SALES_ACTUAL_CHG,
		headerName: WM_DOLL_SALES_ACTUAL_CHG,
		field: 'walmartSalesActualChange',
		type: 'number',
		width: 170,
		cellClass: 'gridFromNumber',
		cellFilter: ZIP_DOLLAR,
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DISTRIBUTION,
		// gkey: 'WM Sales/TDP',
		gkey: 'WM $/TDP',
		name: 'WM $/TDP',
		field: 'walmartDollarTDP',
		headerName: 'WM $/TDP',
		type: 'number',
		width: 200,
		cellFilter: ZIP_DOLLAR,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DISTRIBUTION,
		// gkey: 'WM Sales/TDP % Chg',
		gkey: WM_DOLL_TDP_PER_CHG,
		name: WM_DOLL_TDP_PER_CHG,
		field: 'walmartDollarTDPChange',
		headerName: WM_DOLL_TDP_PER_CHG,
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},

	{
		valuebase: DOLLARS,
		gkey: 'WM % Promo Sales',
		name: WM_PER_PROMO_DOLL,
		field: 'walmartPromo',
		headerName: WM_PER_PROMO_DOLL,
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DOLLARS,
		gkey: 'WM % Promo Sales Chg (BPS)',
		name: WM_PER_PROMO_DOLL_CHG,
		field: 'walmartPromoPointChange',
		headerName: WM_PER_PROMO_DOLL_CHG,
		type: 'number',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: UNITS,
		gkey: 'WM Share',
		name: 'WM Units Share',
		headerName: 'WM Units Share',
		field: 'marketShare',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: EQ,
		gkey: 'WM Share',
		name: 'WM EQ Share',
		headerName: 'WM EQ Share',
		field: 'marketShare',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: UNITS,
		gkey: 'WM Sales',
		name: 'WM Units',
		headerName: 'WM Units',
		field: 'walmartSales',
		type: 'number',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: EQ,
		gkey: 'WM Sales',
		name: 'WM EQ',
		headerName: 'WM EQ',
		field: 'walmartSales',
		type: 'number',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: UNITS,
		gkey: 'WM Sales % Chg',
		name: 'WM Units % Chg',
		headerName: 'WM Units % Chg',
		field: 'walmartSalesChange',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: EQ,
		gkey: 'WM Sales % Chg',
		name: 'WM EQ % Chg',
		headerName: 'WM EQ % Chg',
		field: 'walmartSalesChange',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: UNITS,
		gkey: 'WM Sales Actual Chg',
		name: 'WM Units Actual Chg',
		headerName: 'WM Units Actual Chg',
		field: 'walmartSalesActualChange',
		type: 'number',
		width: 170,
		cellClass: 'gridFromNumber',
		cellFilter: ZIP_ROUNDED_NUMB,
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: EQ,
		gkey: 'WM Sales Actual Chg',
		name: 'WM EQ Actual Chg',
		headerName: 'WM EQ Actual Chg',
		field: 'walmartSalesActualChange',
		type: 'number',
		width: 170,
		cellClass: 'gridFromNumber',
		cellFilter: ZIP_ROUNDED_NUMB,
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DISTRIBUTION,
		// gkey: 'WM Sales/TDP',
		gkey: 'WM Units/TDP',
		name: 'WM Units/TDP',
		field: 'walmartUnitTDP',
		headerName: 'WM Units/TDP',
		type: 'number',
		width: 200,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DISTRIBUTION,
		// gkey: 'WM Sales/TDP',
		gkey: 'WM EQ/TDP',
		name: 'WM EQ/TDP',
		field: 'walmartEQTDP',
		headerName: 'WM EQ/TDP',
		type: 'number',
		width: 200,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DISTRIBUTION,
		// gkey: 'WM Sales/TDP % Chg',
		gkey: 'WM Units/TDP % Chg',
		name: 'WM Units/TDP % Chg',
		field: 'walmartUnitTDPChange',
		headerName: 'WM Units/TDP % Chg',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DISTRIBUTION,
		gkey: 'WM Sales/TDP % Chg',
		name: 'WM EQ/TDP % Chg',
		field: 'walmartEQTDPChange',
		headerName: 'WM EQ/TDP % Chg',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},

	{
		valuebase: EQ,
		gkey: 'WM % Promo Sales',
		name: 'WM % Promo EQ',
		field: 'walmartPromo',
		headerName: 'WM % Promo EQ',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: UNITS,
		gkey: 'WM % Promo Sales',
		name: 'WM % Promo Units',
		field: 'walmartPromo',
		headerName: 'WM % Promo Units',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: UNITS,
		gkey: 'WM % Promo Sales Chg (BPS)',
		name: 'WM % Promo Units Chg (BPS)',
		field: 'walmartPromoPointChange',
		headerName: 'WM % Promo Units Chg (BPS)',
		type: 'number',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: EQ,
		gkey: 'WM % Promo Sales Chg (BPS)',
		name: 'WM % Promo EQ Chg (BPS)',
		field: 'walmartPromoPointChange',
		headerName: 'WM % Promo EQ Chg (BPS)',
		type: 'number',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DISTRIBUTION,
		gkey: WM_TDP_SHR_PER,
		name: WM_TDP_SHR_PER,
		field: 'walmartTDPShare',
		headerName: WM_TDP_SHR_PER,
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DISTRIBUTION,
		gkey: WM_TDP_SHR_PT_CHG,
		name: WM_TDP_SHR_PT_CHG,
		field: 'walmartTDPSharePointChange',
		headerName: WM_TDP_SHR_PT_CHG,
		type: 'number',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: OTHER,
		gkey: WM_AVG_UNIT_PRICE,
		name: WM_AVG_UNIT_PRICE,
		field: 'walmartAverageUnitPrice',
		headerName: WM_AVG_UNIT_PRICE,
		type: 'number',
		width: 170,
		cellFilter: "zip:'dollar2decimal'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: OTHER,
		gkey: 'WM Avg EQ Price',
		name: 'WM Avg EQ Price',
		field: 'walmartAverageEQUnitPrice',
		headerName: 'WM Avg EQ Price',
		type: 'number',
		width: 170,
		cellFilter: "zip:'dollar2decimal'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: OTHER,
		gkey: WM_AVG_UNIT_PRICE_DOLL_CHG,
		name: WM_AVG_UNIT_PRICE_DOLL_CHG,
		field: 'walmartUnitPriceChange',
		headerName: WM_AVG_UNIT_PRICE_DOLL_CHG,
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: OTHER,
		gkey: 'WM Avg EQ Price % Chg',
		name: 'WM Avg EQ Price % Chg',
		field: 'walmartEQUnitPriceChange',
		headerName: 'WM Avg EQ Price % Chg',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DOLLARS,
		gkey: 'RM Sales',
		name: RM_DOLL_SALES,
		headerName: RM_DOLL_SALES,
		field: 'remainingMarketSales',
		type: 'number',
		width: 170,
		cellFilter: ZIP_DOLLAR,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DOLLARS,
		gkey: 'RM Sales % Chg',
		name: RM_DOLL_SALES_PER_CHG,
		headerName: RM_DOLL_SALES_PER_CHG,
		field: 'remainingMarketSalesChange',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DOLLARS,
		gkey: 'RM Sales Actual Chg',
		name: RM_DOLL_SALES_ACTUAL_CHG,
		headerName: RM_DOLL_SALES_ACTUAL_CHG,
		field: 'remainingMarketSalesActualChange',
		type: 'number',
		width: 170,
		cellClass: 'gridFromNumber',
		cellFilter: ZIP_DOLLAR,
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DISTRIBUTION,
		// gkey: 'RM Sales/TDP',
		gkey: 'RM $/TDP',
		name: 'RM $/TDP',
		field: 'remainingMarketDollarTDP',
		headerName: 'RM $/TDP',
		type: 'number',
		width: 170,
		cellFilter: ZIP_DOLLAR,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DISTRIBUTION,
		// gkey: 'RM Sales/TDP % Chg',
		gkey: 'RM $/TDP % Chg',
		name: RM_DOLL_TDP_CHG,
		field: 'remainingMarketDollarTDPChange',
		headerName: RM_DOLL_TDP_CHG,
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DOLLARS,
		gkey: 'RM % Promo Sales',
		name: RM_PER_PROMO_DOLL,
		field: 'remainingMarketPromo',
		headerName: RM_PER_PROMO_DOLL,
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DOLLARS,
		gkey: 'RM % Promo Sales Chg (BPS)',
		name: RM_PER_PROMO_DOLL_CHG,
		field: 'remainingMarketPromoPointChange',
		headerName: RM_PER_PROMO_DOLL_CHG,
		type: 'number',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: UNITS,
		gkey: 'RM Sales',
		name: 'RM Units',
		headerName: 'RM Units',
		field: 'remainingMarketSales',
		type: 'number',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: EQ,
		gkey: 'RM Sales',
		name: 'RM EQ',
		headerName: 'RM EQ',
		field: 'remainingMarketSales',
		type: 'number',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: UNITS,
		gkey: 'RM Sales % Chg',
		name: 'RM Units % Chg',
		headerName: 'RM Units % Chg',
		field: 'remainingMarketSalesChange',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: EQ,
		gkey: 'RM Sales % Chg',
		name: 'RM EQ % Chg',
		headerName: 'RM EQ % Chg',
		field: 'remainingMarketSalesChange',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: UNITS,
		gkey: 'RM Sales Actual Chg',
		name: 'RM Units Actual Chg',
		headerName: 'RM Units Actual Chg',
		field: 'remainingMarketSalesActualChange',
		type: 'number',
		width: 170,
		cellClass: 'gridFromNumber',
		cellFilter: ZIP_ROUNDED_NUMB,
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: EQ,
		gkey: 'RM Sales Actual Chg',
		name: 'RM EQ Actual Chg',
		headerName: 'RM EQ Actual Chg',
		field: 'remainingMarketSalesActualChange',
		type: 'number',
		width: 170,
		cellClass: 'gridFromNumber',
		cellFilter: ZIP_ROUNDED_NUMB,
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DISTRIBUTION,
		// gkey: 'RM Sales/TDP',
		gkey: 'RM Units/TDP',
		name: 'RM Units/TDP',
		field: 'remainingMarketUnitTDP',
		headerName: 'RM Units/TDP',
		type: 'number',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DISTRIBUTION,
		gkey: 'RM Sales/TDP',
		name: 'RM EQ/TDP',
		field: 'remainingMarketEQTDP',
		headerName: 'RM EQ/TDP',
		type: 'number',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DISTRIBUTION,
		// gkey: 'RM Sales/TDP % Chg',
		gkey: 'RM Units/TDP % Chg',
		name: 'RM Units/TDP % Chg',
		field: 'remainingMarketUnitTDPChange',
		headerName: 'RM Units/TDP % Chg',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DISTRIBUTION,
		gkey: 'RM Sales/TDP % Chg',
		name: 'RM EQ/TDP % Chg',
		field: 'remainingMarketEQTDPChange',
		headerName: 'RM EQ/TDP % Chg',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: UNITS,
		gkey: 'RM % Promo Sales',
		name: 'RM % Promo Units',
		field: 'remainingMarketPromo',
		headerName: 'RM % Promo Units',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: EQ,
		gkey: 'RM % Promo Sales',
		name: 'RM % Promo EQ',
		field: 'remainingMarketPromo',
		headerName: 'RM % Promo EQ',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: UNITS,
		gkey: 'RM % Promo Sales Chg (BPS)',
		name: 'RM % Promo Units Chg (BPS)',
		field: 'remainingMarketPromoPointChange',
		headerName: 'RM % Promo Units Chg (BPS)',
		type: 'number',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: EQ,
		gkey: 'RM % Promo Sales Chg (BPS)',
		name: 'RM % Promo EQ Chg (BPS)',
		field: 'remainingMarketPromoPointChange',
		headerName: 'RM % Promo EQ Chg (BPS)',
		type: 'number',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DISTRIBUTION,
		gkey: RM_TDP_SHR_PER,
		name: RM_TDP_SHR_PER,
		field: 'remainingMarketTDPShare',
		headerName: RM_TDP_SHR_PER,
		type: 'number',
		width: 200,
		cellFilter: ZIP_PERCENT,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DISTRIBUTION,
		gkey: 'RM EQ TDP Share Pt Chg',
		name: RM_TDP_SHR_PT_CHG,
		field: 'remainingMarketTDPSharePointChange',
		headerName: RM_TDP_SHR_PT_CHG,
		type: 'number',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: OTHER,
		gkey: RM_AVG_UNIT_PRICE,
		name: RM_AVG_UNIT_PRICE,
		field: 'remainingMarketAverageUnitPrice',
		headerName: RM_AVG_UNIT_PRICE,
		type: 'number',
		width: 170,
		cellFilter: "zip:'dollar2decimal'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: OTHER,
		gkey: 'RM Avg EQ Price',
		name: 'RM Avg EQ Price',
		field: 'remainingMarketAverageEQUnitPrice',
		headerName: 'RM Avg EQ Price',
		type: 'number',
		width: 170,
		cellFilter: "zip:'dollar2decimal'",
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: OTHER,
		gkey: RM_AVG_UNIT_PRICE_DOLL_CHG,
		name: RM_AVG_UNIT_PRICE_DOLL_CHG,
		field: 'remainingMarketUnitPriceChange',
		headerName: RM_AVG_UNIT_PRICE_DOLL_CHG,
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: OTHER,
		gkey: 'RM Avg EQ Price % Chg',
		name: 'RM Avg EQ Price % Chg',
		field: 'remainingMarketEQUnitPriceChange',
		headerName: 'RM Avg EQ Price % Chg',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENT,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DOLLARS,
		gkey: 'TM Sales',
		name: 'TM $ Sales',
		field: 'totalMarketSales',
		headerName: 'TM Sales',
		type: 'number',
		width: 170,
		cellFilter: ZIP_DOLLAR,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: DOLLARS,
		gkey: TM_SALES_ACTUAL_CHG,
		name: 'TM $ Sales Actual Chg',
		field: 'totalMarketSalesActualChange',
		headerName: TM_SALES_ACTUAL_CHG,
		type: 'number',
		width: 170,
		cellFilter: ZIP_DOLLAR,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: UNITS,
		gkey: 'TM Sales',
		name: 'TM Units',
		field: 'totalMarketSales',
		headerName: 'TM Units',
		type: 'number',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: EQ,
		gkey: 'TM Sales',
		name: 'TM EQ',
		field: 'totalMarketSales',
		headerName: 'TM EQ',
		type: 'number',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'special',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: UNITS,
		gkey: TM_SALES_ACTUAL_CHG,
		name: 'TM Units Actual Chg',
		field: 'totalMarketSalesActualChange',
		headerName: 'TM Units Actual Chg',
		type: 'number',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	},
	{
		valuebase: EQ,
		gkey: TM_SALES_ACTUAL_CHG,
		name: 'TM EQ Actual Chg',
		field: 'totalMarketSalesActualChange',
		headerName: 'TM EQ Actual Chg',
		type: 'number',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMB,
		cellClass: 'gridFromNumber',
		isSelected: false,
		isPinned: false
	}
];
