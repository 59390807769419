let query = true;
export const REST_API_CONSTANTS = {
	SHARE_REPORT_PRODUCT: {
		URL: '/jbp/sharereport/v1/product',
		METHOD: 'POST',
		IS_OAUTH: true,
		OTHERPARM: false
	},
	SHARE_REPORT_MARKET: {
		URL: '/jbp/sharereport/v1/market',
		METHOD: 'POST',
		IS_OAUTH: true,
		OTHERPARM: false
	},
	// 	/**************************Start of Product Search**************************/
	AZ_SEARCH_POC_SECOND_API: {
		URL: '/jbp/selector/v1/kpidashboard/' + (query ? 'query/' : '') + 'groupsearch/',
		METHOD: 'GET',
		IS_OAUTH: false,
		OTHERPARM: true
	},
	AZ_SEARCH_POC_GET_SAVED_SELECTION: {
		URL: '/jbp/common/v1/getselectors/',
		METHOD: 'GET',
		IS_OAUTH: false,
		OTHERPARM: false
	},
	AZ_SEARCH_POC_SAVE_USER_SELECTION: {
		URL: '/jbp/common/v1/saveselectors/',
		METHOD: 'POST',
		IS_OAUTH: false,
		OTHERPARM: true
	},
	DASHBOARD_SAVE_USER_SELECTION: {
		URL: '/jbp/common/v1/saveDefaultselectors/',
		METHOD: 'POST',
		IS_OAUTH: false,
		OTHERPARM: true
	},
	AZ_SEARCH_GET_PARENT_COMPANY: {
		URL: '/jbp/selector/v1/kpidashboard/getparentcompany',
		METHOD: 'GET',
		IS_OAUTH: false,
		OTHERPARM: true
	},
	AZ_SEARCH_GET_CHILD_LEVELS: {
		URL: '/jbp/common/v1/getchildlevels/',
		METHOD: 'GET',
		IS_OAUTH: false,
		OTHERPARM: false
	},
	QUADRANT_GET_CHILD_LEVELS: {
		URL: '/jbp/common/v1/getchildlevels/',
		METHOD: 'GET',
		IS_OAUTH: false,
		OTHERPARM: true
	},
	AZ_SEARCH_INDIVIDUAL_SEARCH: {
		URL: '/jbp/selector/v1/kpidashboard/' + (query ? 'query/' : '') + 'individualsearch/',
		METHOD: 'POST',
		IS_OAUTH: false,
		OTHERPARM: false
	},
	AZ_SEARCH_GROUP_SEARCH: {
		URL: '/jbp/selector/v1/kpidashboard/' + (query ? 'query/' : '') + 'groupsearch/',
		METHOD: 'POST',
		IS_OAUTH: false,
		OTHERPARM: false
	},
	AZ_SEARCH_MULTI_SEARCH: {
		URL: '/jbp/selector/v1/kpidashboard/' + (query ? 'query/' : '') + 'multisearch/',
		METHOD: 'POST',
		IS_OAUTH: false,
		OTHERPARM: false
	},
	AZ_SEARCH_SUGGESTIONS: {
		URL: '/jbp/selector/v1/selectors/' + (query ? 'query/' : '') + 'suggestions/',
		METHOD: 'POST',
		IS_OAUTH: false,
		OTHERPARM: false
	},
	FIND_FRESH_PRODUCTS: {
		URL: '/jbp/common//v1/findFreshProducts',
		METHOD: 'POST',
		IS_OAUTH: false,
		OTHERPARM: false
	},
	/**************************End of Product Search**************************/
	FILTER: {
		FETCH_PERIOD: {
			URL: '/jbp/common/v1/periods/info/rms'
		},
		FETCH_MARKET: {
			URL: '/jbp/common/v1/market/name/rms/all'
		},
		FETCH_PRODUCT: {
			URL: '/jbp/selector/v1/kpidashboard/query/multisearch'
		},
		FETCH_SELECTORS: {
			URL: '/jbp/common/v1/getselectors'
		},
		FETCH_BY_LEVEL: {
			URL: '/jbp/common/v1/getchildlevels'
		},
		SAVE_DEFAULT: {
			URL: '/jbp/common/v1/saveDefaultselectors'
		},
		PERIOD_END_DATE: '/jbp/common/v1/getperiodenddate/'
	},
	REPORT: {
		PERFORMANCE_QUADRANT_GRID_DATA: {
			URL: '/jbp/performanceQuadrant/v1/performance'
		},
		CONSUMER_DECISION_TREE: {
			TREE_DATA: {
				URL: '/jbp/cdtconsumer/v1/report/tree'
			},
			TREE_TABLE_DATA: {
				URL: '/jbp/cdtconsumer/v1/report/table'
			},
			UPC_TABLE_DATA: {
				URL: '/jbp/cdtconsumer/v1/report/UPC/product'
			}
		},
		TRENDED_WEEKLY: {
			REPORT_URL: '/jbp/trendedweekly/v1/report',
			PERIOD_END_DATE: '/jbp/common/v1/getperiodenddate/2'
		}
	},
	PERIODS_END_DATE: {
		URL: '/jbp/common/v1/getperiodenddate/',
		METHOD: 'GET',
		IS_OAUTH: false
	},
	PERIODS_END_DATE_POST: {
		URL: '/jbp/common/v1/getperiodenddate/',
		METHOD: 'POST',
		IS_OAUTH: false
	},
	GET_ALL_RMS_MARKET_NAMES: {
		URL: '/jbp/common/v1/market/name/rms/all',
		METHOD: 'GET',
		IS_OAUTH: false
	},
	GET_ALL_PANEL_MARKET_NAMES: {
		URL: '/jbp/common/v1/market/name/panel/all',
		METHOD: 'GET',
		IS_OAUTH: false
	},
	GET_ALL_RMS_PERIODS: {
		URL: '/jbp/common/v1/periods/info/rms',
		METHOD: 'GET',
		IS_OAUTH: false
	},
	GET_ALL_BEATS_PERIODS: {
		URL: '/jbp/common/v1/periods/info/beats',
		METHOD: 'GET',
		IS_OAUTH: false
	},
	GET_ALL_PANEL_PERIODS: {
		URL: '/jbp/common/v1/periods/info/panel',
		METHOD: 'GET',
		IS_OAUTH: false
	},
	PANEL_DASHBOARD_GET_ALL_MARKETS: {
		URL: '/jbp/contributiondrivers/caseMrktSummary/marketSelector',
		METHOD: 'GET',
		IS_OAUTH: false
	},
	EXCEL_CSV_REPORT: {
		URL: 'sbs/export/',
		METHOD: 'POST',
		IS_OAUTH: true,
		OTHERPARM: false
	}
};
