<div class="dropdown-menu p-3">
	<ul class="dropdown-list" aria-labelledby="periodSelectorBtn">
		<crf-ca-save-default
			[selectorType]="selectorType"
			[configId]="configId"
			(menuSelector)="getMenuResponse($event)"
			[disableMenuOptions]="disableMenuOptions"
		></crf-ca-save-default>
		<li class="p-2" *ngFor="let category of items; trackBy: trackByFn">
			<niq-checkbox
				[checked]="category.isSelected"
				size="small"
				(checkedChange)="checkedChange(category)"
				>{{ category.key | textChange }}</niq-checkbox
			>
		</li>
	</ul>
	<!--Footer-->
	<div class="button-container mt-3 d-flex justify-content-end align-items-center">
		<niq-button variant="ghost" size="medium" class="mr-2" (click)="cancel.emit()">
			Cancel
		</niq-button>
		<niq-button variant="primary" size="medium" (click)="applyHandler()"> Apply </niq-button>
	</div>
</div>
