<div class="container m-0 p-0">
	<div class="row">
		<div class="col-12">
			<div class="tab-container mt-3">
				<div class="tab-header" [hidden]="expandedQuadrantIndex !== -1">
					<niq-tab-line-list selected="Job-Aids" [(selected)]="selectedTab">
						<niq-tab-line value="product-quadrants" (click)="tabChange.emit('product-quadrants')"
							>Product Quadrants</niq-tab-line
						>
						<niq-tab-line value="cma-quadrants" (click)="tabChange.emit('cma-quadrants')"
							>CMA Quadrants</niq-tab-line
						>
					</niq-tab-line-list>
				</div>
				<div class="tab-body pb-0 pt-4 px-4">
					<div class="row">
						<div
							[class]="expandedQuadrantIndex == index ? 'col-lg-12' : 'col-lg-6'"
							*ngFor="let quadrant of quadrants; let index = index"
						>
							<div
								class="card mb-4"
								[hidden]="
									!(
										expandedQuadrantIndex === -1 ||
										(expandedQuadrantIndex !== -1 && expandedQuadrantIndex === index)
									)
								"
							>
								<div class="card-header d-flex align-items-center justify-content-between p-3">
									<h4 class="m-0 mr-3 text-center" style="width: 90%">
										{{ quadrant.title }} -
										<span style="font-size: small">{{ quadrant.titleDescription }}</span>
									</h4>
									<niq-icon
										size="small"
										[iconName]="
											expandedQuadrantIndex == index ? 'full-screen-closed' : 'full-screen-open'
										"
										(click)="toggleQuadrantExpansion(index)"
									></niq-icon>
								</div>
								<div class="card-body">
									<crf-ag-grid
										*ngIf="loadColumns"
										[customStyle]="'height:242px'"
										[id]="'PRODUCT-G1'"
										[columns]="quadrant.columns"
										[rowData]="quadrant.data"
										[flex]="quadrant.columns.length < 5"
										[reportName]="reportName"
									>
									</crf-ag-grid>
									<niq-spinner *ngIf="!quadrant.isDataFetched" size="medium"></niq-spinner>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
