import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'formatNumber',
	standalone: true
})
export class FormatNumberPipe implements PipeTransform {
	transform(value: any, isDolloar?: string): any {
		if (value === undefined || value === null) {
			return value;
		}

		let escapedString: any = 0;
		if (isNaN(value) && value !== 'NA') {
			value = parseFloat(value.replaceAll(',', ''));
		}

		if (value > 0) {
			escapedString = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			const lengthRoundOf = (value.toString().split('.')[1] || []).length;
			escapedString =
				isDolloar === 'dollar'
					? '$' + escapedString
					: isDolloar === 'perc'
					? lengthRoundOf === 0
						? escapedString + '.0'
						: escapedString
					: escapedString;
		} else if (value === 0) {
			const lengthRoundOf1 = (value.toString().split('.')[1] || []).length;
			escapedString =
				isDolloar === 'dollar'
					? '$' + escapedString.toFixed(lengthRoundOf1)
					: isDolloar === 'perc'
					? Number(escapedString).toFixed(lengthRoundOf1) + '%'
					: escapedString;
		} else {
			if (value !== 'NA' && value !== null) {
				escapedString = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
				escapedString = escapedString.replace(/^-/, '');
				escapedString = isDolloar === 'dollar' ? '- $' + escapedString : '-' + escapedString;
			} else {
				escapedString = 'NA';
			}
		}

		return escapedString;
	}
}
