<div class="shareReport">
	<div class="main-menu consumer-drive-header">
		<div>
			<div class="mr-4">
				<div class="reportHeader">
					<h4 class="m-0 reportName">{{ pageTitle }}</h4>
				</div>
				<br />
				<div class="mb-0 reportMenu">
					<div>
						<span
							promptClickOutside
							(clickOutside)="closeDropdown('fact')"
							*ngIf="loadHtml"
							class="report-dropdown dropdown dropdown-ovt facts-link"
							[ngClass]="{ 'field-disableOverlay': activeTab === 'Period' }"
						>
							<a
								id="baseFactChooserListBtn"
								type="button"
								(click)="toggleDropdown('fact')"
								[niqTooltip]="factTooltipContent"
								niqTooltipPosition="bottom"
								[niqTooltipColorTheme]="'dark'"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								[ngClass]="showDropdown.fact ? 'prompt-expanded' : ''"
							>
								<span class="text">{{ selectedFactsChecked.length }} Facts</span>
							</a>
							<ng-template #factTooltipContent>
								<div style="padding: 9px 14px; color: #000000">
									{{ pageHoverTexts.basefactTooltip }}
								</div>
							</ng-template>
							<crf-ca-fact-selector
								[hidden]="!showDropdown.fact"
								[factData]="factList"
								[configId]="qid"
								[report]="shareReport"
								(selectedFacts)="onFactSelect($event)"
								[disable]="disableSet"
								(onClose)="toggleDropdown('fact')"
								(onDefaultChange)="onMoreOptionClicked($event)"
							>
							</crf-ca-fact-selector>
						</span>
						for
						<span
							#product
							*ngIf="loadProductSelector"
							class="report-dropdown dropdown dropdown-ovt"
							promptClickOutside
							(clickOutside)="closeDropdown('product')"
						>
							<a
								id="productSelect"
								type="button"
								(click)="toggleDropdown('product')"
								[niqTooltip]="productTooltipContent"
								niqTooltipPosition="bottom"
								[niqTooltipColorTheme]="'dark'"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								[ngClass]="showDropdown.product ? 'prompt-expanded' : ''"
							>
								<span class="product-input-text"> {{ selectedProductToShow[0] }} </span>
								<span
									*ngIf="selectedProductToShow.length > 1"
									title="{{ selectedProductToShow.join(',') }}"
									class="badge item-count pl-2"
									>+{{ selectedProductToShow.length - 1 }}</span
								>
							</a>
							<ng-template #productTooltipContent>
								<div class="popover bottom panel-dashboard-popover" data-placement="left">
									<div class="popover-content">
										<p>{{ pageHoverTexts.productTooltip }}</p>
									</div>
								</div>
							</ng-template>
							<crf-ca-multilayer-dropdown
								[hidden]="!showDropdown.product"
								(onClose)="toggleDropdown('product')"
								[selectorType]="qid"
								[configId]="qid"
								(onProductApply)="updatereport($event)"
							>
							</crf-ca-multilayer-dropdown>
						</span>
						<!-- By Level Menu -->
						by
						<span
							promptClickOutside
							(clickOutside)="closeDropdown('byLevel')"
							class="report-dropdown dropdown dropdown-ovt"
							[ngClass]="{ 'field-disableOverlay': activeTab === 'CMA' }"
						>
							<a
								id="categorySelectBtn"
								type="button"
								(click)="toggleDropdown('byLevel')"
								[niqTooltip]="levelTooltipContent"
								niqTooltipPosition="bottom"
								[niqTooltipColorTheme]="'dark'"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								[ngClass]="showDropdown.byLevel ? 'prompt-expanded' : ''"
								><span>{{ shareCtrl.selectedLevelToShow }}</span>
							</a>
							<ng-template #levelTooltipContent>
								<div style="padding: 9px 14px; color: #000000">
									{{ pageHoverTexts.levelTooltip }}
								</div>
							</ng-template>
							<app-dropdown-menu
								[hidden]="!showDropdown.byLevel"
								[items]="categoryList"
								(selectedItem)="levelSelected($event)"
								[selectorType]="1"
								[configId]="qid"
								(cancel)="showDropdown.byLevel = false"
							>
							</app-dropdown-menu>
						</span>
						<!-- End -->
						in
						<span
							promptClickOutside
							(clickOutside)="closeDropdown('market')"
							*ngIf="loadHtml"
							class="report-dropdown dropdown dropdown-ovt"
							[ngClass]="{ 'field-disableOverlay': activeTab === 'CMA' }"
						>
							<a
								id="marketSelectorBtn"
								type="button"
								(click)="toggleDropdown('market')"
								[niqTooltip]="marketTooltipContent"
								niqTooltipPosition="bottom"
								[niqTooltipColorTheme]="'dark'"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								[ngClass]="showDropdown.market ? 'prompt-expanded' : ''"
								><span>{{ shareCtrl.selectedMarket[0] }}</span>
								<span
									*ngIf="shareCtrl.selectedMarket.length > 1"
									title="{{ shareCtrl.selectedMarket.join(',') }}"
									class="badge item-count pl-2"
									>+{{ shareCtrl.selectedMarket.length - 1 }}</span
								>
							</a>
							<ng-template #marketTooltipContent>
								<div style="padding: 9px 14px; color: #000000">
									{{ pageHoverTexts.marketTooltip }}
								</div>
							</ng-template>
							<app-multiselect-dropdown
								[hidden]="!showDropdown.market"
								[placeholder]="'Search markets'"
								[selectorType]="2"
								[configId]="qid"
								[marketData]="marketMenuItems"
								(onCancel)="toggleDropdown('market')"
								(onApply)="addselectedMarket($event)"
								[disable]="disableSet"
								[questionId]="questionId"
							>
							</app-multiselect-dropdown>
						</span>
						during
						<span
							class="col-lg-2 col-md-2 col-sm-2 col-xs-12"
							promptClickOutside
							(clickOutside)="closeDropdown('period')"
							class="report-dropdown dropdown dropdown-ovt"
							[ngClass]="{ 'field-disableOverlay': activeTab === 'Period' }"
						>
							<a
								id="periodSelectBtn"
								type="button"
								(click)="toggleDropdown('period')"
								[niqTooltip]="periodTooltipContent"
								niqTooltipPosition="bottom"
								[niqTooltipColorTheme]="'dark'"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								[ngClass]="showDropdown.period ? 'prompt-expanded' : ''"
							>
								<span *ngIf="periodCount !== 0">
									{{ activePeriodText }}
								</span>
								<span *ngIf="periodCount === 0" class="imp-categorym">
									&nbsp;&nbsp;<i class="fa fa-star imp-category" aria-hidden="true"></i
									><span style="font-size: 12px">Period Selection Required</span></span
								>
							</a>
							<ng-template #periodTooltipContent>
								<div style="padding: 9px 14px; color: #000000">
									{{ pageHoverTexts.periodTooltip }}
								</div>
							</ng-template>
							<app-dropdown-selection
								[hidden]="!showDropdown.period"
								[items]="periodList"
								(periodCheckChanged)="periodSelected($event)"
								[selectorType]="5"
								[configId]="qid"
								[disableSet]="disableSet"
								(onDefaultChange)="onMoreOptionClicked($event)"
								(cancel)="showDropdown.period = false"
							>
							</app-dropdown-selection>
						</span>
					</div>
				</div>
				<div style="padding-left: 5px !important; padding-right: 5px !important">
					<crf-ca-export-report
						(onExport)="reportDownload($event)"
						[excelIcon]="activeTab !== 'Period'"
						[pptIcon]="activeTab === 'Period'"
					></crf-ca-export-report>

					<crf-ca-save-default
						class="save-selection-all"
						[selectorType]="7"
						[configId]="qid"
						(menuSelector)="onMoreOptionClicked($event)"
					>
					</crf-ca-save-default>
				</div>
			</div>
		</div>
	</div>
	<div>
		<div class="modal-content"></div>

		<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 order-summary-data">
				<div class="disableOverlay"></div>
				<div class="deep-dive-container">
					<div class="dashboard-secondary-tabs-nav">
						<nav class="navbar">
							<ul class="nav navbar-nav">
								<li [ngClass]="activeTab === 'Product' ? 'active' : ''">
									<a data-target="#product" data-toggle="tab" (click)="showTab('product')"
										>Product Comparison Detail</a
									>
								</li>
								<li [ngClass]="activeTab === 'CMA' ? 'active' : ''">
									<a data-target="#cma" data-toggle="tab" (click)="showTab('cma')"
										>CMA Comparison Detail</a
									>
								</li>
								<li [ngClass]="activeTab === 'Period' ? 'active' : ''">
									<a data-target="#productPeriod" data-toggle="tab" (click)="showTab('period')"
										>Period Comparison</a
									>
								</li>
							</ul>

							<div *ngIf="sortData.sortOrder" style="float: right; margin-top: 27px">
								<span *ngIf="sortData.sortOrder === 'desc'"
									><i class="fa fa-arrow-down" aria-hidden="true" style="margin-right: 5px"></i
									>{{ sortData.nameCol }}</span
								>
								<span *ngIf="sortData.sortOrder === 'asc'"
									><i class="fa fa-arrow-up" aria-hidden="true" style="margin-right: 5px"></i
									>{{ sortData.nameCol }}</span
								>
							</div>
							<!---------column chooser space before-------->
						</nav>
					</div>

					<div class="deep-dive-tabs share-tab-content share-report-dashboard-container">
						<div class="overlay-bg-ppt" *ngIf="enableLoader">
							<span class="spinner-large">
								<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
							</span>
						</div>
						@if (loadProductHtml && activeTab==='Product') {
						<div class="tab-pane" id="product">
							<!-- Products Data -->
							<crf-ca-product-comparison
								[productData]="productComparisonData"
								(sortDetails)="getSortColDetails($event)"
								(productDetails)="updateGridData($event)"
								(excelData)="excelData($event)"
								(excelProdData)="excelProdData($event)"
								[childLevel]="childLevelData"
								[reportDownload]="typeToDownload"
								[selectedPeriod]="selectedPeriod"
								[selectedFact]="selectedFactsChecked"
								[actualValue]="finalFactData"
								[factDataNew]="factDataNew"
								[byLevelData]="shareCtrl.selectedLevelToShow"
							>
							</crf-ca-product-comparison>
						</div>
						} @if (activeTab==='CMA') {
						<div class="tab-pane active" id="cma" *ngIf="loadProductHtml">
							<!-- CMA Data -->
							<crf-ca-cma-comparison
								[productData]="productComparisonData"
								(sortDetails)="getSortColDetails($event)"
								[selectedPeriod]="selectedPeriod"
								[selectedFact]="selectedFactsChecked"
								[actualValue]="finalFactData"
								[factDataNew]="factDataNew"
								(excelData)="excelData($event)"
							></crf-ca-cma-comparison>
						</div>
						} @if (activeTab==='Period') {
						<div class="tab-pane active" id="productPeriod" *ngIf="loadProductHtml">
							<!-- Period Data -->
							<crf-ca-period-comparison
								(sortDetails)="updateSortData($event)"
								[childLevel]="childLevelData"
								[selectedPeriod]="selectedPeriod"
								[chartData]="productComparisonData"
								(selectedMeasure)="selectedVolume($event)"
								(factSelectedData)="selectedFactExcel($event)"
								[selectedUnits]="shareCtrl.volumeBasis"
								[selectedFactGraph]="factSelectedExcel"
							></crf-ca-period-comparison>
						</div>
						}
						<div class="clearfix spacer-10"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-12 footnote-block">
			<crf-ca-dashboard-footnote [reportDimension]="shareCtrl"> </crf-ca-dashboard-footnote>
		</div>
	</div>
</div>
