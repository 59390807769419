import { Component } from '@angular/core';
import '@nielsen/nd-ui-components/src/elements.js';
import { AuthService } from './core/service/auth.service';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title: string = 'Welcome WALMART OVT';

	constructor() {}
}
