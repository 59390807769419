<div class="container card">
	<!--Close-->
	<div class="d-flex justify-content-end my-2">
		<niq-icon
			state="primary"
			size="small"
			(click)="closeDialog()"
			iconName="close"
			colorTheme="light"
		></niq-icon>
	</div>
	<!--Search-->
	<div class="search-container">
		<niq-search-bar
			variant="expandable"
			size="medium"
			onWhite="true"
			placeholder="Choose a default product"
			(inputValueChange)="productSearch($event, false)"
			(searchChange)="productSearch($event, true)"
			(clear)="clearProductSearch()"
			class="searchbar"
		>
		</niq-search-bar>
		<ul
			class="typeahead product-selector-search-suggestion p-2"
			*ngIf="suggestions.length > 0 && issuggestions"
		>
			<li
				*ngFor="let suggestion of suggestions; track: by; $index"
				auto-suggest-menu-item
				(click)="suggetionChoosen(suggestion)"
			>
				<span class="search__suggestion"> {{ suggestion }} </span>
			</li>
		</ul>
	</div>
	<div class="header-name d-flex justify-content-between p-3">
		<p class="mb-0">
			Product name
			<span> {{ matchText }} </span>
		</p>
		<p class="mb-0">Matches selected</p>
	</div>
	<!--Products-->
	<div class="product-selector-list">
		<div class="overlay-bg" *ngIf="enableLoader"></div>
		<div *ngIf="enableLoader" class="spinner_overlay">
			<div class="selectorspinner">
				<span class="spinner-large" style="width: fit-content; height: fit-content">
					<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
				</span>
			</div>
		</div>
		<!-- Accordion Start -->
		<niq-accordion-group variant="single" size="medium" [expandedItem]="-1">
			<niq-accordion
				size="medium"
				[isExpanded]="false"
				expandIcon="chevron-up"
				collapseIcon="chevron-down"
				[border]="'none'"
				class="accordion mb-2"
				*ngFor="let allData of allDatas; let i = index"
				[primaryText]="allData.key | textChange"
				[isExpanded]="activeAccordion === allData.key"
				(toggle)="activeAccordion = allData.key"
			>
				<div accordion-actions class="d-flex justify-content-between align-items-center">
					<span style="margin-left: -6px">:</span>
					<p>
						<niq-chip
							type="type"
							class="px-2 mx-2"
							active="active"
							disabled=" disabled"
							*ngIf="allData.selectedValue.length > 0 && enableReset[allData.key]"
						>
							{{ allData.selectedValue[0] }}
							<span
								*ngIf="allData.selectedValue.length > 1"
								class="item-count ng-binding ng-scope ml-2"
							>
								+{{ allData.selectedValue.length - 1 }}</span
							>
						</niq-chip>
						<niq-icon
							state="primary"
							size="small"
							iconName="reset"
							colorTheme="light"
							*ngIf="allData.selectedValue.length > 0 && enableReset[allData.key]"
							(click)="resetProduct(allData.key)"
						>
						</niq-icon>
					</p>
					<p style="position: absolute; right: 50px">
						{{ allData.selectedValue.length }} of {{ allData.value.length }}
					</p>
				</div>
				<div accordion-content class="accordion-content-container p-3">
					@if(allData.key !== "TOTAL"){
					<niq-checkbox-group orientation="vertical">
						<niq-checkbox
							*ngFor="let keyValue of allData.value"
							size="small"
							[checked]="keyValue.isChecked"
							(checkedChange)="checkedChanges($event, allData.key, keyValue, i)"
						>
							{{ keyValue.name }}
						</niq-checkbox>
					</niq-checkbox-group>
					} @else{
					<niq-radio-group
						(selectedChange)="onSelectedChange($event, i)"
						size="small"
						[(selected)]="selectedDes"
					>
						<niq-radio-button *ngFor="let keyValue of allData.value" [value]="keyValue.name">
							<p style="font-weight: 400">{{ keyValue.name }}</p>
						</niq-radio-button>
					</niq-radio-group>
					}
				</div>
			</niq-accordion>
		</niq-accordion-group>
	</div>
	<!--Footer-->
	<div class="button-container py-3 d-flex justify-content-between">
		<niq-button variant="ghost" size="small" class="mr-2" (click)="clearSelection()">
			Clear all
		</niq-button>
		<div>
			<niq-button
				[state]="disabledButton ? 'disabled' : 'default'"
				variant="ghost"
				size="medium"
				class="mr-2"
				(click)="applyUserSelection()"
			>
				Apply
			</niq-button>
			<niq-button
				[state]="disabledButton ? 'disabled' : 'default'"
				variant="primary"
				size="medium"
				class="mr-2"
				[disabled]="disabledButton"
				(click)="saveDefault()"
			>
				Apply and set as default
			</niq-button>
		</div>
	</div>
</div>
