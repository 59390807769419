import { Component, ViewChild } from '@angular/core';
import { NlsnDataStoreService } from '../../service/store/nlsn-data-store.service';
import { KEY } from '../../../constants/data.constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TopbarService } from 'src/app/core/service/topbar/topbar.service';
import { DialogComponent, ToastService } from '@nielseniq/athena-core';
import { LandingPageService } from '../../../service/landing-page.service';
import { NavigationEnd, Router } from '@angular/router';
import { TopbarUtilityService } from '../../service/topbar/topbar-utility.service';
import { Location } from '@angular/common';
import { FeedbackDropdownOption, NotificationMessage } from '../../model/topbar.model';

@Component({
	selector: 'crf-ca-topbar',
	templateUrl: './topbar.component.html',
	styleUrl: './topbar.component.scss'
})
export class TopbarComponent {
	userDetails: any;
	firstName: any;

	header = {
		bannerTitleText: 'Walmart One Version of Truth',
		currentUserName: 'Login User'
	};
	page = {
		title: 'Home Page',
		isConcierge: false
	};

	//PANEL
	isAppMenuPanelVisible = false;
	isNotificationPanelVisible = false;
	isProfileMenuVisible = false;

	//FEEDBACK
	feedbackFormGroup: FormGroup;
	feedbackFormReportDropdownOptions: FeedbackDropdownOption[] = [];
	isFeedbackFormSubmittedAndInvalid: boolean = false;

	//NOTIFICATION
	@ViewChild('notificationDialog') notificationDialog: DialogComponent;
	notifications: NotificationMessage[] = [];
	unreadNotificationCount: number = 0;

	constructor(
		private nlsnDataStoreService: NlsnDataStoreService,
		private formBuilder: FormBuilder,
		private topbarService: TopbarService,
		private utility: TopbarUtilityService,
		public toastServiceTop: ToastService,
		private landingPageService: LandingPageService,
		private router: Router,
		private location: Location
	) {
		//Setting up the feedback form in topbar
		this.feedbackFormGroup = this.formBuilder.group({
			dashboardIndex: ['0', Validators.required],
			feedback: ['', Validators.required]
		});

		//Setting page title
		this.router.events.subscribe({
			next: event => {
				if (event instanceof NavigationEnd) {
					this.page.title = this.utility.getRoutePageTitle(event.url);
				}
			}
		});
	}
	goBack() {
		if (window.history.length > 1) {
			this.location.back();
		} else {
			this.router.navigate(['/']);
		}
	}

	ngOnInit(): void {
		this.userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		this.getHomePageTiles();

		//Loading notifications
		this.getAllNotifications();
	}

	getHomePageTiles() {
		this.header.currentUserName = this.userDetails?.userinfo?.name;
		this.firstName = this.userDetails?.userinfo?.given_name;
	}

	//PANEL
	toggleAppMenuPanel() {
		this.isNotificationPanelVisible = this.isProfileMenuVisible = false;
		this.isAppMenuPanelVisible = !this.isAppMenuPanelVisible;
	}

	toggleNotificationPanel() {
		//Closing all other panels
		this.isAppMenuPanelVisible = this.isProfileMenuVisible = false;

		//Toggling the panel visibility
		this.isNotificationPanelVisible = !this.isNotificationPanelVisible;

		//API call to load notifications on open of notification
		if (this.isNotificationPanelVisible) this.getAllNotifications();
	}

	toggleProfileMenu() {
		this.isAppMenuPanelVisible = this.isNotificationPanelVisible = false;
		this.isProfileMenuVisible = !this.isProfileMenuVisible;
	}

	closeAllPanel() {
		this.isAppMenuPanelVisible =
			this.isNotificationPanelVisible =
			this.isProfileMenuVisible =
				false;
	}
	onLogout() {
		this.topbarService.logout();
	}

	//NOTIFICATION
	notificationData: any[] = [];
	getAllNotifications(): void {
		this.topbarService.readAllNotifications().subscribe(response => {
			if (response.result) {
				this.notificationData = response.result
					.sort((notification1, notification2) => {
						if (notification1.message.msgId < notification2.message.msgId) return 1;
						else if (notification1.message.msgId > notification2.message.msgId) return -1;
						else return 0;
					})
					.map(notification => {
						//Calculating the number of unread message to set notification badge
						if (!notification.isViewed) this.unreadNotificationCount++;

						return {
							id: notification.id,
							messageId: notification.message.msgId,
							date: notification.message.msgDate,
							heading: notification.message.msgHeader,
							subHeading: notification.message.msgSubheader,
							content: notification.message.msgContent,
							contactInfo: notification.message.msgContactInfo,
							isViewed: notification.isViewed
						};
					});
			}
			console.log(this.notificationData);
			console.log('Unread notification counts are :', this.unreadNotificationCount);
		});
	}

	setNotificationAsRead(notificationId): void {
		this.notificationDialog.open();
		this.closeAllPanel();
		this.unreadNotificationCount = 0;
		this.topbarService.updateNotificationAsRead(notificationId).subscribe(response => {
			if (response.responseCode === 'SUCCESS')
				if (this.unreadNotificationCount > 0) this.unreadNotificationCount--;
		});
	}

	navigate() {
		this.router.navigate(['']);
	}
}
