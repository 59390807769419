import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ReportHeaderUtility } from 'src/app/shared/service/report/report-header.utility';

@Injectable({
	providedIn: 'root'
})
export class ConsumerDecisionTreeUtility extends ReportHeaderUtility {
	expandTableView$: Subject<any> = new Subject<any>();
	expandTree$: Subject<any> = new Subject<any>();

	refactorFactDefintion(facts: any): any {
		let factsCopy = structuredClone(facts);

		factsCopy.forEach(fact => {
			//Updated the logic directly in the contanst file
			if (fact.name === 'Products') fact.cellClass = 'gridProductName';
			if (
				fact.cellFilter &&
				(fact.cellFilter === "zip:'dollar'" || fact.cellFilter === "zip:'roundeddollar'")
			)
				fact.cellFilter = fact.valuebase === 'Units' ? "zip:'roundeddollar'" : "zip:'dollar'";
			if (
				(fact.valuebase === 'Dollars' || fact.valuebase === 'Other') &&
				fact.gkey !== 'Products' &&
				fact.gkey !== 'Product Description'
			)
				fact.field = 'dollar' + fact.field[0].toUpperCase() + fact.field.slice(1);
			if (fact.valuebase === 'Units') {
				fact.field = 'unit' + fact.field[0].toUpperCase() + fact.field.slice(1);
			}
			fact.displayName =
				fact.valuebase === 'Dollars' || fact.valuebase === 'Other'
					? fact.displayName
					: fact.units_display_name;
			fact.name = fact.name + '_' + fact.valuebase;
		});

		return factsCopy;
	}
}
