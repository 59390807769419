import { Injectable } from '@angular/core';

@Injectable()
export class NlsnGlobalsContextExtract {
	public appEnvironment: string;
	private contextURL: any;

	extractEnvironment(): string {
		// Get the pathname from current URL
		this.contextURL = window.location.pathname.split('/');
		// Check the environment
		if (
			window.location.host == 'jbpdev.nielseniq.io' ||
			window.location.host == 'uat.dc1.answers.nielseniq.io'
		) {
			// prepend envvironment with '-'
			this.appEnvironment = 'DEV';
		} else if (
			window.location.host == 'oneversiontruthuat.nielseniq.io' ||
			window.location.host == 'uat.dc2.answers.nielseniq.io'
		) {
			// Conditon for higher environments
			this.appEnvironment = 'UAT';
		} else if (
			window.location.host.indexOf('127.0.0.1') > -1 ||
			window.location.host.includes('localhost')
		) {
			// condition for local
			this.appEnvironment = 'LOCAL';
		} else if (
			window.location.host == 'oneversiontruth.nielseniq.com' ||
			window.location.host == 'dc2.answers.nielseniq.com'
		) {
			this.appEnvironment = 'PROD';
		} else {
			//Default pointing to PROD
			this.appEnvironment = 'PROD';
		}
		return this.appEnvironment;
	}
}
