import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'colorvarient',
	standalone: true
})
export class ColorvarientPipe implements PipeTransform {
	transform(value: string, ...args: unknown[]): unknown {
		if (!value) {
			return;
		}
		let number;
		if (typeof value === 'string') {
			number = parseFloat(value.replace(/[%$,]/g, ''));
		}

		if (number <= 0) {
			return {
				color: '#DD0014'
			};
		} else if (number > 0) {
			return {
				color: '#26B910'
			};
		}
		return {
			color: '#000000'
		};
	}
}
