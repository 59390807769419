<div class="container p-3">
	<div>
		<niq-search-bar
			variant="expandable"
			size="medium"
			onWhite="true"
			placeholder="Filter Parent Companies"
			class="searchbar mb-2"
			(inputValueChange)="onSearchChange($event)"
			(clear)="clearProductSearch($event)"
		>
		</niq-search-bar>
	</div>
	<div class="market-dropdown">
		<h6 class="p-3 m-0">Parent Companies</h6>
		<ul class="p-2" (scroll)="onScroll($event)">
			<li
				*ngFor="let marketData of filteredMarketItems; trackBy: trackMarketData"
				(click)="checkSelection(marketData)"
			>
				<niq-checkbox size="small" [(checked)]="marketData.isSelected">
					<span [innerHTML]="searchQuery ? marketData.highlightedValue : marketData.value"></span>
				</niq-checkbox>
			</li>
		</ul>
	</div>
	<!--Footer-->
	<div class="button-container pt-2 d-flex justify-content-end">
		<niq-button size="medium" state="default" variant="ghost" (click)="onCancelRequest()">
			Cancel
		</niq-button>
		<niq-button
			size="medium"
			[state]="isApplyDisable ? 'disabled' : 'default'"
			variant="primary"
			(click)="onApplyRequest()"
		>
			Apply
		</niq-button>
	</div>
</div>
