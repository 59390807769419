import { Injectable } from '@angular/core';
import { ReportHeaderUtility } from 'src/app/shared/service/report/report-header.utility';

@Injectable({
	providedIn: 'root'
})
export class PerformanceQuadrantUtility extends ReportHeaderUtility {
	getDataRequestPayload(values: any) {
		let payload = {
			totalBusiness: values.product.totalBusiness,
			majorBusiness: values.product.majorBusiness,
			department: values.product.department,
			categoryGroup: values.product.categoryGroup,
			category: values.product.category,
			subCategory: values.product.subCategory,
			segment: values.product.segment,
			brandOwnerHigh: values.product.brandOwnerHigh,
			brandOwnerLow: values.product.brandOwnerLow,
			brandHigh: values.product.brandHigh,
			brandFamily: values.product.brandFamily,
			brandLow: values.product.brandLow,
			cyPeriod: '',
			yaPeriod: '',
			wmMarket: [],
			rmMarket: []
		};

		values.market.forEach(market => {
			payload.wmMarket.push(market.api.value.walmartMarket);
			payload.rmMarket.push(market.api.value.remainingMarket);
		});

		if (values.period.length > 0) {
			payload.cyPeriod = values.period[0].api.value.currentPeriod;
			payload.yaPeriod = values.period[0].api.value.previousPeriod;
		}

		return payload;
	}
}
