// ******** Note : Used for tree case Only
export const defaultColDef = {
	width: 180,
	resizable: true,
	initialWidth: 100,
	wrapHeaderText: true,
	autoHeaderHeight: true,
	sortable: true,
	cellStyle: (params: any) => {
		if (
			params.column.userProvidedColDef?.cellClass !== 'special' &&
			params.column.userProvidedColDef?.cellClass !== 'normal' &&
			params.colDef?.cellClass !== 'gridProductName'
		) {
			let number = params.value;
			if (number < 0) {
				return {
					color: '#DD0014'
				};
			} else if (number > 0) {
				return {
					color: '#26B910'
				};
			}
			return { color: '#000000' };
		}
	},
	cellRenderer: params => {
		let param = +params.value;
	},
	comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
		let valA = Number(valueA);
		let valB = Number(valueA);

		if (isNaN(valA) || isNaN(valB)) {
			if (valueA == valueB) return 0;
			return valueA > valueB ? 1 : -1;
		} else {
			return valueA - valueB;
		}
	}
};
export const icons: { [key: string]: Function | string } = {
	groupExpanded: '<i class="fa fa-minus-square" aria-hidden="true"></i>',
	groupContracted: '<i class="fa fa-plus-square" aria-hidden="true"></i>',
	filter: '<i class="fa fa-long-arrow-alt-down"/>',
	columns: '<i class="fa fa-table" aria-hidden="true"></i>',
	menu: '<i class="fa fa-ellipsis-h" aria-hidden="true"></i>'
};

export const themeClass: string = 'ag-theme-alpine';
