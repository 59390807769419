import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService, ToastState } from '@nielseniq/athena-core';
import { Subscription } from 'rxjs';
import { DOLLAR_CP, EQ, UNITs_CP } from 'src/app/constants/report.constant';
import { TOAST } from 'src/app/constants/toast.constant';
import { FilterPipe } from 'src/app/core/pipes/filter.pipe';
import { ReportService } from 'src/app/service/report.service';
import { cloneDeep } from 'lodash';

@Component({
	selector: 'crf-ca-fact-selector-single',
	templateUrl: './fact-selector-single.component.html',
	styleUrl: './fact-selector-single.component.scss'
})
export class FactSelectorSingleComponent implements OnInit {
	@Input() configId: number;
	@Input() disableMenuOptions: boolean;
	@Input() menu = true;
	@Input() factData;
	@Input() defaultValue;
	@Input() questionId = -1;
	defaultFactValue: any;
	@Output() selectedFacts = new EventEmitter<any>();
	@Output() onClose = new EventEmitter<any>();
	newFactData: any = [];
	originalNewFactData: any = [];
	initial = { headerValue: '', factValue: undefined };

	factHeaders: any[] = [
		{ title: 'Dollars', value: DOLLAR_CP, selectedCount: 0 },
		{ title: 'Units', value: UNITs_CP, selectedCount: 0 },
		{ title: 'EQ', value: EQ, selectedCount: 0 }
	];

	headerValue;
	factValue;
	accordionExpandedItem: number = -1;
	originalCopyOfTheFactData: any[] = [];
	disabledButton = false;
	searchText: string;

	constructor(
		private service: ReportService,
		private toastService: ToastService,
		private filterPipe: FilterPipe
	) {}

	ngOnInit(): void {
		if (this.factData && this.factData.length > 0) {
			this.factData.forEach(fact => {
				if (fact.isDollarChecked) {
					this.headerValue = DOLLAR_CP;
					this.factValue = fact;
				} else if (fact.isUnitChecked) {
					this.headerValue = UNITs_CP;
					this.factValue = fact;
				} else if (fact.isEQChecked) {
					this.headerValue = EQ;
					this.factValue = fact;
				}
			});
		}

		if (!this.factValue) {
			this.factValue = this.factData ? this.factData[0] : '';
		}
		if (this.factValue && !this.headerValue) {
			this.factData[0].isDollarChecked = true;
		}
		if (!this.headerValue) {
			this.headerValue = DOLLAR_CP;
		}
		this.initial.headerValue = this.headerValue;
		this.initial.factValue = this.factValue;

		this.originalCopyOfTheFactData = cloneDeep(this.factData);
		this.getCheckedCount(this.headerValue);
		this.defaultFactValue = this.factValue;
		this.checkDefault();
		this.transformFactData();
	}

	headerValueChange(value): void {
		this.headerValue = value;
	}
	checkDefault(): any {
		this.disableMenuOptions = this.factValue === this.defaultFactValue;
	}
	factValueChange(value, type: 'DOLLAR' | 'UNITS' | 'EQ'): void {
		this.factValue = value;
		this.headerValue = type;
		this.disabledButton = false;
		switch (type) {
			case DOLLAR_CP:
				this.factData.filter(fact => {
					if (fact.isDollarChecked === true) {
						fact.isDollarChecked = false;
					}
				});
				value.isDollarChecked = !value.isDollarChecked;
				break;
			case UNITs_CP:
				this.factData.filter(fact => {
					if (fact.isUnitChecked === true) {
						fact.isUnitChecked = false;
					}
				});
				value.isUnitChecked = !value.isUnitChecked;
				break;
			case EQ:
				this.factData.filter(fact => {
					if (fact.isEQChecked === true) {
						fact.isEQChecked = false;
					}
				});
				value.isEQChecked = !value.isEQChecked;
				break;
			default:
				// Added for fix code smells
				break;
		}
		this.updateSelection();
	}
	getCheckedCount(factType: 'DOLLAR' | 'UNITS' | 'EQ') {
		const factHeaderMap = {
			DOLLAR: 0,
			UNITS: 1,
			EQ: 2
		};

		// Reset all selectedCount values
		this.factHeaders.forEach(header => (header.selectedCount = 0));

		// Map factType to the correct isChecked property names
		const isCheckedPropertyMap = {
			DOLLAR: ['isDollarChecked'],
			UNITS: ['isUnitsChecked', 'isUnitChecked'],
			EQ: ['isEQChecked', 'isEqChecked']
		};

		const headerIndex = factHeaderMap[factType];
		const isCheckedProperties = isCheckedPropertyMap[factType];

		if (headerIndex !== undefined && isCheckedProperties) {
			// Filter factData based on any of the isChecked properties
			this.factHeaders[headerIndex].selectedCount = 1;
		}
	}

	cancel() {
		this.onClose.emit(true);
	}

	apply() {
		this.selectedFacts.emit({ header: this.headerValue, value: this.factValue });

		// Reset all checked values and set the appropriate one based on factValue's valueBase
		this.factData.forEach(fact => {
			if (fact.key === this.factValue.key) {
				['isDollarChecked', 'isUnitChecked', 'isEQChecked'].forEach(
					property => (fact[property] = false)
				);

				const checkProperty = `is${
					this.factValue.valueBase.charAt(0) + this.factValue.valueBase.slice(1).toLowerCase()
				}Checked`;
				fact[checkProperty] = true;
			}
		});
		this.checkDefault();
		this.getCheckedCount(this.factValue.valueBase);
	}

	clearSelection() {
		this.disabledButton = true;
		this.factValue = '';
		this.factData.filter(fact => {
			if (fact.isDollarChecked || fact.isUnitChecked || fact.isEQChecked) {
				fact.isDollarChecked = false;
				fact.isUnitChecked = false;
				fact.isEQChecked = false;
			}
		});
		this.factHeaders[0].selectedCount = 0;
		this.factHeaders[1].selectedCount = 0;
		this.factHeaders[2].selectedCount = 0;
	}
	transformFactData() {
		this.newFactData = [];

		this.originalCopyOfTheFactData.forEach(fact => {
			// Create separate objects for Dollar, Unit, and EQ, and include the display text
			this.newFactData.push({
				key: fact.key,
				factKey: fact.dollarKey,
				valueBase: 'DOLLAR',
				displayText: '$ ' + fact.key,
				dollarKey: fact.dollarKey,
				unitKey: fact.unitKey,
				eqKey: fact.eqKey
			});

			this.newFactData.push({
				key: fact.key,
				factKey: fact.unitKey,
				valueBase: 'UNITS',
				displayText: 'Unit ' + fact.key,
				dollarKey: fact.dollarKey,
				unitKey: fact.unitKey,
				eqKey: fact.eqKey
			});

			this.newFactData.push({
				key: fact.key,
				factKey: fact.eqKey,
				valueBase: 'EQ',
				displayText: 'EQ ' + fact.key,
				dollarKey: fact.dollarKey,
				unitKey: fact.unitKey,
				eqKey: fact.eqKey
			});

			this.originalNewFactData = [...this.newFactData];
		});

		// this.factHeaders.forEach(header => {
		// 	header.selectedCount = this.factData.filter(fact => fact.valueBase === header.value).length;
		// });
	}
	getFilteredFactData(valueBase: string) {
		return this.newFactData.filter(fact => fact.valueBase === valueBase);
	}
	search(searchString: string) {
		const trimmedSearchString = searchString.trim();
		this.searchText = searchString;
		if (trimmedSearchString === '') {
			this.newFactData = [...this.originalNewFactData];
			return;
		}

		const searchTerms = trimmedSearchString
			.toLowerCase()
			.split(/\s+/)
			.filter(term => term.length > 0);

		if (searchTerms.length > 0) {
			this.newFactData = this.originalNewFactData
				.filter(item => {
					return searchTerms.every(term => item.displayText?.toLowerCase().includes(term));
				})
				.map(item => {
					return {
						...item,
						highlightedValue: this.highlightSearchText(item.displayText, searchTerms)
					};
				});
		} else {
			this.newFactData = [...this.originalNewFactData];
		}
		console.log(this.newFactData);
	}

	highlightSearchText(text: string, searchTerms: string[]): string {
		if (!text || searchTerms.length === 0) {
			return text;
		}

		const regexPattern = searchTerms
			.map(term => term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
			.join('|');

		const regex = new RegExp(`(${regexPattern})`, 'gi');

		return text.replace(regex, '<b>$1</b>');
	}

	clear(event) {
		this.searchText = '';
		this.newFactData = this.originalNewFactData;
		this.newFactData = this.originalNewFactData.map(fact => {
			return {
				...fact,
				displayText: fact.displayText.replace(/<b>(.*?)<\/b>/g, '$1')
			};
		});
		this.updateSelection();
	}

	updateSelection() {
		if (this.headerValue) {
			if (this.headerValue === DOLLAR_CP) {
				this.factData.filter(fact => {
					if (fact.isUnitChecked === true) {
						fact.isUnitChecked = false;
					}
					if (fact.isEQChecked === true) {
						fact.isEQChecked = false;
					}
				});
			} else if (this.headerValue === UNITs_CP) {
				this.factData.filter(fact => {
					if (fact.isDollarChecked === true) {
						fact.isDollarChecked = false;
					}
					if (fact.isEQChecked === true) {
						fact.isEQChecked = false;
					}
				});
			} else {
				this.factData.filter(fact => {
					if (fact.isDollarChecked === true) {
						fact.isDollarChecked = false;
					}
					if (fact.isUnitChecked === true) {
						fact.isUnitChecked = false;
					}
				});
			}
		}
	}
	toggle(index: number) {
		if (this.accordionExpandedItem == index) this.accordionExpandedItem = -1;
		else this.accordionExpandedItem = index;
	}

	getMenuResponse(data) {
		let subscription: Subscription;
		if (this.questionId !== -1) {
			if (data.menuId === 1) {
				let value = '';
				if (this.headerValue === DOLLAR_CP) {
					value = this.factValue?.dollarKey;
				} else if (this.headerValue === UNITs_CP) {
					value = this.factValue?.unitKey;
				} else {
					value = this.factValue?.eqKey;
				}

				if (value !== undefined) {
					subscription = this.service
						.saveSelectors(this.questionId, 'facts', { facts: value })
						.subscribe({
							next: res => {
								// Copying value from the initial variables
								this.initial.factValue = this.factValue;
								this.initial.headerValue = this.headerValue;
								this.defaultFactValue = this.factValue;
								this.checkDefault();

								this.toast('success', 'Success', 'Selections saved successfully');
							},
							error: err => {
								this.toast('error', 'Failed', 'Invalid Response');
								if (subscription) {
									subscription.unsubscribe();
								}
							},
							complete: () => {
								if (subscription) {
									subscription.unsubscribe();
								}
							}
						});
				} else {
					console.error('Value is undefined. Unable to subscribe.');
				}
			} else if (data.menuId === 2) {
				// Copying value from the initial variables
				this.factValue = this.initial.factValue;
				this.headerValue = this.initial.headerValue;
				//Update fact count on reset
				this.factHeaders.forEach(fact => {
					if (this.headerValue === fact.value) {
						fact.selectedCount = 1;
					} else {
						fact.selectedCount = 0;
					}
				});

				this.selectedFacts.emit({ header: this.headerValue, value: this.factValue });
				this.factValue = this.defaultFactValue;
				this.checkDefault();
			}
		} else {
			console.error('Invalid question ID.');
		}
	}

	toast(type: ToastState, title: string, message: string): void {
		this.toastService.InjectToast(type, message, '', TOAST.TIMEOUT, '', title, TOAST.SIZE);
	}
}
