<div class="container m-0 p-0 p-3">
	<div class="row">
		<div class="col-12">
			<div class="tab-container mt-3">
				<div class="tab-header">
					<niq-tab-line-list selected="Job-Aids" [(selected)]="selectedTrainingTabValue">
						<niq-tab-line value="Job-Aids">Job-Aids</niq-tab-line>
						<niq-tab-line value="Videos">Videos</niq-tab-line>
					</niq-tab-line-list>
				</div>
				<div class="tab-body">
					<crf-ca-training-document
						*ngIf="selectedTrainingTabValue == 'Job-Aids'"
						[trainingDocuments]="trainingMaterials.Documents"
					>
					</crf-ca-training-document>
					<crf-ca-training-video
						*ngIf="selectedTrainingTabValue == 'Videos'"
						[trainingVideos]="trainingMaterials.Videos"
					>
					</crf-ca-training-video>
				</div>
			</div>
		</div>
	</div>
</div>
