import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NlsnDataStoreService } from '../core/service/store/nlsn-data-store.service';
import { KEY } from '../constants/data.constant';
import {
	BASE_URL,
	OVT_UI_URL_AZ_SEARCH,
	OVT_UI_URL_DASHBOARD_SELECTION
} from 'src/environments/env.config.constants';
import { Observable, catchError, map } from 'rxjs';
import { REST_API_CONSTANTS } from '../constants/rest-api.constant';
import { ByLevelSelectorParams, Selector } from '../models/report.model';
import { NlsnGlobalsContextExtract } from '../core/service/auth/nlsn-globals.service';
import { ProductLevel } from '../models/report-dimension.model';

@Injectable({
	providedIn: 'root'
})
export class ReportService {
	options: any = {};

	constructor(
		public http: HttpClient,
		public nlsnDataStoreService: NlsnDataStoreService,
		public nlsnGlobalsContextExtract: NlsnGlobalsContextExtract
	) {
		this.options.headers = new HttpHeaders().append(
			'X-NIQ-ACCESS-TOKEN',
			this.nlsnDataStoreService.getItem(KEY.COOKIE)?.access_token
		);
	}

	getMarket(): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			REST_API_CONSTANTS.FILTER.FETCH_MARKET.URL;
		return this.http.get<any>(pingURL, this.options).pipe(
			map(result => {
				return result;
			})
		);
	}

	getPeriod(): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			REST_API_CONSTANTS.FILTER.FETCH_PERIOD.URL;
		return this.http.get<any>(pingURL, this.options).pipe(
			map(result => {
				return result;
			})
		);
	}

	getByLevel(questionId: number, type: string, params: ByLevelSelectorParams): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			REST_API_CONSTANTS.FILTER.FETCH_BY_LEVEL.URL +
			'/' +
			questionId +
			'/' +
			type;

		let requestParams = '';
		Object.keys(params).forEach(param => {
			if (params[param]) requestParams += encodeURI(param) + '=' + encodeURI(params[param]) + '&';
		});
		if (requestParams.length > 0)
			requestParams = requestParams.substring(0, requestParams.length - 1);

		return this.http.get<any>(pingURL + '?' + requestParams, this.options).pipe(
			map(result => {
				return result;
			})
		);
	}

	//Gets the values of the selectors saved in the database
	getSelectors(questionId: number): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			REST_API_CONSTANTS.FILTER.FETCH_SELECTORS.URL +
			'/' +
			questionId;
		return this.http.get<any>(pingURL, this.options).pipe(
			map(result => {
				return result;
			}),
			catchError((err: HttpErrorResponse): any => {
				console.log('error');
			})
		);
	}

	//Save the values of the selectors in the database
	saveSelectors(questionId: number, type: string, payload: Selector): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			REST_API_CONSTANTS.FILTER.SAVE_DEFAULT.URL +
			'/' +
			questionId +
			'?type=' +
			encodeURI(type);

		return this.http.post<any>(pingURL, payload, this.options).pipe(
			map(result => {
				return result;
			})
		);
	}

	getPeriodEndDate(queid, period, payload?): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			REST_API_CONSTANTS.FILTER.PERIOD_END_DATE +
			queid +
			'/' +
			period;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.post<any>(pingURL, payload, headers).pipe(
			map(result => {
				return result.responseData;
			})
		);
	}

	getPanelPeriod(): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			OVT_UI_URL_DASHBOARD_SELECTION.GET_ALL_PANEL_PERIODS;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.get<any>(pingURL, headers).pipe(
			map(result => {
				return result.responseData;
			})
		);
	}
	getParentCompany(queryParams): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			OVT_UI_URL_AZ_SEARCH.GET_PARENT_COMPANY +
			'/DISPLAYALL?' +
			queryParams;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.get<any>(pingURL, headers).pipe(map(res => res.responseData));
	}

	exportReport(payload: any, type: 'excel' | 'ppt'): Observable<any> {
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		return this.http.post(
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] + '/sbs/export/' + type,
			payload,
			{
				headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token),
				responseType: 'blob'
			}
		);
	}

	setSelectedProduct(selectedProduct, isMulti: boolean): ProductLevel[] {
		let selectedProductArr = [];
		selectedProduct?.forEach(level => {
			selectedProductArr.push({
				displayName: level.filter,
				displayValue: isMulti ? level.value : [level.value]
			});
		});
		return selectedProductArr;
	}

	setMarketArray(selectedMarket) {
		let marketArr = [];
		selectedMarket?.forEach(market => {
			marketArr.push(market.key);
		});
		return marketArr;
	}
}
